import { useContext, useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { NewIntegrationCard } from "@attrove/attrove-ui/components/integrations/new-integration-card";
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { GET_REPORTS_QUERY_KEY } from "@attrove/service-supabase";

export const OutlookIntegration: React.FC<{
  onSuccess?: () => void;
}> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const axiosClient = useContext(AxiosClientContext);
  const processedCode = useRef<string | null>(null);

  useEffect(() => {
    const handleOutlookCallback = async () => {
      if (location.pathname !== "/integrations/new/microsoft/outlook/callback") {
        return;
      }

      const code = searchParams.get("code");
      if (!code || processedCode.current === code) {
        return;
      }

      // Mark this code as being processed
      processedCode.current = code;

      // Call onSuccess immediately to proceed with onboarding
      if (onSuccess) {
        onSuccess();
      }

      try {
        setLoading(true);
        // Process the code with the backend asynchronously
        const response = await axiosClient.post("/integrations/new/microsoft/outlook/callback", { code });
        
        if (response.status === 200) {
          queryClient.invalidateQueries({ queryKey: [GET_REPORTS_QUERY_KEY] });
          // Use replace instead of push to prevent history stack issues
          navigate("/integrations", { replace: true });
        }
      } catch (error) {
        console.error("Error processing Outlook callback:", error);
        // On error, clear the processed code to allow retry
        processedCode.current = null;
      } finally {
        setLoading(false);
      }
    };

    // Debounce the callback handling
    const timeoutId = setTimeout(handleOutlookCallback, 100);
    return () => clearTimeout(timeoutId);

  }, [searchParams, navigate, location, onSuccess, axiosClient]);

  const handleOutlookIntegration = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get("/integrations/new/microsoft/outlook");
      if (response.status === 200 && response.data.authUrl) {
        // Clear any previously processed code before redirecting
        processedCode.current = null;
        window.location.href = response.data.authUrl;
      }
    } catch (error) {
      console.error("Error initiating Outlook integration:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NewIntegrationCard
      provider={"outlook"}
      name={"Outlook"}
      loading={loading}
      onClick={handleOutlookIntegration}
    />
  );
};