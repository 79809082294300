import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { GmailIntegration } from "@attrove/attrove-ui/components/integrations/gmail";
import { OutlookIntegration } from "@attrove/attrove-ui/components/integrations/outlook";
import { SlackIntegration } from "@attrove/attrove-ui/components/integrations/slack";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { GET_USER_QUERY_KEY, updateUser } from "@attrove/service-supabase";
import { useMutation } from "@tanstack/react-query";
import React from "react";

export const Step3: React.FC<{ setCurrentStep: (n: number) => void }> = ({ setCurrentStep }) => {
  const { user } = useSession();

  const updateOnboarding = useMutation({
    mutationFn: async () => {
      return await updateUser(supabase, user.id, { onboarding_step: 4 });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_USER_QUERY_KEY] });
      setCurrentStep(4);
    },
  });

  const onSuccess = () => {
    updateOnboarding.mutate();
  };

  return (
    <div className="flex flex-col gap-4 justify-between pb-4">
      <h1 className="text-2xl font-bold">Let's Get You Connected</h1>
      <div className="text-muted-foreground space-y-4">
        <p>
          To start synthesizing your workplace communication, we need to connect to your email and messaging accounts. This allows Attrove to
          securely analyze your communications and provide valuable insights.
        </p>
        <div className="border-t pt-4">
        <h2 className="text-lg font-semibold text-primary mb-2">Getting Started</h2>
        <p className="text-muted-foreground">
          We're kicking things off by analyzing your 100 most recent messages. Don't worry, we're not diving into ancient history. This initial sample gives us just enough to work our magic and provide you with immediate insights.
        </p>
        <p className="text-muted-foreground mt-2">
          As you continue your journey with Attrove, we'll process new messages faster than you can say "inbox zero". This way, your insights will always be fresher than your coffee.
        </p>
      </div>
      </div>
      <div className="flex flex-col gap-3 mt-4">
        <GmailIntegration onSuccess={onSuccess} />
        <OutlookIntegration onSuccess={onSuccess} />
      </div>
      <p className="text-muted-foreground text-sm mt-4">Your data is encrypted and used only to enhance your work efficiency. We prioritize your privacy and data security.</p>
    </div>
  );
};