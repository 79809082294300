import { ComponentType } from "react";
import { DebounceInputProps } from "./onboarding-debounce-input";

type InputProps = {
  DebounceInput: ComponentType<DebounceInputProps>;
  value: string;
  onChange: (value: string) => void;
  onSave: (value: string) => void;
  isLoading?: boolean;
};

export const FullNameInput: React.FC<InputProps> = ({ 
  DebounceInput, 
  value, 
  onChange,
  onSave,
  isLoading
}) => {
  return (
    <DebounceInput
      value={value}
      setValue={onChange}
      onChange={onSave}
      label="What's your full name?"
      placeholder="John Doe"
      isLoading={isLoading}
    />
  );
};
