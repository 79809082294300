import { supabase } from "@attrove/attrove-ui/app/supabase";
import { GET_USER_QUERY_KEY, User, getUser } from "@attrove/service-supabase";
import { Session } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import { axiosClient } from "../main";

type SessionContextValue = {
  session: Session | null;
  user: User | null;
};

const SessionContext = createContext<SessionContextValue | undefined>(undefined);

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};

type Props = { children: React.ReactNode };
export const UnprotectedSessionProvider = ({ children }: Props) => {
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error fetching session:", error);
        setIsLoading(false);
        return;
      }
      setSession(data.session);
      setIsLoading(false);
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setSession(session);
      if (session?.access_token) {
        axiosClient.defaults.headers.common["Authorization"] = `Bearer ${session.access_token}`;
      } else {
        delete axiosClient.defaults.headers.common["Authorization"];
      }
      setIsLoading(false);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const {
    data: user,
    isLoading: isUserLoading,
    isError,
    error,
  } = useQuery<User, Error>({
    queryKey: [GET_USER_QUERY_KEY],
    queryFn: () => {
      if (!session?.user.id) throw new Error("No user ID available");
      return getUser(supabase, session.user.id);
    },
    enabled: !!session?.user.id,
  });

  if (isLoading || isUserLoading) {
    return null;
  }

  return <SessionContext.Provider value={{ session, user: user || null }}>{children}</SessionContext.Provider>;
};
