import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { GET_REPORTS_QUERY_KEY, GET_USER_QUERY_KEY, getReports, Report, updateUser } from "@attrove/service-supabase";
import { Button, Skeleton } from "@attrove/ui-shadcn";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export const Step5 = () => {
  const { user } = useSession();
  const navigate = useNavigate();

  const { data: reports, isLoading } = useQuery<Report[]>({
    queryKey: [GET_REPORTS_QUERY_KEY],
    queryFn: () => getReports(supabase, user.id),
  });

  const setOnboarded = useMutation({
    mutationFn: async () => {
      return await updateUser(supabase, user.id, { onboarded: true });
    },
    onSuccess: () => {
      navigate("/reports");
      queryClient.invalidateQueries({ queryKey: [GET_USER_QUERY_KEY] });
    },
  });

  const handleNext = () => {
    setOnboarded.mutate();
  };

  return reports && reports.length > 0 ? (
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl font-bold">Your personal report is now ready!</h1>
      <div className="flex justify-end">
        <Button className="w-min" variant="default" onClick={handleNext}>
          View Report
        </Button>
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl font-bold">Generating report</h1>

      <div className="space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>

      <div className="flex justify-end">
        <Button className="w-min" variant="default" disabled>
          View Report
        </Button>
      </div>
    </div>
  );
};
