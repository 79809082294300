import { parseName } from "@attrove/attrove-ui/lib/utils";
import { FilterFn } from "@tanstack/react-table";

export const customGlobalFilter: FilterFn<any> = (row, columnId, filterValue, addMeta) => {
  // Ensure filterValue is a string
  const searchValue = String(filterValue).toLowerCase();
  const message = row.original;

  // Check the 'from' column data
  const fromEmail = parseName(message.sender_name);
  const fromName = fromEmail?.name || "";
  if (fromName.toLowerCase().includes(searchValue)) return true;
  if (message.subject.toLowerCase().includes(searchValue)) return true;

  // Check the 'details' column data
  if (message.brief?.toLowerCase().includes(searchValue)) return true;

  // If none of the above matches, this row doesn't match the filter
  return false;
};
