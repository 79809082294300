import { ColumnDef } from "@tanstack/react-table";
import { TopEntity } from "@attrove/attrove-ui/app/pages/dashboard/people";
import { tagFilterFn } from "@attrove/attrove-ui/features/people/filtering/filtering";
import { Button, cn } from "@attrove/ui-shadcn";
import { Mail, MessageCircle, Calendar, MessagesSquare } from "lucide-react";
import { Link } from "react-router-dom";
import { Avatar, AvatarFallback } from "@attrove/ui-shadcn";
import { parseTag } from "@attrove/attrove-ui/lib/utils";

const getInitials = (name: string) => {
  const names = name.split(" ");
  if (names.length === 1) return names[0].substring(0, 2).toUpperCase();
  return (names[0][0] + names[names.length - 1][0]).toUpperCase();
};

interface IntegrationDetail {
  id: number;
  type: string;
  type_generic: string;
}

const renderIntegrationButton = (row: any, accessorKey: string, type_generic: string, icon: React.ReactNode) => {
  const count = (row.getValue(accessorKey) as number) || 0;
  const entityId = row.original?.entity_id || "";
  const integrationDetails: IntegrationDetail[] = row.original?.integration_details || [];

  const relevantIntegrations = integrationDetails
    .filter(detail => detail.type_generic === type_generic)
    .map(detail => detail.id);

  let integrationIdsParam = "";
  if (relevantIntegrations.length > 0) {
    integrationIdsParam = `&integrations=${relevantIntegrations.join(",")}`;
  }

  return (
    <div className="hidden lg:block">
    <Link to={`/messages?entity=${entityId}${integrationIdsParam}`}>
      <Button variant="outline" size="sm" disabled={count === 0}>
        {icon}
        <span className="ml-2">{count}</span>
      </Button>
    </Link>
    </div>
  );
};

const TagItem = ({ tag, entityId }) => (
  <Link to={`/messages?tags=${encodeURIComponent(tag)}&entity=${entityId}`}>
    <div className={cn(
      "flex items-center px-2 py-1 rounded-lg border transition-all duration-200 cursor-pointer",
      "bg-brand-primary/5 border-brand-primary/20",
      "hover:bg-brand-primary/10 hover:border-brand-primary/30 group"
    )}>
      <span className={cn(
        "text-xs font-medium transition-colors",
        "group-hover:text-brand-primary"
      )}>
        {parseTag(tag).split(' ').map(word => 
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ).join(' ')}
      </span>
    </div>
  </Link>
);

export const columns: ColumnDef<TopEntity>[] = [
  {
    accessorKey: "entity_name",
    header: "Name",
    cell: ({ row }) => {
      const name = row.getValue("entity_name") as string || 'Unknown';
      return (
        <div className="flex items-center gap-3">
          <Avatar className="h-8 w-8">
          <AvatarFallback className="bg-background text-foreground">
              {getInitials(name)}
            </AvatarFallback>
          </Avatar>
          <span className="truncate">{name}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "total_interactions",
    header: "Total Interactions",
    cell: ({ row }) => {
      const count = row.getValue("total_interactions") as number;
      const entityId = row.original?.entity_id || "";
      return (
        <div className="hidden sm:block">
        <Link to={`/messages?entity=${entityId}`}>
          <Button variant="outline" size="sm" disabled={count === 0}>
            <MessagesSquare className="w-4 h-4" />
            <span className="ml-2">{count}</span>
          </Button>
        </Link>
        </div>
      );
    },
  },
  {
    accessorKey: "email_count",
    header: "Email",
    cell: ({ row }) => renderIntegrationButton(row, "email_count", "email", <Mail className="w-4 h-4" />),
  },
  {
    accessorKey: "chat_count",
    header: "Chat",
    cell: ({ row }) => renderIntegrationButton(row, "chat_count", "chat", <MessageCircle className="w-4 h-4" />),
  },
  {
    accessorKey: "meeting_count",
    header: "Meetings",
    cell: ({ row }) => renderIntegrationButton(row, "meeting_count", "calendar", <Calendar className="w-4 h-4" />),
  },
  {
    accessorKey: "top_tags",
    header: "Top Tags",
    filterFn: tagFilterFn,
    cell: ({ row }) => {
      const tags = (row.getValue("top_tags") as string[]) || [];
      const entityId = row.original?.entity_id || "";
      return (
        <div className="hidden sm:flex flex-wrap gap-1">
          {tags.slice(0, 3).map((tag, index) => (
            <TagItem key={index} tag={tag} entityId={entityId} />
          ))}
        </div>
      );
    },
  },
];
