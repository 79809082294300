// useSubscription.tsx
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import type { UserSubscriptionStatus, SubscriptionTier } from '@attrove/service-supabase';
import { getSubscriptionLimits } from '@attrove/service-supabase';

export interface SubscriptionResponse {
  subscription: UserSubscriptionStatus | null;
  usage: {
    used: number;
    limit: number;
    periodEnd: string;
    tier: 'free' | 'pro';
  } | null;
}

export const useSubscription = () => {
  const axiosClient = useContext(AxiosClientContext);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['subscription'],
    queryFn: async () => {
      const { data } = await axiosClient.get<SubscriptionResponse>('/api/stripe/subscription');
      return data;
    },
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
    refetchOnWindowFocus: false,
  });

  const subscription = data?.subscription;
  const usage = data?.usage;
  
  // Consider a user on Pro tier only if they have an active subscription with pro tier
  const isPro = subscription?.tier === 'pro' && subscription?.isActive;
  
  // Default to free tier limits if no subscription exists
  const limits = getSubscriptionLimits(subscription?.tier ?? 'free');

  return {
    subscription,
    usage,
    isLoading,
    error,
    isPro,
    limits,
    refetch,
    // Enhanced to work even without an active subscription
    currentTier: subscription?.tier ?? 'free',
    isFeatureAvailable: (feature: string) => {
      switch (feature) {
        case 'prioritySupport':
          return isPro;
        default:
          return true; // Free features
      }
    }
  };
};