import React from "react";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { getInitials, getOrganizer } from "@attrove/attrove-ui/lib/utils";
import { EventType, EventMessagesResponse, getMessagesByUser } from "@attrove/service-supabase";
import {
  Avatar,
  AvatarFallback,
  Button,
  Card,
  CardHeader,
  Badge,
  ScrollArea,
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
  cn,
  Icons,
} from "@attrove/ui-shadcn";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "@attrove/ui-shadcn";
import { AlertCircle, Calendar, Video, Users, MessageCircle, Clock, MapPin, ArrowUpRight } from "lucide-react";
import { Link } from "react-router-dom";
import { SideScreen } from ".";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";

dayjs.extend(relativeTime);

// Helper function to decode HTML entities
function decodeHtmlEntities(text: string): string {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
}

const TimeIndicator: React.FC<{ startTime: string; endTime: string }> = ({ startTime, endTime }) => {
  const now = dayjs();
  const start = dayjs(startTime);
  const end = dayjs(endTime);
  const diffMinutes = start.diff(now, 'minute');
  const diffHours = start.diff(now, 'hour');
  const diffDays = start.diff(now, 'day');
  
  // Check if meeting is over
  if (now.isAfter(end)) {
    return null;
  }

  // Check if meeting is ongoing
  if (now.isAfter(start) && now.isBefore(end)) {
    return (
      <div className={cn(
        "inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-xs font-medium border",
        "bg-slate-500/10 text-slate-700 dark:text-slate-400 border-slate-200 dark:border-slate-900"
      )}>
        <span className="w-1.5 h-1.5 rounded-full bg-slate-500" />
        In Progress • {end.fromNow(true)} remaining
      </div>
    );
  }

  // Don't show indicator if meeting is more than a day away
  if (diffDays > 1) {
    return null;
  }
  
  // Check if the meeting is tomorrow by comparing calendar dates
  const isTomorrow = start.startOf('day').diff(now.startOf('day'), 'day') === 1;
  if (isTomorrow) {
    return (
      <div className={cn(
        "inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-xs font-medium border",
        "bg-blue-500/10 text-blue-700 dark:text-blue-400 border-blue-200 dark:border-blue-900"
      )}>
        <span className="w-1.5 h-1.5 rounded-full bg-blue-500" />
        Tomorrow • {start.format('h:mm A')}
      </div>
    );
  }

  // Check if meeting is today by comparing calendar dates
  const isToday = start.startOf('day').isSame(now.startOf('day'));
  if (!isToday) {
    return null;
  }
  
  let status: 'imminent' | 'soon' | 'upcoming' = 'upcoming';
  if (diffMinutes <= 15) status = 'imminent';
  else if (diffHours <= 2) status = 'soon';

  const statusStyles = {
    imminent: "bg-red-500/10 text-red-700 dark:text-red-400 border-red-200 dark:border-red-900",
    soon: "bg-orange-500/10 text-orange-700 dark:text-orange-400 border-orange-200 dark:border-orange-900",
    upcoming: "bg-blue-500/10 text-blue-700 dark:text-blue-400 border-blue-200 dark:border-blue-900"
  };

  const statusText = {
    imminent: "Starting soon",
    soon: "Coming up",
    upcoming: "Later today"
  };

  return (
    <div className={cn(
      "inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-xs font-medium border",
      statusStyles[status]
    )}>
      <span className={cn(
        "w-1.5 h-1.5 rounded-full",
        status === 'imminent' && "animate-pulse",
        {
          'bg-red-500': status === 'imminent',
          'bg-orange-500': status === 'soon',
          'bg-blue-500': status === 'upcoming'
        }
      )} />
      {statusText[status]} • {start.fromNow()}
    </div>
  );
};

export const MeetingDetail: React.FC<{
  screen: SideScreen;
  messages?: EventMessagesResponse;
}> = ({ screen, messages }) => {
  const event = screen.event;
  if (!event) return null;

  const messageCount = messages?.find((m) => m.event_id === event.id)?.message_ids.length || 0;

  return (
    <Card className="h-[calc(100vh-300px)] flex flex-col">
      <CardHeader className="border-b space-y-3 flex-shrink-0">
        <div className="flex items-center justify-between gap-4 min-w-0">
          <div className="space-y-2 min-w-0">
            <TimeIndicator startTime={event.start_time} endTime={event.end_time} />
            <h2 className="text-2xl font-bold truncate">{event.title}</h2>
          </div>
          {event.event_link && (
            <Button className="gap-3 flex-shrink-0">
              <Video className="h-4 w-4" />&nbsp;
              <span className="hidden sm:inline">Join Meeting</span>
            </Button>
          )}
        </div>
        <div className="flex flex-wrap gap-4 text-sm text-muted-foreground">
          <div className="flex items-center gap-1.5 min-w-0">
            <Clock className="h-4 w-4 text-primary flex-shrink-0" />
            <span className="truncate">
              {dayjs(event.start_time).format("ddd, MMM D • h:mm A")} - {dayjs(event.end_time).format("h:mm A")}
            </span>
          </div>
          <div className="flex items-center gap-1.5 min-w-0">
            <Users className="h-4 w-4 text-primary flex-shrink-0" />
            <span className="truncate">{event.attendees?.length || 0} attendees</span>
          </div>
        </div>
      </CardHeader>

      <Tabs defaultValue="details" className="flex-1 flex flex-col min-h-0">
        <TabsList className="grid w-full grid-cols-3 px-6 pt-2 flex-shrink-0">
          <TabsTrigger value="details" className="truncate">
            <div className="flex items-center gap-2">
              <Calendar className="h-4 w-4 flex-shrink-0" />
              <span className="truncate">Details</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="participants" className="truncate">
            <div className="flex items-center gap-2">
              <Users className="h-4 w-4 flex-shrink-0" />
              <span className="truncate">Participants</span>
            </div>
          </TabsTrigger>
          {messageCount > 0 && (
            <TabsTrigger value="messages" className="truncate">
              <div className="flex items-center gap-2">
                <MessageCircle className="h-4 w-4 flex-shrink-0" />
                <span className="truncate">Messages</span>
                <Badge variant="secondary" className="ml-1 flex-shrink-0">
                  {messageCount}
                </Badge>
              </div>
            </TabsTrigger>
          )}
        </TabsList>

        <ScrollArea className="flex-1 px-6 py-4">
          <TabsContent value="details" className="m-0 h-full">
            <MeetingDetails event={event} />
          </TabsContent>

          <TabsContent value="participants" className="m-0 h-full">
            <MeetingParticipants event={event} />
          </TabsContent>

          <TabsContent value="messages" className="m-0 h-full">
            <MeetingMessages event={event} messageCount={messageCount} messages={messages} />
          </TabsContent>
        </ScrollArea>
      </Tabs>
    </Card>
  );
};

const MeetingDetails: React.FC<{ event: EventType }> = ({ event }) => {
  const { session } = useSession();
  const organizer = getOrganizer(event.attendees, session.user.email!);

  return (
    <div className="min-w-0 space-y-6 pb-6">
      <div className="flex items-center gap-4 min-w-0">
        <Avatar className="flex-shrink-0 h-12 w-12">
          <AvatarFallback className="bg-primary/10 text-primary">{getInitials(organizer)}</AvatarFallback>
        </Avatar>
        <div className="min-w-0">
          <p className="font-medium truncate">{organizer}</p>
          <p className="text-sm text-muted-foreground">Organizer</p>
        </div>
      </div>

      {/* AI Generated Rundown Section */}
      {event.rundown && (
        <div className="space-y-2 min-w-0">
          <h3 className="text-lg font-semibold flex items-center gap-2 flex-wrap">
            <span className="truncate">Pre-Meeting Rundown</span>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <AlertCircle className="h-4 w-4 text-blue-500 flex-shrink-0" />
                </TooltipTrigger>
                <TooltipContent>
                  <p className="max-w-xs text-sm">
                    Content is AI-generated and may contain errors or inaccuracies. 
                    Please use it as a general guide and verify important information.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </h3>
          <div className="relative rounded-lg p-6 overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10" />
            <div className="relative prose prose-sm dark:prose-invert max-w-none break-words">
              <p className="text-sm font-medium py-1 px-2 rounded whitespace-pre-wrap">
                {event.rundown}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Description Section */}
      {event.description && (
        <div className="space-y-2 min-w-0">
          <h3 className="text-lg font-semibold truncate">Description</h3>
          <div className="prose prose-sm dark:prose-invert max-w-none break-words">
            <div 
              dangerouslySetInnerHTML={{ __html: event.description }}
              className="whitespace-pre-wrap"
            />
          </div>
        </div>
      )}

      {event.html_link && (
        <div className="flex">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="outline" size="sm" className="gap-2 w-full sm:w-auto">
                  <Calendar className="h-4 w-4 flex-shrink-0" />
                  <span className="truncate">View in Calendar</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Open in your calendar app</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}
    </div>
  );
};

const MeetingParticipants: React.FC<{ event: EventType }> = ({ event }) => {
  return (
    <div className="space-y-4">
      {event.attendees?.map((attendee) => (
        <div key={attendee.email} className="flex items-center gap-4 p-2 rounded-lg hover:bg-accent/5">
          <Avatar className="h-12 w-12">
            <AvatarFallback className="bg-primary/10 text-primary">{getInitials(attendee.email ?? attendee.name ?? '')}</AvatarFallback>
          </Avatar>
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-2">
              <p className="font-medium truncate">{attendee.name ?? attendee.email ?? ''}</p>
              {attendee.comment && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <MessageCircle className="h-4 w-4 text-muted-foreground" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{attendee.comment}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            <p className="text-sm text-muted-foreground truncate">{attendee.email}</p>
          </div>
          <Badge variant={attendee.response_status === "accepted" ? "default" : "secondary"} className="flex-shrink-0">
            {attendee.response_status === "needsAction" ? "pending" : attendee.response_status}
          </Badge>
        </div>
      ))}
    </div>
  );
};

const MeetingMessages: React.FC<{ event: EventType; messageCount: number; messages?: EventMessagesResponse }> = ({ event, messageCount, messages: eventMessages }) => {
  const { data: messages, isLoading } = useQuery({
    queryKey: ['eventMessages', event.id],
    queryFn: async () => {
      if (!eventMessages) return [];
      const messageIds = eventMessages.find(m => m.event_id === event.id)?.message_ids;
      if (!messageIds?.length) return [];
      const limit = 1000 as const;
      return getMessagesByUser(supabase, { message_ids: messageIds.map(String) }, limit);
    },
    enabled: messageCount > 0 && !!eventMessages,
  });

  if (!messageCount) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-center p-6">
        <MessageCircle className="h-12 w-12 text-muted-foreground mb-4" />
        <h3 className="font-medium mb-2">No messages yet</h3>
        <p className="text-sm text-muted-foreground">Messages related to this meeting will appear here</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="space-y-4">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="p-2 rounded-lg bg-muted/50 animate-pulse">
            <div className="flex justify-between items-start gap-2 mb-3">
              <div className="h-5 bg-muted-foreground/20 rounded w-32" />
              <div className="flex items-center gap-1.5">
                <div className="h-4 bg-muted-foreground/20 rounded w-20" />
                <div className="h-6 w-6 rounded-lg bg-muted-foreground/20" />
              </div>
            </div>
            <div className="h-4 bg-muted-foreground/20 rounded w-3/4" />
          </div>
        ))}
      </div>
    );
  }

  // Sort messages by date (most recent first) and take first 5
  const sortedMessages = messages
    ?.sort((a, b) => new Date(b.received_at).getTime() - new Date(a.received_at).getTime())
    ?.slice(0, 5);

  return (
    <div className="space-y-4">
      {/* Message Preview Cards */}
      {sortedMessages?.map((message) => {
        const integrationIcon = message.integration_type.toLowerCase() as keyof typeof Icons;
        const Icon = Icons[integrationIcon] || MessageCircle;
        const integrationColor = 
          message.integration_type === "GMAIL" ? "#EA4335" :
          message.integration_type === "OUTLOOK" ? "#0078D4" :
          message.integration_type === "SLACK" ? "#4A154B" : "#6b7280";

        return (
          <Link
            key={message.message_id}
            to={`/messages/?message=${message.message_id}`}
            className={cn(
              "block p-2 rounded-lg bg-muted/50",
              "hover:bg-accent/50 transition-colors relative group"
            )}
          >
            <div className="flex justify-between items-start gap-2">
              <span className="text-sm font-medium">{message.sender_name}</span>
              <div className="flex items-center gap-1.5 shrink-0">
                <span className="text-xs text-muted-foreground">
                  {dayjs(message.received_at).format('MMM D, h:mm A')}
                </span>
                <div 
                  className="p-1 rounded-lg" 
                  style={{ backgroundColor: `${integrationColor}20` }}
                >
                  <Icon 
                    className="w-3 h-3" 
                    style={{ color: integrationColor }} 
                  />
                </div>
              </div>
            </div>
            <div className="relative">
              <p className="text-xs text-muted-foreground mt-1 line-clamp-2 pr-6">
                {decodeHtmlEntities(message.snippet)}
              </p>
              <div className="absolute right-0 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity">
                <ArrowUpRight className="w-4 h-4 text-muted-foreground" />
              </div>
            </div>
          </Link>
        );
      })}

      {/* View All Messages Link */}
      <Link
        to={`/messages/?message_ids=${eventMessages?.find(m => m.event_id === event.id)?.message_ids.join(',') || ''}`}
        className={cn(
          "block w-full p-4 rounded-lg border bg-card text-card-foreground shadow-sm",
          "hover:bg-accent/10 transition-colors relative group"
        )}
      >
        <div className="flex items-center justify-between mb-2">
          <h3 className="font-medium">View All Messages</h3>
          <Badge variant="secondary" className="flex items-center gap-1">
            <MessageCircle className="h-3 w-3" />
            {messageCount}
          </Badge>
        </div>
        <div className="relative">
          <p className="text-sm text-muted-foreground pr-6">View all messages related to this meeting</p>
          <div className="absolute right-0 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity">
            <ArrowUpRight className="w-4 h-4 text-muted-foreground" />
          </div>
        </div>
      </Link>
    </div>
  );
};
