/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useContext } from "react";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { TopEntity } from "@attrove/attrove-ui/app/pages/dashboard/people";
import { Button, Input, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, Progress, Card, CardHeader, CardContent, CardTitle } from "@attrove/ui-shadcn";
import { LoadingSpinner } from "@attrove/attrove-ui/features/people/LoadingSpinner";
import { Brain, Calendar, BarChart2, Hash, ChevronDown, User, ListChecks, ClipboardList, CheckCircle, Info } from "lucide-react";
import { TagCloud } from "@attrove/attrove-ui/features/people/TagCloud";
import { useQuery } from "@tanstack/react-query";
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { Icons } from "@attrove/ui-shadcn";
import { Link } from "react-router-dom";
import { cn } from "@attrove/ui-shadcn";
import { Skeleton } from "@attrove/ui-shadcn";

interface PersonDetailViewProps {
  person: TopEntity;
  onClose: () => void;
  timePeriod: string;
  startDate?: string;
  endDate?: string;
  onNameChange: (entityId: string, newName: string) => void;
}

interface SentimentData {
  sentiment: string;
  count: number;
}

interface PersonSummary {
  summary: string;
  key_points: string[];
  action_items?: string[];
  version: string;
  timestamp: number;
}

// interface IntegrationDetail {
//   id: number;
//   type: string;
//   type_generic: string;
// }

const getIntegrationIcon = (type: string) => {
  switch (type.toLowerCase()) {
    case "gmail":
      return <Icons.gmail className="h-6 w-6" />;
    case "outlook":
      return <Icons.outlook className="h-6 w-6" />;
    case "slack":
      return <Icons.slack className="h-6 w-6" />;
    case "google_meet":
    case "google_calendar":
      return <Icons.google_calendar className="h-6 w-6" />;
    default:
      return <Icons.message className="h-6 w-6" />;
  }
};

const CACHE_EXPIRATION = 60 * 60 * 1000;

const InsightsSkeleton = () => (
  <div className="space-y-6">
    {/* Main Summary Skeleton */}
    <div className="space-y-2">
      <Skeleton className="h-4 w-5/6" />
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-4/5" />
    </div>

    {/* Key Points Skeleton */}
    <div className="space-y-3">
      <h4 className="text-sm font-medium flex items-center gap-2 opacity-50">
        <ListChecks className="w-4 h-4" />
        Key Points
      </h4>
      <div className="grid gap-2">
        {[1, 2, 3].map((i) => (
          <div key={i} className="flex gap-2 items-start p-2 rounded-lg bg-muted/50">
            <div className="w-5 h-5 rounded-full bg-muted flex items-center justify-center shrink-0" />
            <Skeleton className="h-4 w-full" />
          </div>
        ))}
      </div>
    </div>

    {/* Action Items Skeleton */}
    <div className="space-y-3">
      <h4 className="text-sm font-medium flex items-center gap-2 opacity-50">
        <ClipboardList className="w-4 h-4" />
        Action Items
      </h4>
      <div className="grid gap-2">
        {[1, 2].map((i) => (
          <div key={i} className="flex items-start gap-2 p-2 rounded-lg bg-muted/50">
            <div className="p-1 rounded-lg bg-muted shrink-0">
              <CheckCircle className="w-3 h-3 opacity-50" />
            </div>
            <Skeleton className="h-4 w-full" />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export const PersonDetailView: React.FC<PersonDetailViewProps> = ({ person, onClose, timePeriod, startDate, endDate, onNameChange }) => {
  const [name, setName] = useState(person.entity_name);
  const [summary, setSummary] = useState<PersonSummary | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSentimentExpanded, setIsSentimentExpanded] = useState(false);
  const axiosClient = useContext(AxiosClientContext);

  const fetchSentiment = async () => {
    const { data, error } = await supabase.rpc("get_entity_sentiment", {
      p_entity_id: person.entity_id,
      p_start_date: startDate,
      p_end_date: endDate,
      p_time_period: parseInt(timePeriod) || 30,
    });

    if (error) throw error;
    return data as SentimentData[];
  };

  useEffect(() => {
    const fetchSummary = async () => {
      const cacheKey = `personSummary_${person.entity_id}_${timePeriod}`;
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        const parsedData: PersonSummary = JSON.parse(cachedData);
        const isExpired = Date.now() - parsedData.timestamp > CACHE_EXPIRATION;

        if (!isExpired) {
          setSummary(parsedData);
          return;
        }
      }

      setIsLoading(true);
      setError(null);
      try {
        const response = await axiosClient.post("/people/summary", {
          entity_id: person.entity_id,
          time_period: parseInt(timePeriod),
          start_date: startDate,
          stop_date: endDate,
        });

        const newSummary: PersonSummary = {
          ...response.data.summary,
          version: `${response.data.messageCount}-${response.data.eventCount}`,
          timestamp: Date.now(),
        };

        setSummary(newSummary);
        localStorage.setItem(cacheKey, JSON.stringify(newSummary));
      } catch (err) {
        setError("Failed to fetch summary");
        console.error("Error fetching summary:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSummary();
  }, [person.entity_id, timePeriod, startDate, endDate, axiosClient]);

  const {
    data: sentimentData,
    isLoading: isSentimentLoading,
    error: sentimentError,
  } = useQuery({
    queryKey: ["sentiment", person.entity_id, timePeriod, startDate, endDate],
    queryFn: fetchSentiment,
  });

  // Calculate dominant sentiment
  const getSentimentSummary = (data: SentimentData[]) => {
    if (!data || data.length === 0) return { text: "Pending", color: "text-muted-foreground" };
    
    // Create a map of sentiment counts
    const sentimentCounts = new Map<string, number>();
    data.forEach(item => {
      sentimentCounts.set(item.sentiment, item.count);
    });

    const positiveCount = sentimentCounts.get("Positive") || 0;
    const neutralCount = sentimentCounts.get("Neutral") || 0;
    const negativeCount = sentimentCounts.get("Negative") || 0;
    const total = positiveCount + neutralCount + negativeCount;

    if (total === 0) return { text: "Pending", color: "text-muted-foreground" };

    // Find the highest count
    const maxCount = Math.max(positiveCount, neutralCount, negativeCount);

    // Return sentiment based on the highest count
    if (maxCount === positiveCount && positiveCount > 0) {
      return { text: "Positive", color: "text-green-500" };
    }
    if (maxCount === negativeCount && negativeCount > 0) {
      return { text: "Concerned", color: "text-red-500" };
    }
    if (maxCount === neutralCount && neutralCount > 0) {
      return { text: "Neutral", color: "text-yellow-500" };
    }

    return { text: "Pending", color: "text-muted-foreground" };
  };

  const handleNameChange = async () => {
    try {
      const { error } = await supabase.from("entity").update({ name: name }).eq("id", person.entity_id);
      if (error) throw error;
      onNameChange(person.entity_id, name);
    } catch (error) {
      console.error("Error updating name:", error);
    }
  };

  return (
    <div className="space-y-3 pr-3">
      {/* Profile Card */}
      <Card>
        <CardHeader className="p-3 pb-2">
          <div className="flex items-center gap-2">
            <User className="w-4 h-4 text-primary" />
            <h3 className="text-base font-semibold">Profile</h3>
          </div>
        </CardHeader>
        <CardContent className="p-3 pt-0">
          <div className="flex items-center gap-3">
            <div className="flex-grow">
              <Input 
                id="name" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                className="h-8 text-sm" 
              />
            </div>
            <Button 
              onClick={handleNameChange}
              size="sm"
              className="h-8"
            >
              Save
            </Button>
          </div>
        </CardContent>
      </Card>

      {/* Interaction Insights - Move up and update styling */}
      <Card>
        <CardHeader className="p-3 pb-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Brain className="w-4 h-4 text-primary" />
              <CardTitle className="text-base">Interaction Insights</CardTitle>
            </div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex items-center gap-1.5 px-1.5 py-0.5 rounded-md bg-primary/5 border border-primary/10">
                    <Info className="w-3 h-3 text-primary" />
                    <span className="text-xs font-medium text-primary">AI</span>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p className="text-xs max-w-[200px]">
                    This content is generated by AI and may contain errors or inaccuracies. Please verify important information.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </CardHeader>
        <CardContent className="p-3 pt-0">
          {isLoading ? (
            <InsightsSkeleton />
          ) : error ? (
            <p className="text-sm text-red-500 p-2">{error}</p>
          ) : summary ? (
            <div className="space-y-6">
              {/* Main Summary */}
              <div className="prose prose-sm max-w-none">
                <p className="text-sm text-muted-foreground leading-relaxed">
                  {summary.summary}
                </p>
              </div>

              {/* Key Points */}
              <div className="space-y-3">
                <h4 className="text-sm font-medium flex items-center gap-2">
                  <ListChecks className="w-4 h-4 text-primary" />
                  Key Points
                </h4>
                <div className="grid gap-2">
                  {summary.key_points.map((point, index) => (
                    <div
                      key={index}
                      className="flex gap-2 items-start p-2 rounded-lg bg-muted/50"
                    >
                      <div className="w-5 h-5 rounded-full bg-primary/10 text-primary flex items-center justify-center shrink-0 mt-0.5">
                        <span className="text-xs font-medium">{index + 1}</span>
                      </div>
                      <p className="text-sm text-muted-foreground">{point}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Action Items */}
              {summary.action_items && summary.action_items.length > 0 && (
                <div className="space-y-3">
                  <h4 className="text-sm font-medium flex items-center gap-2">
                    <ClipboardList className="w-4 h-4 text-primary" />
                    Action Items
                  </h4>
                  <div className="grid gap-2">
                    {summary.action_items.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-start gap-2 p-2 rounded-lg bg-muted/50 group"
                      >
                        <div className="p-1 rounded-lg bg-primary/10 shrink-0 mt-0.5">
                          <CheckCircle className="w-3 h-3 text-primary" />
                        </div>
                        <p className="text-sm text-muted-foreground">{item}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <p className="text-sm text-muted-foreground">Unable to generate insights.</p>
          )}
        </CardContent>
      </Card>

      {/* Integration Channels Card */}
      <Card>
        <CardHeader className="p-3 pb-2">
          <div className="flex items-center gap-2">
            <Calendar className="w-4 h-4 text-primary" />
            <h3 className="text-base font-semibold">Interaction Channels</h3>
          </div>
        </CardHeader>
        <CardContent className="p-3 pt-0">
          <div className="flex gap-2">
            {person.integration_details.map((integration) => (
              <Link
                key={integration.id}
                to={`/messages?entity=${person.entity_id}&integrations=${integration.id}`}
                className="flex items-center justify-center w-8 h-8 bg-secondary rounded-lg hover:bg-secondary-foreground transition-colors"
                title={integration.type}
              >
                {React.cloneElement(getIntegrationIcon(integration.type), { className: 'w-4 h-4' })}
              </Link>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Sentiment Section */}
      <div className="col-span-full">
        {isSentimentLoading ? (
          <div className="flex justify-center p-3">
            <LoadingSpinner className="h-4 w-4" />
          </div>
        ) : sentimentError ? (
          <Card>
            <CardContent className="p-3">
              <span className="text-red-500 text-sm">Error loading sentiment</span>
            </CardContent>
          </Card>
        ) : sentimentData && sentimentData.length > 0 ? (
          <Card
            className="cursor-pointer transition-all duration-300 hover:bg-accent/5"
            onClick={() => setIsSentimentExpanded(!isSentimentExpanded)}
          >
            <CardHeader className="p-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <BarChart2 className="w-4 h-4 text-primary" />
                  <CardTitle className="text-base">Sentiment Analysis</CardTitle>
                </div>
                <div className="flex items-center gap-2">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <div className="flex items-center gap-1.5 px-1.5 py-0.5 rounded-md bg-primary/5 border border-primary/10">
                          <Info className="w-3 h-3 text-primary" />
                          <span className="text-xs font-medium text-primary">AI</span>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className="text-xs max-w-[200px]">
                          This content is generated by AI and may contain errors or inaccuracies. Please verify important information.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <span className={cn("text-sm font-medium", getSentimentSummary(sentimentData).color)}>
                    {getSentimentSummary(sentimentData).text}
                  </span>
                  <ChevronDown 
                    className={cn(
                      "w-4 h-4 transition-transform duration-200",
                      isSentimentExpanded && "transform rotate-180"
                    )} 
                  />
                </div>
              </div>
            </CardHeader>
            <CardContent className="p-3">
              <div 
                className={cn(
                  "space-y-2 transition-all duration-300 origin-top pt-2",
                  isSentimentExpanded ? "opacity-100 max-h-[500px]" : "opacity-0 max-h-0 overflow-hidden"
                )}
              >
                {(() => {
                  if (!sentimentData || sentimentData.length === 0) {
                    return (
                      <div className="py-6 text-center">
                        <div className="flex flex-col items-center gap-2 text-muted-foreground">
                          <BarChart2 className="w-8 h-8 opacity-50" />
                          <p className="text-sm">Sentiment analysis pending</p>
                        </div>
                      </div>
                    );
                  }

                  const total = sentimentData.reduce((sum, i) => sum + i.count, 0);
                  if (total === 0) {
                    return (
                      <div className="py-6 text-center">
                        <div className="flex flex-col items-center gap-2 text-muted-foreground">
                          <BarChart2 className="w-8 h-8 opacity-50" />
                          <p className="text-sm">Sentiment analysis pending</p>
                        </div>
                      </div>
                    );
                  }

                  // Create a map for easier lookup
                  const sentimentMap = new Map(sentimentData.map(item => [item.sentiment, item.count]));
                  
                  // Always show all three sentiment types in a consistent order
                  const sentimentTypes = [
                    { type: "Positive", color: "[&>div]:bg-green-500" },
                    { type: "Neutral", color: "[&>div]:bg-yellow-500" },
                    { type: "Negative", color: "[&>div]:bg-red-500" }
                  ];

                  return (
                    <>
                      {sentimentTypes.map(({ type, color }) => {
                        const count = sentimentMap.get(type) || 0;
                        const percentage = ((count / total) * 100).toFixed(1);
                        return (
                          <div key={type} className="space-y-1">
                            <div className="flex justify-between text-xs">
                              <span>{type}</span>
                              <span className="font-medium">{percentage}%</span>
                            </div>
                            <Progress
                              value={parseFloat(percentage) || 0}
                              className={cn("h-1.5", color)}
                            />
                          </div>
                        );
                      })}
                    </>
                  );
                })()}
              </div>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent className="p-3">
              <span className="text-sm text-muted-foreground">No sentiment data available</span>
            </CardContent>
          </Card>
        )}
      </div>

      {/* Key Topics Card */}
      <Card>
        <CardHeader className="p-3 pb-2">
          <div className="flex items-center gap-2">
            <Hash className="w-4 h-4 text-primary" />
            <h3 className="text-base font-semibold">Frequent Topics</h3>
          </div>
        </CardHeader>
        <CardContent className="p-3 pt-0">
          <TagCloud tags={person.top_tags} />
        </CardContent>
      </Card>
    </div>
  );
};
