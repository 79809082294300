// timeContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';

interface TimePeriodContextType {
  timePeriod: string;
  setTimePeriod: (value: string) => void;
}

const TimePeriodContext = createContext<TimePeriodContextType | undefined>(undefined);

const TIME_PERIOD_STORAGE_KEY = 'attrove-time-period';
const DEFAULT_TIME_PERIOD = '30';

export function TimePeriodProvider({ children }: { children: React.ReactNode }) {
  // Initialize state from localStorage or default value
  const [timePeriod, setTimePeriodState] = useState(() => {
    const stored = localStorage.getItem(TIME_PERIOD_STORAGE_KEY);
    return stored || DEFAULT_TIME_PERIOD;
  });

  // Update localStorage when timePeriod changes
  const setTimePeriod = (value: string) => {
    setTimePeriodState(value);
    localStorage.setItem(TIME_PERIOD_STORAGE_KEY, value);
  };

  useEffect(() => {
    // Sync with localStorage if it changes in another tab
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === TIME_PERIOD_STORAGE_KEY && e.newValue) {
        setTimePeriodState(e.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <TimePeriodContext.Provider value={{ timePeriod, setTimePeriod }}>
      {children}
    </TimePeriodContext.Provider>
  );
}

export function useTimePeriod() {
  const context = useContext(TimePeriodContext);
  if (context === undefined) {
    throw new Error('useTimePeriod must be used within a TimePeriodProvider');
  }
  return context;
}