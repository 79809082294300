import React, { useState, useRef, useEffect } from "react";
import { Button, Label } from "@attrove/ui-shadcn";
import WavesurferPlayer from "@wavesurfer/react";
import { Play, Pause } from "lucide-react";

export const VoicePreview: React.FC<{ audioUrl: string }> = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [waveformWidth, setWaveformWidth] = useState(0);
  const waveformContainerRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<any>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (waveformContainerRef.current) {
        setWaveformWidth(waveformContainerRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", updateWidth);
    updateWidth(); // Initial width calculation

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const onReady = (wavesurfer: any) => {
    wavesurferRef.current = wavesurfer;
    wavesurfer.on("play", () => setIsPlaying(true));
    wavesurfer.on("pause", () => setIsPlaying(false));
  };

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      if (isPlaying) {
        wavesurferRef.current.pause();
      } else {
        wavesurferRef.current.play();
      }
    }
  };

  return (
    <div className="flex gap-2 flex-col">
      <Label>Preview</Label>
      <div className="w-full p-2 flex flex-col sm:flex-row rounded-lg border bg-opacity-10 items-center gap-4">
        <Button
          size="icon"
          variant="default"
          className="rounded-full h-min w-min p-1 bg-[#00a6fb] text-white hover:bg-[#0084c8] transition-colors duration-200"
          onClick={handlePlayPause}
        >
          {isPlaying ? <Pause className="h-4 w-4" fill="white" /> : <Play className="h-4 w-4" fill="white" />}
        </Button>

        <div ref={waveformContainerRef} className="w-full min-w-0 waveform-container">
          {audioUrl && waveformWidth > 0 && (
            <WavesurferPlayer
              waveColor="#00a7fa"
              progressColor="var(--waveform-color)"
              url={audioUrl}
              height={20}
              width={waveformWidth}
              normalize={true}
              barGap={5}
              barWidth={5}
              cursorWidth={1.5}
              onReady={onReady}
            />
          )}
        </div>
      </div>
    </div>
  );
};
