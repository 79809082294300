import React, { useState, useEffect, useContext } from "react";
import { Card, CardContent, CardHeader, CardTitle, Progress, Skeleton } from "@attrove/ui-shadcn";
import { format } from "date-fns";
import { BarChart3, MessageSquare, Users, TrendingUp, ChevronDown, ArrowUpRight, FileText, ListChecks, Activity, ClipboardList, CheckCircle, Loader2, Info } from "lucide-react";
import { cn } from "@attrove/ui-shadcn";
import { Icons } from "@attrove/ui-shadcn";
import { Link } from "react-router-dom";
import { TagTrend, TagDetail, RelatedPerson } from "@attrove/util-tags";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { useQuery } from "@tanstack/react-query";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@attrove/ui-shadcn";

interface TagSummary {
  summary: string;
  key_points: string[];
  trends: string[];
  action_items?: string[];
  version?: string;
  timestamp?: number;
}

const TagSummarySkeleton = () => (
  <div className="space-y-6">
    {/* Main Summary Skeleton */}
    <div className="space-y-2">
      <Skeleton className="h-4 w-5/6" />
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-4/5" />
    </div>

    {/* Key Points Skeleton */}
    <div className="space-y-3">
      <h4 className="text-sm font-medium flex items-center gap-2 opacity-50">
        <ListChecks className="w-4 h-4" />
        Key Points
      </h4>
      <div className="grid gap-2">
        {[1, 2, 3].map((i) => (
          <div key={i} className="flex gap-2 items-start p-2 rounded-lg bg-muted/50">
            <div className="w-5 h-5 rounded-full bg-muted flex items-center justify-center shrink-0" />
            <Skeleton className="h-4 w-full" />
          </div>
        ))}
      </div>
    </div>

    {/* Trends Skeleton */}
    <div className="space-y-3">
      <h4 className="text-sm font-medium flex items-center gap-2 opacity-50">
        <TrendingUp className="w-4 h-4" />
        Emerging Trends
      </h4>
      <div className="grid gap-2">
        {[1, 2].map((i) => (
          <div key={i} className="flex items-center gap-2 p-2 rounded-lg bg-muted/50">
            <div className="p-1 rounded-lg bg-muted">
              <Activity className="w-3 h-3 opacity-50" />
            </div>
            <Skeleton className="h-4 w-full" />
          </div>
        ))}
      </div>
    </div>

    {/* Action Items Skeleton */}
    <div className="space-y-3">
      <h4 className="text-sm font-medium flex items-center gap-2 opacity-50">
        <ClipboardList className="w-4 h-4" />
        Action Items
      </h4>
      <div className="grid gap-2">
        {[1, 2].map((i) => (
          <div key={i} className="flex items-start gap-2 p-2 rounded-lg bg-muted/50">
            <div className="p-1 rounded-lg bg-muted shrink-0">
              <CheckCircle className="w-3 h-3 opacity-50" />
            </div>
            <Skeleton className="h-4 w-full" />
          </div>
        ))}
      </div>
    </div>
  </div>
);

interface TagDetailProps {
  tag: TagTrend;
}

export function TagDetailView({ tag }: TagDetailProps) {
  const [tagDetail, setTagDetail] = useState<TagDetail | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSentimentExpanded, setIsSentimentExpanded] = useState(false);
  const axiosClient = useContext(AxiosClientContext);

  const { data: summaryData, isLoading: isSummaryLoading } = useQuery({
    queryKey: ['tagSummary', tag.id],
    queryFn: async () => {
      const response = await axiosClient.post("/tags/summary", {
        tag_id: tag.id,
        time_period: 30 // Default to last 30 days
      });
      return response.data.summary as TagSummary;
    }
  });

  useEffect(() => {
    const loadDetails = async () => {
      setIsLoading(true);
      try {
        const details = await tag.getDetails(supabase);
        setTagDetail(details);
      } catch (error) {
        console.error("Error loading tag details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadDetails();
  }, [tag]);

  if (!tagDetail) return <div className="p-4 text-center text-muted-foreground">Loading tag details...</div>;

  const totalMessages = tagDetail.integration_stats.reduce((sum, stat) => sum + stat.count, 0);

  const getSentimentSummary = () => {
    const { positive, neutral, negative } = tagDetail.sentiment;
    const total = positive + neutral + negative;
    
    // If no sentiment data, return no data state
    if (total === 0) return { text: "Pending", color: "text-muted-foreground" };
    
    if (positive > neutral && positive > negative) return { text: "Positive", color: "text-green-500" };
    if (negative > neutral && negative > positive) return { text: "Concerned", color: "text-red-500" };
    return { text: "Neutral", color: "text-yellow-500" };
  };

  const sentimentSummary = getSentimentSummary();

  return (
    <div className="h-full overflow-y-auto space-y-3 pr-3">
      {/* Tag Summary */}
      <Card>
        <CardHeader className="p-3 pb-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <FileText className="w-4 h-4 text-primary" />
              <CardTitle className="text-base">Summary</CardTitle>
            </div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex items-center gap-1.5 px-1.5 py-0.5 rounded-md bg-primary/5 border border-primary/10">
                    <Info className="w-3 h-3 text-primary" />
                    <span className="text-xs font-medium text-primary">AI</span>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p className="text-xs max-w-[200px]">
                    This content is generated by AI and may contain errors or inaccuracies. Please verify important information.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </CardHeader>
        <CardContent className="p-3 pt-0">
          {isSummaryLoading ? (
            <TagSummarySkeleton />
          ) : summaryData ? (
            <div className="space-y-6">
              {/* Main Summary */}
              <div className="prose prose-sm max-w-none">
                <p className="text-sm text-muted-foreground leading-relaxed">
                  {summaryData.summary}
                </p>
              </div>

              {/* Key Points */}
              <div className="space-y-3">
                <h4 className="text-sm font-medium flex items-center gap-2">
                  <ListChecks className="w-4 h-4 text-primary" />
                  Key Points
                </h4>
                <div className="grid gap-2">
                  {summaryData.key_points.map((point, index) => (
                    <div
                      key={index}
                      className="flex gap-2 items-start p-2 rounded-lg bg-muted/50"
                    >
                      <div className="w-5 h-5 rounded-full bg-primary/10 text-primary flex items-center justify-center shrink-0 mt-0.5">
                        <span className="text-xs font-medium">{index + 1}</span>
                      </div>
                      <p className="text-sm text-muted-foreground">{point}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Trends */}
              <div className="space-y-3">
                <h4 className="text-sm font-medium flex items-center gap-2">
                  <TrendingUp className="w-4 h-4 text-primary" />
                  Emerging Trends
                </h4>
                <div className="grid gap-2">
                  {summaryData.trends.map((trend, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-2 p-2 rounded-lg bg-muted/50"
                    >
                      <div className="p-1 rounded-lg bg-primary/10">
                        <Activity className="w-3 h-3 text-primary" />
                      </div>
                      <p className="text-sm text-muted-foreground">{trend}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Action Items */}
              {summaryData.action_items && summaryData.action_items.length > 0 && (
                <div className="space-y-3">
                  <h4 className="text-sm font-medium flex items-center gap-2">
                    <ClipboardList className="w-4 h-4 text-primary" />
                    Action Items
                  </h4>
                  <div className="grid gap-2">
                    {summaryData.action_items.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-start gap-2 p-2 rounded-lg bg-muted/50 group"
                      >
                        <div className="p-1 rounded-lg bg-primary/10 shrink-0 mt-0.5">
                          <CheckCircle className="w-3 h-3 text-primary" />
                        </div>
                        <p className="text-sm text-muted-foreground">{item}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="py-8">
              <div className="flex flex-col items-center justify-center text-center">
                <Loader2 className="w-8 h-8 text-muted-foreground/50 animate-spin mb-4" />
                <p className="text-sm text-muted-foreground">Generating summary...</p>
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      {/* Integration Stats */}
      <Card>
        <CardHeader className="p-3 pb-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <MessageSquare className="w-4 h-4 text-primary" />
              <CardTitle className="text-base flex items-center gap-2">
                Message Distribution
                <span className="text-sm font-normal text-muted-foreground">
                  ({totalMessages} total)
                </span>
              </CardTitle>
            </div>
          </div>
        </CardHeader>
        <CardContent className="p-3 pt-0">
          <div className="space-y-3">
            {tagDetail.integration_stats.map((stat) => {
              const Icon = Icons[stat.icon as keyof typeof Icons];
              const percentage = Math.round((stat.count / totalMessages) * 100);
              return (
                <div key={stat.name} className="flex items-center gap-3">
                  <div 
                    className="p-1.5 rounded-lg shrink-0" 
                    style={{ backgroundColor: `${stat.color}20` }}
                  >
                    <Icon className="w-4 h-4" style={{ color: stat.color }} />
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between mb-1">
                      <span className="text-sm font-medium">{stat.name}</span>
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-medium">{stat.count}</span>
                        <span className="text-xs text-muted-foreground">({percentage}%)</span>
                      </div>
                    </div>
                    <Progress
                      value={percentage}
                      className="h-1.5"
                      style={{ 
                        ['--progress-background' as string]: `${stat.color}20`,
                        ['--progress-foreground' as string]: stat.color 
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>

      {/* People Involved */}
      <Card>
        <CardHeader className="p-3 pb-2">
          <div className="flex items-center gap-2">
            <Users className="w-4 h-4 text-primary" />
            <CardTitle className="text-base">People Involved</CardTitle>
          </div>
        </CardHeader>
        <CardContent className="p-3 pt-0">
          <div className="space-y-2">
            {tagDetail.top_tag_entities.map((person: RelatedPerson) => (
              <Link
                key={person.id}
                to={`/people?entity_name=${encodeURIComponent(person.name)}`}
                className="block hover:bg-accent/5 rounded-lg transition-colors"
              >
                <div className="flex items-center justify-between p-1.5">
                  <div className="flex items-center gap-2">
                    <div className="w-6 h-6 rounded-full bg-brand-primary/10 flex items-center justify-center text-xs">
                      {person.name.charAt(0)}
                    </div>
                    <span className="text-sm font-medium">{person.name}</span>
                  </div>
                  <span className="text-xs text-muted-foreground">
                    {person.message_count} messages
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Sentiment Analysis */}
      <Card
        className="cursor-pointer transition-all duration-300 hover:bg-accent/5"
        onClick={() => setIsSentimentExpanded(!isSentimentExpanded)}
      >
        <CardHeader className="p-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <TrendingUp className="w-4 h-4 text-primary" />
              <CardTitle className="text-base">Sentiment Analysis</CardTitle>
            </div>
            <div className="flex items-center gap-2">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <div className="flex items-center gap-1.5 px-1.5 py-0.5 rounded-md bg-primary/5 border border-primary/10">
                      <Info className="w-3 h-3 text-primary" />
                      <span className="text-xs font-medium text-primary">AI</span>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="text-xs max-w-[200px]">
                      This content is generated by AI and may contain errors or inaccuracies. Please verify important information.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <span className={cn("text-sm font-medium", sentimentSummary.color)}>
                {sentimentSummary.text}
              </span>
              <ChevronDown 
                className={cn(
                  "w-4 h-4 transition-transform duration-200",
                  isSentimentExpanded && "transform rotate-180"
                )} 
              />
            </div>
          </div>
          <div 
            className={cn(
              "space-y-2 transition-all duration-300 origin-top pt-2",
              isSentimentExpanded ? "opacity-100 max-h-[500px]" : "opacity-0 max-h-0 overflow-hidden"
            )}
          >
            {(() => {
              const total = tagDetail.sentiment.positive + tagDetail.sentiment.neutral + tagDetail.sentiment.negative;
              
              // Check if we have any messages but no sentiment analysis yet
              if (total === 0) {
                return (
                  <div className="py-6 text-center">
                    <div className="flex flex-col items-center gap-2 text-muted-foreground">
                      <BarChart3 className="w-8 h-8 opacity-50" />
                      <p className="text-sm">Sentiment analysis pending</p>
                    </div>
                  </div>
                );
              }

              return (
                <>
                  <div className="space-y-1">
                    <div className="flex justify-between text-xs">
                      <span>Positive</span>
                      <span className="font-medium">{Math.round((tagDetail.sentiment.positive / total) * 100) || 0}%</span>
                    </div>
                    <Progress
                      value={(tagDetail.sentiment.positive / total) * 100 || 0}
                      className="h-1.5 [&>div]:bg-green-500"
                    />
                  </div>
                  <div className="space-y-1">
                    <div className="flex justify-between text-xs">
                      <span>Neutral</span>
                      <span className="font-medium">{Math.round((tagDetail.sentiment.neutral / total) * 100) || 0}%</span>
                    </div>
                    <Progress
                      value={(tagDetail.sentiment.neutral / total) * 100 || 0}
                      className="h-1.5 [&>div]:bg-yellow-500"
                    />
                  </div>
                  <div className="space-y-1">
                    <div className="flex justify-between text-xs">
                      <span>Negative</span>
                      <span className="font-medium">{Math.round((tagDetail.sentiment.negative / total) * 100) || 0}%</span>
                    </div>
                    <Progress
                      value={(tagDetail.sentiment.negative / total) * 100 || 0}
                      className="h-1.5 [&>div]:bg-red-500"
                    />
                  </div>
                </>
              );
            })()}
          </div>
        </CardHeader>
      </Card>

      {/* Recent Messages */}
      <Card>
        <CardHeader className="p-3 pb-2">
          <div className="flex items-center gap-2">
            <BarChart3 className="w-4 h-4 text-primary" />
            <CardTitle className="text-base">Recent Messages</CardTitle>
          </div>
        </CardHeader>
        <CardContent className="p-3 pt-0">
          <div className="space-y-2">
            {tagDetail.recent_messages.map((message) => {
              const Icon = Icons[message.integration.toLowerCase() as keyof typeof Icons];
              return (
                <Link
                  key={message.id}
                  to={`/messages/?message=${message.id}`}
                  className={cn(
                    "block p-2 rounded-lg bg-muted/50",
                    "hover:bg-accent/50 transition-colors relative group"
                  )}
                >
                  <div className="flex justify-between items-start gap-2">
                    <span className="text-sm font-medium">{message.sender}</span>
                    <div className="flex items-center gap-1.5 shrink-0">
                      <span className="text-xs text-muted-foreground">
                        {format(new Date(message.timestamp), 'MMM d, h:mm a')}
                      </span>
                      <div 
                        className="p-1 rounded-lg" 
                        style={{ 
                          backgroundColor: message.integration === "Slack" ? "#4A154B20" :
                                        message.integration === "Gmail" ? "#EA433520" : "#0078D420"
                        }}
                      >
                        <Icon 
                          className="w-3 h-3" 
                          style={{ 
                            color: message.integration === "Slack" ? "#4A154B" :
                                  message.integration === "Gmail" ? "#EA4335" : "#0078D4"
                          }} 
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <p className="text-xs text-muted-foreground mt-1 line-clamp-2 pr-6">
                      {message.content}
                    </p>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity">
                      <ArrowUpRight className="w-4 h-4 text-muted-foreground" />
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </CardContent>
      </Card>
    </div>
  );
} 