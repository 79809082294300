import React, { useState, useEffect } from "react";
import { ContentLayout } from "@attrove/attrove-ui/components/layouts/content-layout";
import { MetaTagStats, type TagTrend, getMetaTagStats, getTrendingTags } from "@attrove/util-tags";
import { useTimePeriod } from "@attrove/attrove-ui/context/TimeContext";
import { 
  Card, 
  CardContent, 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue, 
  ScrollArea, 
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@attrove/ui-shadcn";
import { Users, ArrowUp, ArrowDown, Minus, Hash, Search, PlusCircle } from "lucide-react";
import { cn } from "@attrove/ui-shadcn";
import { format, subDays } from "date-fns";
import { Link } from "react-router-dom";
import { TagDetailView } from "../../../features/tags/TagDetail";
import { DetailSidePanel } from "../../../components/layouts/detail-side-panel";
import { supabase } from "../../supabase";

// Add useMediaQuery hook
const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
};

const TrendIcon = ({ direction }: { direction: TagTrend['trend_direction'] }) => {
  const icons = {
    up: <ArrowUp className="w-4 h-4 text-green-500" />,
    down: <ArrowDown className="w-4 h-4 text-red-500" />,
    stable: <Minus className="w-4 h-4 text-yellow-500" />,
    new: <PlusCircle className="w-4 h-4 text-blue-500" />
  };
  return icons[direction];
};

const TagCard = ({ 
  tag, 
  onClick, 
  isSelected,
  onMuteToggle 
}: { 
  tag: TagTrend; 
  onClick: () => void; 
  isSelected: boolean;
  onMuteToggle: (e: React.MouseEvent) => void;
}) => (
  <Card 
    className={cn(
      "hover:border-brand-primary hover:shadow-md hover:scale-[1.01] hover:bg-accent/5",
      "transition-all duration-200 cursor-pointer relative group",
      isSelected && "border-brand-primary bg-accent/5",
      tag.is_muted && "opacity-75"
    )}
    onClick={onClick}
  >
    <CardContent className="p-3">
      <div className="flex items-center justify-between gap-2">
        <div className="flex-1 min-w-0">
          <div className="flex items-center gap-2 mb-1">
            <span className="text-base font-semibold text-brand-primary truncate">{tag.name}</span>
            <span className={cn(
              "px-1.5 py-0.5 rounded-full text-xs whitespace-nowrap",
              tag.importance === 'high' ? "bg-red-100 text-red-700" :
              tag.importance === 'medium' ? "bg-yellow-100 text-yellow-700" :
              "bg-green-100 text-green-700"
            )}>
              {tag.importance === 'high' ? 'highly relevant' :
               tag.importance === 'medium' ? 'relevant' :
               'not relevant'}
            </span>
            <div className="flex items-center gap-1 whitespace-nowrap">
              <TrendIcon direction={tag.trend_direction} />
              <span className="text-xs font-medium">
                {tag.trend_direction === 'new' ? 'New' : `${Math.round(tag.trend_percentage)}%`}
              </span>
            </div>
          </div>
          
          <div className="flex items-center gap-4 text-xs text-muted-foreground mb-2">
            <span>Category: {tag.category}</span>
            <span>Mentions: {tag.count}</span>
            <span>Last: {format(new Date(tag.last_activity), 'MMM d, h:mm a')}</span>
          </div>

          <div className="flex flex-wrap gap-1">
            {tag.top_tag_entities.map((person: { id: string; name: string }) => (
              <Link
                key={person.id}
                to={`/people?entity_name=${encodeURIComponent(person.name)}`}
                className="text-xs px-1.5 py-0.5 rounded-full bg-brand-primary/10 text-brand-primary hover:bg-brand-primary/20 transition-colors"
                onClick={(e) => e.stopPropagation()}
              >
                {person.name}
              </Link>
            ))}
          </div>
        </div>

        <TooltipProvider delayDuration={300}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className={cn(
                  "relative h-7 transition-all duration-200 border shadow-sm",
                  tag.is_muted 
                    ? "bg-secondary hover:bg-secondary/80 text-muted-foreground hover:text-foreground border-muted" 
                    : "bg-background hover:bg-accent text-foreground hover:text-accent-foreground"
                )}
                onClick={onMuteToggle}
              >
                <span className="flex items-center gap-1.5 w-14 justify-center text-xs">
                  {tag.is_muted ? "Unmute" : "Mute"}
                </span>
              </Button>
            </TooltipTrigger>
            <TooltipContent 
              side="bottom" 
              align="end"
              className="max-w-[200px] text-xs z-[100]"
              sideOffset={5}
            >
              {tag.is_muted 
                ? "Include this tag in reports, summaries, and analytics" 
                : "Stop including this tag in reports, summaries, and analytics"}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </CardContent>
  </Card>
);


const StatsCard = ({ icon: Icon, title, value, className }: any) => (
  <Card className="overflow-hidden w-[200px]">
    <CardContent className="p-3">
      <div className="flex items-center gap-3">
        <div className={cn("p-2 rounded-lg shrink-0", className)}>
          <Icon className="w-4 h-4" />
        </div>
        <div className="min-w-0">
          <p className="text-xs font-medium text-muted-foreground truncate">{title}</p>
          <h3 className="text-lg font-bold">{value}</h3>
        </div>
      </div>
    </CardContent>
  </Card>
);

const getStartDate = (days: number) => {
  return subDays(new Date(), days).toISOString();
};

export default function Tags() {
  const { timePeriod, setTimePeriod } = useTimePeriod();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTag, setSelectedTag] = useState<TagTrend | null>(null);
  const [tags, setTags] = useState<TagTrend[]>([]);
  const [metaStats, setMetaStats] = useState<MetaTagStats>({ total_mentions: 0, unique_participants: 0 });
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const isMobile = !useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const startDate = getStartDate(parseInt(timePeriod));
        const [newTags, stats] = await Promise.all([
          getTrendingTags({supabase, start_date: startDate,}),
          getMetaTagStats({supabase, start_date: startDate,})
        ]);
        setTags(newTags);
        setMetaStats(stats);
      } catch (error) {
        console.error('Error fetching tag data:', error);
      }
    };

    fetchData();
  }, [timePeriod]);

  const handleTimePeriodChange = (value: string) => {
    setTimePeriod(value);
  };

  const handleTagSelect = (tag: TagTrend) => {
    setSelectedTag(tag);
  };

  const handleDetailClose = () => {
    setSelectedTag(null);
  };

  const handleMuteToggle = (tagId: string) => async (e: React.MouseEvent) => {
    e.stopPropagation();
    
    try {
      const tag = tags.find(t => t.id === tagId);
      if (!tag) return;

      // Call the appropriate RPC based on current mute status
      const { error } = await supabase.rpc(
        tag.is_muted ? 'unmute_tag' : 'mute_tag',
        { p_tag_id: tagId }
      );

      if (error) throw error;

      // Update local state only after successful RPC call
      setTags(prevTags => {
        const newTags = prevTags.map(tag =>
          tag.id === tagId ? { ...tag, is_muted: !tag.is_muted } : tag
        ) as TagTrend[];
        
        return [
          ...newTags.filter(t => !t.is_muted),
          ...newTags.filter(t => t.is_muted)
        ];
      });
    } catch (error) {
      console.error('Error toggling tag mute status:', error);
    }
  };

  const filteredTags = tags.filter((tag: TagTrend) =>
    tag.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    tag.category.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <ContentLayout>
      <div className="">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h1 className="text-3xl font-bold">Tags & Trends</h1>
              <p className="text-sm text-muted-foreground">
                Track and manage trending tags across your organization
              </p>
            </div>
            <Select onValueChange={handleTimePeriodChange} value={timePeriod}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select time period" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="1">Last 24 hours</SelectItem>
                <SelectItem value="7">Last 7 days</SelectItem>
                <SelectItem value="30">Last 30 days</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex gap-3 mb-4">
            <StatsCard
              icon={Hash}
              title="Unique Tags"
              value={metaStats.total_mentions}
              className="bg-blue-100 text-blue-700"
            />
            <StatsCard
              icon={Users}
              title="Unique Participants"
              value={metaStats.unique_participants}
              className="bg-green-100 text-green-700"
            />
            <div className="flex-1" /> {/* Spacer */}
          </div>
        </div>

        <div className="h-px bg-gradient-to-r from-transparent via-border to-transparent mb-4" />

        <div className="flex-grow min-h-0 h-[calc(100vh-300px)]">
          <div className="h-full flex gap-4 px-4">
            {/* Scrollable Tags List */}
            <Card className={cn(
              "relative overflow-hidden",
              "w-full md:w-1/2", // Full width on mobile, half on desktop
              isMobile && selectedTag && "hidden" // Hide on mobile when detail is shown
            )}>
              <div className="absolute top-0 left-0 w-1 bg-gradient-to-b from-accent to-accent/30" />
              <div className="p-3 border-b bg-background/95 supports-[backdrop-filter]:bg-background/60">
                <div className="flex items-center justify-between gap-3">
                  <h3 className="text-lg font-semibold whitespace-nowrap">Trending Tags</h3>
                  <div 
                    className={cn(
                      "relative flex-1 max-w-[160px] transition-all duration-300 ease-in-out",
                      isSearchFocused && "max-w-sm"
                    )}
                  >
                    <Search 
                      className={cn(
                        "absolute left-2 top-1/2 h-3.5 w-3.5 -translate-y-1/2 transition-colors duration-200",
                        isSearchFocused ? "text-foreground" : "text-muted-foreground"
                      )} 
                    />
                    <input
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onFocus={() => setIsSearchFocused(true)}
                      onBlur={() => {
                        setIsSearchFocused(false);
                        if (!searchQuery.trim()) {
                          setSearchQuery("");
                        }
                      }}
                      className={cn(
                        "w-full rounded-md border bg-background pl-7 pr-3 py-1 text-sm",
                        "placeholder:text-muted-foreground",
                        "focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-0",
                        "transition-all duration-200",
                        isSearchFocused 
                          ? "border-transparent shadow-sm" 
                          : "border-input hover:bg-accent/5"
                      )}
                      placeholder="Search..."
                    />
                  </div>
                </div>
              </div>
              <ScrollArea className="h-full">
                <div className="p-3 space-y-2">
                  {filteredTags.map((tag: TagTrend) => (
                    <TagCard
                      key={tag.id}
                      tag={tag}
                      onClick={() => handleTagSelect(tag)}
                      isSelected={selectedTag?.id === tag.id}
                      onMuteToggle={handleMuteToggle(tag.id)}
                    />
                  ))}
                </div>
              </ScrollArea>
            </Card>

            {/* Detail View - Only shown on desktop */}
            <div className="hidden md:block w-1/2 border-l pl-4">
              {selectedTag ? (
                <TagDetailView tag={selectedTag} />
              ) : (
                <div className="h-full flex items-center justify-center text-muted-foreground">
                  Select a tag to view details
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Mobile Detail View - Only shown on mobile */}
        <DetailSidePanel
          isOpen={!!selectedTag && isMobile}
          onClose={handleDetailClose}
          title={selectedTag?.name || "Tag Details"}
        >
          {selectedTag && <TagDetailView tag={selectedTag} />}
        </DetailSidePanel>
      </div>
    </ContentLayout>
  );
} 