import { Link, Outlet, useNavigate } from "react-router-dom";

import { supabase } from "@attrove/attrove-ui/app/supabase";
import { cn } from "@attrove/ui-shadcn";

const items = [
  {
    title: "Profile",
    href: "/settings/profile",
  },
  {
    title: "Billing",
    href: "/settings/billing",
  },
];

export const SettingsLayout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.log(error);
      return;
    }
    // redirect('/password_signin');
    navigate("/password_signin");
  };

  const pathname = window.location.pathname;

  return (
    <div className="flex h-full">
      <aside className="min-w-40 w-1/3 max-w-64 border-r  p-6">
        <h2 className="text-2xl font-bold mb-6">Settings</h2>
        <nav className="space-y-2">
          {items.map((item, index) => {
            return (
              <Link
              key={index}
              to={item.href}
              className={cn(
                "block px-4 py-2 rounded-md text-sm font-medium transition-colors",
                location.pathname === item.href
                  ? "bg-primary text-primary-foreground"
                  : "text-muted-foreground hover:bg-accent hover:text-accent-foreground"
              )}
            >
                {item.title}
              </Link>
            );
          })}
        </nav>
      </aside>
      <main className="flex-1 overflow-auto">
        <div className="rounded-lg shadow-sm p-10">
          <Outlet />
        </div>
      </main>
    </div>
  );
};
