import { OnboardingFullNameInput, OnboardingPreferredNameInput } from "@attrove/attrove-ui/components/profile/onboarding-name-inputs";
import { RoleInput, RoleType } from "@attrove/attrove-ui/components/profile/role";
import { CurrentGoalInput } from "@attrove/attrove-ui/components/profile/current-goal";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useDisabled } from "./index";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";

export interface Step2Ref {
  getFormData: () => {
    fullName: string;
    preferredName: string;
    currentGoal: string;
    role: RoleType;
  };
}

export const Step2 = forwardRef<Step2Ref>((_, ref) => {
  const { user } = useSession();
  const [formData, setFormData] = useState({
    fullName: user.full_name ?? "",
    preferredName: user.preferred_name ?? "",
    currentGoal: "",
    role: user.role as RoleType
  });
  const { setNextDisabled } = useDisabled();

  useImperativeHandle(ref, () => ({
    getFormData: () => formData
  }));

  // Disable Next button if required fields are empty
  useEffect(() => {
    setNextDisabled(
      !formData.fullName || 
      !formData.preferredName || 
      !formData.role ||
      !formData.currentGoal
    );
  }, [formData, setNextDisabled]);

  return (
    <div className="flex flex-col gap-6" data-step="2">
      <h1 className="text-2xl font-bold">Tell us a bit about yourself</h1>
      <div className="flex flex-col gap-4">
        <OnboardingFullNameInput 
          value={formData.fullName}
          onChange={(value) => setFormData(prev => ({ ...prev, fullName: value }))}
        />
        <OnboardingPreferredNameInput 
          value={formData.preferredName}
          onChange={(value) => setFormData(prev => ({ ...prev, preferredName: value }))}
        />
        <RoleInput 
          value={formData.role}
          onChange={(value) => setFormData(prev => ({ ...prev, role: value }))}
        />
        <CurrentGoalInput 
          value={formData.currentGoal}
          onChange={(value) => setFormData(prev => ({ ...prev, currentGoal: value }))}
        />
      </div>
    </div>
  );
});

Step2.displayName = "Step2";