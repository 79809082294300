import { type AttroveSupabaseClient, DB, userRoleSchema, usersRowSchema, usersUpdateSchema, inactivityStatusSchema, Database } from "@attrove/service-supabase";
import { logError, logInfo } from "@attrove/util-logs";
import { Logtail } from "@logtail/node";
import { z } from "zod";

export type UserUpdate = Omit<z.infer<typeof usersUpdateSchema>, "id">;
export type BaseUser = z.infer<typeof usersRowSchema>;
export type UserRole = z.infer<typeof userRoleSchema>;

export interface UserGoal {
  id: number;
  goal_text: string;
  start_date: string;
  user_id: string;
}

export interface User extends BaseUser {
  latest_goal?: UserGoal | null;
}

export const GET_USER_QUERY_KEY = "GET_USER";

export const getUser = async (supabaseClient: AttroveSupabaseClient, userId: string): Promise<User> => {
  // First get the user data
  const { data: user, error: getUserError } = await supabaseClient
    .from(DB.USERS)
    .select('*')
    .eq('id', userId)
    .single();

  if (getUserError) {
    throw getUserError;
  }

  // Then get the user's latest goal
  const { data: goals, error: goalError } = await supabaseClient
    .from(DB.USER_GOALS)
    .select('id, goal_text, start_date, user_id')
    .eq('user_id', userId)
    .order('start_date', { ascending: false })
    .order('created_at', { ascending: false })
    .limit(1);

  if (goalError) {
    throw goalError;
  }

  const latestGoal = goals && goals.length > 0 ? goals[0] : null;

  // Combine the data
  return {
    ...user,
    latest_goal: latestGoal
  };
};

export const updateUser = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  updatedUser: Partial<User>,
): Promise<User | null> => {
  const { data: user, error: updateUserError } = await supabaseClient.from(DB.USERS).update(updatedUser).eq("id", userId).select().single();

  if (updateUserError) {
    logError("error updating user", { userId, error: updateUserError });
    return null;
  }
  return user;
};

const REPORT_GENERATION_BUFFER_IN_MINUTES = 30;

function padZero(num: number): string {
  return num.toString().padStart(2, '0');
}

function getTimeString(date: Date): string {
  return `${padZero(date.getUTCHours())}:${padZero(date.getUTCMinutes())}:${padZero(date.getUTCSeconds())}`;
}

function isWeekend(date: Date): boolean {
  const day = date.getUTCDay();
  return day === 0 || day === 6; // 0 is Sunday, 6 is Saturday
}

export const getUsersDueForReport = async (supabaseClient: AttroveSupabaseClient, logtail: Logtail): Promise<User[]> => {
  const now = new Date();
  const currentDay = now.getUTCDay();
  
  const bufferStart = new Date(now.getTime() - REPORT_GENERATION_BUFFER_IN_MINUTES * 60000);
  const bufferEnd = new Date(now.getTime() + REPORT_GENERATION_BUFFER_IN_MINUTES * 60000);
  
  const bufferStartTime = getTimeString(bufferStart);
  const bufferEndTime = getTimeString(bufferEnd);
  
  // Check if we're crossing midnight
  const isCrossingMidnight = bufferEnd.getUTCDate() !== bufferStart.getUTCDate();

  // logInfo(
  //   `Getting users due for report`, 
  //   { 
  //     currentTime, 
  //     bufferStartTime, 
  //     bufferEndTime, 
  //     currentDay,
  //     isCrossingMidnight 
  //   }, 
  //   logtail
  // );

  let query = supabaseClient
    .from(DB.USERS)
    .select("*")
    .is("report_pending", false);

  if (isCrossingMidnight) {
    // Handle midnight crossing case explicitly
    // This covers both the end of the current day and start of the next day
    query = query.or(
      `and(report_time.gte.${bufferStartTime},report_time.lt.'24:00:00'),` +
      `and(report_time.gte.'00:00:00',report_time.lt.${bufferEndTime})`
    );
  } else {
    // Normal case within the same day
    query = query.gte("report_time", bufferStartTime).lt("report_time", bufferEndTime);
  }

  // Weekend handling remains the same
  if (isWeekend(now)) {
    query = query.eq("consolidated_weekend", false);
  } else if (currentDay === 1) { // Monday
    if (isCrossingMidnight) {
      query = query.or(
        `consolidated_weekend.eq.true,` +
        `and(consolidated_weekend.eq.false,` +
        `or(and(report_time.gte.${bufferStartTime},report_time.lt.'24:00:00'),` +
        `and(report_time.gte.'00:00:00',report_time.lt.${bufferEndTime})))`
      );
    } else {
      query = query.or(
        `consolidated_weekend.eq.true,` +
        `and(consolidated_weekend.eq.false,report_time.gte.${bufferStartTime},report_time.lt.${bufferEndTime})`
      );
    }
  }

  const { data: users, error: getUsersError } = await query;

  if (getUsersError) {
    logError(`Error getting users due for report`, { error: getUsersError }, logtail);
    return [];
  }

  // logInfo(
  //   `Users due for report`, 
  //   { 
  //     count: users.length, 
  //     currentTime, 
  //     currentDay,
  //     isCrossingMidnight,
  //     bufferStartTime,
  //     bufferEndTime 
  //   }, 
  //   logtail
  // );
  return users;
};

export const markReportAsPending = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  logtail: Logtail
): Promise<void> => {
  const { error } = await supabaseClient
    .from(DB.USERS)
    .update({ report_pending: true })
    .eq("id", userId);

  if (error) {
    logError(`Error marking report as pending for user`, { userId, error }, logtail);
  }
};

export const finalizeReportGeneration = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  logtail: Logtail
): Promise<void> => {
  const now = new Date().toISOString();
  const { error } = await supabaseClient
    .from(DB.USERS)
    .update({ 
      report_pending: false,
      last_report_date: now
    })
    .eq("id", userId);

  if (error) {
    logError(`Error finalizing report generation for user`, { userId, error }, logtail);
  }
};

// Add new types for inactivity management
export type InactivityStatus = z.infer<typeof inactivityStatusSchema>;

// Use return types from the generated functions
type VerifyUserStatusResponse = Database["public"]["Functions"]["verify_user_status"]["Returns"][number];
type GetUsersNeedingWarningResponse = Database["public"]["Functions"]["get_users_needing_warning"]["Returns"][number];
type GetUsersNeedingDeactivationResponse = Database["public"]["Functions"]["get_users_needing_deactivation"]["Returns"][number];
type GetUsersNeedingDeletionResponse = Database["public"]["Functions"]["get_users_needing_deletion"]["Returns"][number];

export interface InactiveUserWithAuth {
  id: string;
  inactivity_status: InactivityStatus;
  last_auth_activity: string;
}

/**
 * Gets users who need an inactivity warning
 * @param supabaseClient - The Supabase client instance
 * @param thresholdDate - The date threshold for inactivity
 * @param logtail - Optional logger
 * @returns Array of users needing warning
 */
export const getUsersNeedingInactivityWarning = async (
  supabaseClient: AttroveSupabaseClient,
  thresholdDate: Date,
  logtail?: Logtail,
): Promise<InactiveUserWithAuth[]> => {
  try {
    const { data, error } = await supabaseClient.rpc(
      'get_users_needing_warning',
      { threshold_date: thresholdDate.toISOString() }
    );

    if (error) {
      logError("Error fetching users needing warning", { error }, logtail);
      return [];
    }

    return (data || []).map((user: GetUsersNeedingWarningResponse) => ({
      id: user.user_id,
      inactivity_status: user.inactivity_status as InactivityStatus,
      last_auth_activity: user.last_auth_activity,
    }));
  } catch (error) {
    logError("Unexpected error fetching users needing warning", { error }, logtail);
    return [];
  }
};

/**
 * Gets users who need deactivation
 * @param supabaseClient - The Supabase client instance
 * @param thresholdDate - The date threshold for deactivation
 * @param logtail - Optional logger
 * @returns Array of users needing deactivation
 */
export const getUsersNeedingDeactivation = async (
  supabaseClient: AttroveSupabaseClient,
  thresholdDate: Date,
  logtail?: Logtail,
): Promise<InactiveUserWithAuth[]> => {
  try {
    const { data, error } = await supabaseClient.rpc(
      'get_users_needing_deactivation',
      { threshold_date: thresholdDate.toISOString() }
    );

    if (error) {
      logError("Error fetching users needing deactivation", { error }, logtail);
      return [];
    }

    return (data || []).map((user: GetUsersNeedingDeactivationResponse) => ({
      id: user.user_id,
      inactivity_status: user.inactivity_status as InactivityStatus,
      last_auth_activity: user.last_auth_activity,
    }));
  } catch (error) {
    logError("Unexpected error fetching users needing deactivation", { error }, logtail);
    return [];
  }
};

/**
 * Gets users who need deletion
 * @param supabaseClient - The Supabase client instance
 * @param thresholdDate - The date threshold for deletion
 * @param logtail - Optional logger
 * @returns Array of users needing deletion
 */
export const getUsersNeedingDeletion = async (
  supabaseClient: AttroveSupabaseClient,
  thresholdDate: Date,
  logtail?: Logtail,
): Promise<InactiveUserWithAuth[]> => {
  try {
    const { data, error } = await supabaseClient.rpc(
      'get_users_needing_deletion',
      { threshold_date: thresholdDate.toISOString() }
    );

    if (error) {
      logError("Error fetching users needing deletion", { error }, logtail);
      return [];
    }

    return (data || []).map((user: GetUsersNeedingDeletionResponse) => ({
      id: user.user_id,
      inactivity_status: user.inactivity_status as InactivityStatus,
      last_auth_activity: user.last_auth_activity,
    }));
  } catch (error) {
    logError("Unexpected error fetching users needing deletion", { error }, logtail);
    return [];
  }
};

/**
 * Updates a user's inactivity status
 * @param supabaseClient - The Supabase client instance
 * @param userId - The user's ID
 * @param status - The new inactivity status
 * @param logtail - Optional logger
 * @returns boolean indicating success
 */
export const updateUserInactivityStatus = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  status: InactivityStatus,
  logtail?: Logtail,
): Promise<boolean> => {
  try {
    const { data, error } = await supabaseClient.rpc(
      'force_update_inactivity_status',
      {
        p_user_id: userId,
        p_status: status,
        p_current_time: new Date().toISOString()
      }
    );

    if (error) {
      logError(
        "Error updating user inactivity status",
        { error, userId, status },
        logtail
      );
      return false;
    }

    logInfo(
      "Successfully updated user inactivity status",
      { userId, status },
      logtail
    );
    
    return !!data;
  } catch (error) {
    logError(
      "Unexpected error updating user inactivity status",
      { error, userId, status },
      logtail
    );
    return false;
  }
};

/**
 * Gets a user's current inactivity status and notification info
 * @param supabaseClient - The Supabase client instance
 * @param userId - The user's ID
 * @param logtail - Optional logger
 * @returns User's inactivity information
 */
export const getUserInactivityInfo = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  logtail?: Logtail,
): Promise<{
  status: InactivityStatus;
  lastNotified: string | null;
  lastActivity: string | null;
  daysInactive: number;
} | null> => {
  try {
    const { data, error } = await supabaseClient.rpc(
      'verify_user_status',
      { p_user_id: userId }
    );

    if (error) {
      logError(
        "Error fetching user inactivity info",
        { error, userId },
        logtail
      );
      return null;
    }

    const userStatus = data?.[0] as VerifyUserStatusResponse;
    if (!userStatus) {
      logError(
        "No user status found",
        { userId },
        logtail
      );
      return null;
    }

    return {
      status: userStatus.inactivity_status as InactivityStatus,
      lastNotified: userStatus.inactivity_notified_at,
      lastActivity: userStatus.last_activity_at,
      daysInactive: userStatus.days_since_activity
    };
  } catch (error) {
    logError(
      "Unexpected error fetching user inactivity info",
      { error, userId },
      logtail
    );
    return null;
  }
};

/**
 * Checks if a user is inactive based on their last activity
 */
export const isUserInactive = (
  lastAuthActivity: string, 
  thresholdDays: number
): boolean => {
  const thresholdDate = new Date();
  thresholdDate.setDate(thresholdDate.getDate() - thresholdDays);
  return new Date(lastAuthActivity) < thresholdDate;
};

// Add interface for goal response
// interface UserGoal {
//   id: number;
//   goal_text: string;
//   start_date: string;
//   user_id: string;
// }

export interface UserWithEmail extends User {
  email?: string;
}

export const getUserWithEmail = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  logtail?: Logtail
): Promise<UserWithEmail | null> => {
  try {
    // First get the user data
    const { data: userData, error: userError } = await supabaseClient
      .from(DB.USERS)
      .select(`
        *,
        email:auth_user_id(email)
      `)
      .eq('id', userId)
      .single();

    if (userError) {
      logError(
        'Error fetching user data',
        { error: userError, userId },
        logtail
      );
      throw userError;
    }

    // Then get the user's latest goal
    const { data: goals, error: goalError } = await supabaseClient
      .from(DB.USER_GOALS)
      .select('id, goal_text, start_date, user_id')
      .eq('user_id', userId)
      .order('start_date', { ascending: false })
      .limit(1);

    if (goalError) {
      logError(
        'Error fetching user goal',
        { error: goalError, userId },
        logtail
      );
      throw goalError;
    }

    const latestGoal = goals && goals.length > 0 ? goals[0] : null;
    
    // Log the data we're returning for debugging
    logInfo(
      'Fetched user with email and goal',
      { 
        userId,
        hasGoal: !!latestGoal,
        goalText: latestGoal?.goal_text
      },
      logtail
    );
    
    // Combine the data
    const data: UserWithEmail = {
      ...userData,
      latest_goal: latestGoal
    };
    
    return data;
  } catch (error) {
    logError(
      'Error in getUserWithEmail',
      { error, userId },
      logtail
    );
    return null;
  }
};

// Add new function to update user goal
export const updateUserGoal = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  goalText: string,
  logtail?: Logtail
): Promise<void> => {
  try {
    // Call the create_user_goal RPC function with just the goal text
    // Let the function handle the start_date with its default CURRENT_DATE
    const { error } = await supabaseClient.rpc('create_user_goal', {
      _goal_text: goalText
    });

    if (error) {
      logError('Error updating user goal', { error, userId }, logtail);
      throw error;
    }

  } catch (error) {
    logError('Unexpected error updating user goal', { error, userId }, logtail);
    throw error;
  }
};

export async function deleteUser(
  supabaseClient: AttroveSupabaseClient,
  userId: string,
) {
  try {
    logInfo("Starting user deletion process", { userId });

    // First delete from public.users (this will cascade to related tables)
    const { error: deleteDataError } = await supabaseClient
      .from('users')
      .delete()
      .eq('id', userId);

    if (deleteDataError) {
      logError("Failed to delete user data", { error: deleteDataError, userId });
      throw deleteDataError;
    }

    // Then delete the auth user
    const { error: deleteAuthError } = await supabaseClient.auth.admin.deleteUser(
      userId
    );

    if (deleteAuthError) {
      logError("Failed to delete auth user", { error: deleteAuthError, userId });
      throw deleteAuthError;
    }

    logInfo("Successfully completed user deletion", { userId });
    return { success: true };
  } catch (error) {
    logError("Error in deleteUser function", {error: error instanceof Error ? error.message : String(error), userId});

    throw error;
  }
}

export async function getUserOnboardReportStatus(
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  logtail?: Logtail
): Promise<boolean> {
  try {
    const { data, error } = await supabaseClient
      .from(DB.USERS)
      .select('onboard_report_once')
      .eq('id', userId)
      .limit(1)
      .single();

    if (error) {
      logError(
        '[getUserOnboardReportStatus] Error fetching user onboard report status',
        { error, userId },
        logtail
      );
      throw error;
    }

    return !!data?.onboard_report_once;
  } catch (error) {
    logError(
      '[getUserOnboardReportStatus] Unexpected error',
      { error: error instanceof Error ? error.message : String(error), userId },
      logtail
    );
    throw error;
  }
}

export async function markUserOnboardReportComplete(
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  logtail?: Logtail
): Promise<void> {
  try {
    const { error } = await supabaseClient
      .from(DB.USERS)
      .update({ onboard_report_once: true })
      .eq('id', userId);

    if (error) {
      logError(
        '[markUserOnboardReportComplete] Error updating user onboard report status',
        { error, userId },
        logtail
      );
      throw error;
    }

    logInfo(
      '[markUserOnboardReportComplete] Successfully marked onboard report as complete',
      { userId },
      logtail
    );
  } catch (error) {
    logError(
      '[markUserOnboardReportComplete] Unexpected error',
      { error: error instanceof Error ? error.message : String(error), userId },
      logtail
    );
    throw error;
  }
}