import { z } from "zod";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useNavigate } from "react-router-dom";

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from "@attrove/ui-shadcn";
import { supabase } from "../../supabase";
import { LoadingButton } from "@attrove/ui-shadcn";
import { Oauth } from "@attrove/attrove-ui/features/auth/oauth-button";
import { Eye, EyeOff, AlertCircle } from "lucide-react";
import { useToast } from "@attrove/ui-shadcn";
import { Alert, AlertDescription } from "@attrove/ui-shadcn";

const passwordSignInSchema = z.object({
  email: z.string().email({
    message: "Invalid email address",
  }),
  password: z.string().min(8, {
    message: "Password must be at least 8 characters",
  }),
});

const PASSWORD_SIGN_IN_DEFAULT_VALUES = {
  email: "",
  password: "",
};

export default function PasswordSignIn() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [authError, setAuthError] = useState<string | null>(null);

  const form = useForm({
    resolver: zodResolver(passwordSignInSchema),
    defaultValues: PASSWORD_SIGN_IN_DEFAULT_VALUES,
  });

  const handleSubmit = async (data: { email: string; password: string }) => {
    setIsSubmitting(true);
    setAuthError(null);

    const { error } = await supabase.auth.signInWithPassword({
      email: data.email,
      password: data.password,
    });

    if (error) {
      const errorMessage = error.message === "Invalid login credentials"
        ? "The email or password you entered is incorrect."
        : "There was a problem signing you in. Please try again.";
      
      setAuthError(errorMessage);
      toast({
        variant: "destructive",
        title: "Authentication Error",
        description: errorMessage,
      });
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(false);
    navigate("/");
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <h1 className="text-2xl font-semibold tracking-tight">Welcome back</h1>
        <p className="text-sm text-muted-foreground">
          Enter your credentials to access your account
        </p>
      </div>
      
      <Oauth />
      
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">
            Or continue with
          </span>
        </div>
      </div>

      {authError && (
        <Alert variant="destructive" className="animate-in fade-in-50">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            {authError}
          </AlertDescription>
        </Alert>
      )}

      <Form {...form}>
        <form noValidate={true} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input 
                    autoCapitalize="none" 
                    autoComplete="email" 
                    autoCorrect="off" 
                    placeholder="name@company.com" 
                    {...field} 
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center justify-between">
                  <FormLabel>Password</FormLabel>
                  <Link 
                    to="/forgot_password" 
                    className="text-sm text-muted-foreground hover:text-primary"
                  >
                    Forgot password?
                  </Link>
                </div>
                <FormControl>
                  <div className="relative">
                    <Input
                      type={showPassword ? "text" : "password"}
                      autoCapitalize="none"
                      autoComplete="current-password"
                      autoCorrect="off"
                      {...field}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-primary"
                    >
                      {showPassword ? (
                        <EyeOff className="h-4 w-4" />
                      ) : (
                        <Eye className="h-4 w-4" />
                      )}
                    </button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <LoadingButton 
            type="submit" 
            className="w-full" 
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Sign in
          </LoadingButton>
        </form>
      </Form>

      <p className="text-center text-sm text-muted-foreground">
        Interested in joining our closed beta?{" "}
        <a
          href="mailto:beta@attrove.com"
          className="font-medium text-primary hover:underline"
        >
          Request access
        </a>
      </p>
    </div>
  );
}
