import { Duration } from 'luxon';

/**
 * Formats a Date object into a detailed time string with hours, minutes, seconds, and milliseconds
 * The format is "HH:MM:SS:mmm" where:
 * - HH: 2-digit hours (00-23)
 * - MM: 2-digit minutes (00-59)
 * - SS: 2-digit seconds (00-59)
 * - mmm: 3-digit milliseconds (000-999)
 * 
 * @param {Date} date - The Date object to format. Defaults to the current date/time if not provided
 * @returns {string} A formatted time string in the format "HH:MM:SS:mmm"
 * 
 * @example
 * // Current time
 * displayClock()
 * // => "14:30:45:234"
 * 
 * @example
 * // Specific date
 * displayClock(new Date('2024-03-20T15:30:45.123'))
 * // => "15:30:45:123"
 */
export function displayClock(date: Date = new Date()) {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0');
  return `${hours}:${minutes}:${seconds}:${milliseconds}`;
}

/**
 * Formats a duration in milliseconds to a human-readable string
 * @param {number} ms Duration in milliseconds
 * @returns {string} Formatted string like "2h 30m" or "500ms"
 */
export function formatDuration(ms: number): string {
  if (ms < 1000) {
    return `${ms}ms`;
  }

  // Convert to hours, minutes, seconds
  const hours = Math.floor(ms / (1000 * 60 * 60));
  const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((ms % (1000 * 60)) / 1000);

  const parts: string[] = [];

  if (hours > 0) {
    parts.push(`${hours}h`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }
  if (seconds > 0 && parts.length === 0) {
    parts.push(`${seconds}s`);
  }

  // If no parts were added (e.g., for very small durations), return ms
  return parts.join(' ') || `${ms}ms`;
}