import React, { useState } from "react";
import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { Card, CardContent, Switch, Button, Separator, Skeleton } from "@attrove/ui-shadcn";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "@attrove/ui-shadcn";
import { 
  getUserNotificationPreferences, 
  updateNotificationPreference,
  getNotificationTypes,
  getActiveNotificationChannels,
  NotificationType,
  NotificationChannel
} from "@attrove/service-supabase";

// Keep categories as a UI organization helper
const NOTIFICATION_CATEGORIES = [
  {
    id: 'critical',
    name: 'Critical Updates',
    description: 'Important notifications that need your immediate attention',
    types: ['urgent_action']  // Use type codes instead of IDs
  },
  {
    id: 'insights',
    name: 'Insights & Analysis',
    description: 'AI-generated insights about your meetings and data',
    types: ['meeting_insight', 'insight']
  },
  {
    id: 'reports',
    name: 'Reports & Summaries',
    description: 'Daily reports and digest notifications',
    types: ['report_ready', 'daily_digest']
  }
];

interface PreferencesProps {
  preferences: Record<string, Record<string, boolean>>;
  channels: NotificationChannel[];
  types: NotificationType[];
  updatePreference: (params: { 
    typeId: string;
    channelId: string;
    enabled: boolean;
  }) => void;
  isLoading?: boolean;
  isUpdating?: boolean;
}

const SimplePreferences = ({ preferences, channels, types, updatePreference, isLoading, isUpdating }: PreferencesProps) => {
  // Helper function to get type IDs from codes
  const getTypeIdsByCode = (codes: string[]): string[] => {
    return types
      .filter(type => codes.includes(type.code))
      .map(type => type.id);
  };

  if (isLoading) {
    return <div className="space-y-6 pt-4">
      {[1, 2, 3].map((i) => (
        <div key={i} className="space-y-4">
          <Skeleton className="h-20 w-full" />
          <Separator />
        </div>
      ))}
    </div>;
  }

  return (
    <div className="space-y-6 pt-4">
      {/* Add header row */}
      <div className="grid grid-cols-[2fr,auto] gap-4 pb-2">
        <div className="text-sm font-medium">Category</div>
        <div className="flex gap-6 pr-1">
          {channels.map(channel => (
            <div key={channel.id} className="w-[52px] text-center text-sm font-medium">
              {channel.name}
            </div>
          ))}
        </div>
      </div>

      {NOTIFICATION_CATEGORIES.map(category => (
        <div key={category.id} className="space-y-4">
          <div className="grid grid-cols-[2fr,auto] gap-4 items-start">
            <div>
              <h3 className="font-medium text-sm">{category.name}</h3>
              <p className="text-sm text-muted-foreground">{category.description}</p>
            </div>
            <div className="flex gap-6 pr-1">
              {channels.map(channel => (
                <div key={channel.id} className="w-[52px] flex justify-center">
                  <Switch
                    disabled={isUpdating}
                    checked={getTypeIdsByCode(category.types).every(typeId => 
                      preferences?.[typeId]?.[channel.id] ?? false
                    )}
                    onCheckedChange={(checked) => {
                      getTypeIdsByCode(category.types).forEach(typeId => {
                        updatePreference({
                          typeId,
                          channelId: channel.id,
                          enabled: checked
                        });
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <Separator />
        </div>
      ))}
    </div>
  );
};

const AdvancedPreferences = ({ preferences, channels, types, updatePreference, isLoading, isUpdating }: PreferencesProps) => {
  if (isLoading) {
    return <div className="space-y-6 pt-4">
      {[1, 2, 3, 4].map((i) => (
        <div key={i}>
          <Skeleton className="h-16 w-full" />
        </div>
      ))}
    </div>;
  }

  return (
    <div className="space-y-6 pt-4">
      {/* Header row */}
      <div className="grid grid-cols-[2fr,auto] gap-4 pb-2">
        <div className="text-sm font-medium">Notification Type</div>
        <div className="flex gap-6 pr-1">
          {channels.map(channel => (
            <div key={channel.id} className="w-[52px] text-center text-sm font-medium">
              {channel.name}
            </div>
          ))}
        </div>
      </div>

      {/* Content rows */}
      {types.map(type => (
        <div key={type.id} className="grid grid-cols-[2fr,auto] gap-4 items-center">
          <div>
            <h3 className="text-sm font-medium">{type.name}</h3>
            <p className="text-sm text-muted-foreground">{type.description}</p>
          </div>
          <div className="flex gap-6 pr-1">
            {channels.map(channel => (
              <div key={channel.id} className="w-[52px] flex justify-center">
                <Switch
                  disabled={isUpdating}
                  checked={preferences?.[type.id]?.[channel.id] ?? false}
                  onCheckedChange={(checked) => {
                    updatePreference({
                      typeId: type.id,
                      channelId: channel.id,
                      enabled: checked
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export const NotificationPreferences = () => {
  const { user } = useSession();
  const [showAdvanced, setShowAdvanced] = useState(false);

  const { data: preferences = {}, isLoading: prefsLoading } = useQuery({
    queryKey: ['notification-preferences', user.id],
    queryFn: () => getUserNotificationPreferences(supabase, user.id),
    staleTime: 30000, // Consider preferences stale after 30 seconds
    retry: 2
  });

  const { data: channels = [], isLoading: channelsLoading } = useQuery({
    queryKey: ['notification-channels'],
    queryFn: () => getActiveNotificationChannels(supabase),
    staleTime: Infinity, // These rarely change
    retry: 2
  });

  const { data: types = [], isLoading: typesLoading } = useQuery({
    queryKey: ['notification-types'],
    queryFn: () => getNotificationTypes(supabase),
    staleTime: Infinity, // These rarely change
    retry: 2
  });

  const updatePreference = useMutation({
    mutationFn: async ({ typeId, channelId, enabled }: {
      typeId: string;
      channelId: string;
      enabled: boolean;
    }) => {
      return await updateNotificationPreference(supabase, {
        user_id: user.id,
        notification_type_id: typeId,
        notification_channel_id: channelId,
        is_enabled: enabled
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ 
        queryKey: ['notification-preferences', user.id] 
      });
    },
    onError: (error) => {
      toast.error('Failed to update notification preference');
      console.error('Error updating notification preference:', error);
    }
  });

  // Check if all notifications are enabled
  const areAllEnabled = React.useMemo(() => {
    if (!types.length || !channels.length) return false;
    return types.every(type => 
      channels.every(channel => 
        preferences?.[type.id]?.[channel.id] ?? false
      )
    );
  }, [types, channels, preferences]);

  // Handle toggling all notifications
  const handleToggleAll = (enabled: boolean) => {
    types.forEach(type => {
      channels.forEach(channel => {
        updatePreference.mutate({
          typeId: type.id,
          channelId: channel.id,
          enabled
        });
      });
    });
  };

  const isLoading = prefsLoading || channelsLoading || typesLoading;

  if (isLoading && !types.length && !channels.length) {
    return (
      <Card className="max-w-2xl">
        <CardContent className="p-6">
          <Skeleton className="h-[400px] w-full" />
        </CardContent>
      </Card>
    );
  }

  const handlePreferenceUpdate = ({ typeId, channelId, enabled }: {
    typeId: string;
    channelId: string;
    enabled: boolean;
  }) => {
    updatePreference.mutate({ typeId, channelId, enabled });
  };

  return (
    <Card className="max-w-2xl">
      <CardContent className="p-6">
        <div className="flex flex-col gap-6">
          <div className="flex justify-between items-start">
            <div className="space-y-2">
              <h2 className="text-lg font-semibold">Notification Preferences</h2>
              <p className="text-sm text-muted-foreground max-w-md">
                Manage how and when you want to be notified about important updates, insights, and reports.
              </p>
            </div>
            <Button 
              variant="ghost" 
              onClick={() => setShowAdvanced(!showAdvanced)}
              disabled={isLoading}
            >
              {showAdvanced ? 'Simple' : 'Advanced'}
            </Button>
          </div>

          <div className="flex items-center justify-between py-4 border-y border-border">
            <div>
              <h3 className="font-medium">All Notifications</h3>
            </div>
            <Switch
              checked={areAllEnabled}
              onCheckedChange={handleToggleAll}
              aria-label="Toggle all notifications"
              disabled={isLoading || updatePreference.isPending}
            />
          </div>

          {!showAdvanced ? (
            <SimplePreferences 
              preferences={preferences}
              channels={channels}
              types={types}
              updatePreference={handlePreferenceUpdate}
              isLoading={isLoading}
              isUpdating={updatePreference.isPending}
            />
          ) : (
            <AdvancedPreferences 
              preferences={preferences}
              channels={channels}
              types={types}
              updatePreference={handlePreferenceUpdate}
              isLoading={isLoading}
              isUpdating={updatePreference.isPending}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
}; 