import { ReportTimeInput } from "@attrove/attrove-ui/components/profile/report-time";

export const Step4 = () => {
  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl font-bold">When should we deliver your daily insights?</h1>
      <p className="text-muted-foreground">We'll deliver you a written summary and audio rundown at this time every day.</p>

      <ReportTimeInput />
    </div>
  );
};
