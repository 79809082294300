import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input, LoadingButton } from "@attrove/ui-shadcn";
import { Oauth } from "@attrove/attrove-ui/features/auth/oauth-button";

const emailSignInSchema = z.object({
  email: z.string().email({
    message: "Invalid email address",
  }),
});

const EMAIL_SIGN_IN_DEFAULT_VALUES = {
  email: "",
};

export default function EmailSignIn() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm({
    resolver: zodResolver(emailSignInSchema),
    defaultValues: EMAIL_SIGN_IN_DEFAULT_VALUES,
  });

  const handleSubmit = async (data: { email: string }) => {
    setIsSubmitting(true);
    // const redirectPath = await signInWithEmail(data);
    // router.push(redirectPath);
    setIsSubmitting(false);
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <h1 className="text-2xl font-semibold tracking-tight">Welcome back</h1>
        <p className="text-sm text-muted-foreground">
          Sign in via magic link with your email below
        </p>
      </div>

      <Oauth />
      
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">
            Or continue with
          </span>
        </div>
      </div>

      <Form {...form}>
        <form noValidate={true} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input 
                    autoCapitalize="none" 
                    autoComplete="email" 
                    autoCorrect="off" 
                    placeholder="name@company.com" 
                    {...field} 
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <LoadingButton 
            type="submit" 
            className="w-full" 
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Send magic link
          </LoadingButton>
        </form>
      </Form>

      <div className="space-y-2 text-center text-sm">
        <Link 
          to="/password_signin" 
          className="text-muted-foreground hover:text-primary"
        >
          Sign in with password
        </Link>
        <p className="text-muted-foreground">
          Interested in joining our closed beta?{" "}
          <a
            href="mailto:beta@attrove.com"
            className="font-medium text-primary hover:underline"
          >
            Request access
          </a>
        </p>
      </div>
    </div>
  );
}
