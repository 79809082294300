import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { ContentLayout } from "@attrove/attrove-ui/components/layouts/content-layout";

import { GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY, getIntegrationsWithCredById } from "@attrove/service-supabase";
import { EmailDetail } from "@attrove/attrove-ui/features/integrations/integration-detali/email";
import { CalendarDetail } from "@attrove/attrove-ui/features/integrations/integration-detali/calendar";
import { MeetingDetail } from "@attrove/attrove-ui/features/integrations/integration-detali/meeting";
import { ChatDetail } from "@attrove/attrove-ui/features/integrations/integration-detali/chat";

const INTEGRATION_COMPONENTS = {
  google_calendar: CalendarDetail,
  gmail: EmailDetail,
  google_meet: MeetingDetail,
  outlook: EmailDetail,
  slack: ChatDetail,
} as const;

const INTEGRATION_TITLES = {
  google_calendar: "Google Calendar",
  gmail: "Gmail",
  google_meet: "Google Meet",
  outlook: "Outlook",
  slack: "Slack",
} as const;

type IntegrationType = keyof typeof INTEGRATION_COMPONENTS;

export default function IntegrationDetail() {
  const { integrationId } = useParams();

  const {
    data: integration,
    isLoading,
    error,
  } = useQuery({
    queryKey: [GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY, integrationId],
    queryFn: () => getIntegrationsWithCredById(supabase, Number(integrationId)),
    enabled: !!integrationId,
  });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <ContentLayout title="Error">An error occurred: {error.message}</ContentLayout>;
  }

  if (!integration) {
    return <ContentLayout title="Not Found">Integration not found</ContentLayout>;
  }

  const IntegrationComponent = INTEGRATION_COMPONENTS[integration.type as IntegrationType];
  const integrationTitle = INTEGRATION_TITLES[integration.type as IntegrationType] || integration.type;

  if (!IntegrationComponent) {
    return <ContentLayout title="Unsupported Type">Unsupported integration type: {integration.type}</ContentLayout>;
  }

  return <IntegrationComponent integrationId={integration.id} />;
}
