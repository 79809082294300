import React, { useState } from 'react';
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { toTimeAgo } from "@attrove/attrove-ui/lib/utils";
import {
  GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY,
  getIntegrationsWithCredById,
  GET_MEETINGS_BY_INTEGRATION_ID_QUERY_KEY,
  getMeetingsByIntegrationId,
} from "@attrove/service-supabase";
import { Icons, ScrollArea, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Button } from "@attrove/ui-shadcn";
import { useQuery } from "@tanstack/react-query";
import { Loader2, Calendar, Clock, Video, ChevronLeft, ChevronRight } from "lucide-react";
import { IntegrationActions } from "../integration-actions";

const MeetingDetailTable: React.FC<{ meeting: any }> = ({ meeting }) => {
  if (!meeting) return null;

  const meetingDate = new Date(meeting.modified_at);
  
  return (
    <div className="group grid grid-cols-[1fr_auto] gap-4 items-center p-4 bg-card rounded-lg border max-w-7xl mx-auto transition-all duration-200 hover:bg-accent/5 hover:shadow-sm">
      <div className="min-w-0">
        <div className="flex items-center gap-3 mb-2">
          <div className="flex-shrink-0 p-2 rounded-full bg-primary/10">
            <Video className="w-4 h-4 text-primary" />
          </div>
          <h3 className="font-semibold text-foreground group-hover:text-primary transition-colors truncate">
            {meeting.name || 'Untitled Meeting'}
          </h3>
        </div>
        <div className="flex flex-wrap items-center gap-4 text-sm text-muted-foreground">
          <div className="flex items-center flex-shrink-0">
            <Calendar className="w-4 h-4 mr-2" />
            <span>{meetingDate.toLocaleDateString(undefined, { 
              month: 'short', 
              day: 'numeric', 
              year: 'numeric' 
            })}</span>
          </div>
          <div className="flex items-center flex-shrink-0">
            <Clock className="w-4 h-4 mr-2" />
            <span>{meetingDate.toLocaleTimeString(undefined, { 
              hour: 'numeric', 
              minute: '2-digit'
            })}</span>
          </div>
        </div>
      </div>
      <a 
        href={meeting.drive_url} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="flex-shrink-0 whitespace-nowrap px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 transition-colors duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
      >
        View
      </a>
    </div>
  );
};

export const MeetingDetail: React.FC<{ integrationId: number }> = ({ integrationId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 15; // Fixed number of items per page

  const { data: integration, isLoading: integrationLoading, error: integrationError } = useQuery({
    queryKey: [GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY, integrationId],
    queryFn: () => getIntegrationsWithCredById(supabase, Number(integrationId)),
  });

  const { data: meetings, isLoading: meetingsLoading, error: meetingsError } = useQuery({
    queryKey: [GET_MEETINGS_BY_INTEGRATION_ID_QUERY_KEY, integrationId],
    queryFn: () => getMeetingsByIntegrationId(supabase, integrationId),
  });

  if (integrationLoading || meetingsLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }
  
  if (integrationError || meetingsError) {
    return (
      <div className="flex items-center justify-center h-64 text-destructive">
        Error loading data. Please try again.
      </div>
    );
  }
  
  if (!integration) {
    return (
      <div className="flex items-center justify-center h-64 text-muted-foreground">
        No integration data available.
      </div>
    );
  }

  const GoogleMeet = Icons["google_meet"];

  // Sort meetings by modified_at in descending order (most recent first)
  const sortedMeetings = meetings ? 
    [...meetings].sort((a, b) => new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime()) 
    : [];

  // Pagination calculations
  const totalPages = meetings ? Math.ceil(meetings.length / ITEMS_PER_PAGE) : 0;
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentMeetings = sortedMeetings.slice(startIndex, endIndex);

  const PaginationControls = () => (
    <div className="flex items-center justify-between px-4 py-3 border-t bg-card">
      <div className="flex-1 text-sm text-muted-foreground">
        Showing <span className="font-medium">{startIndex + 1}</span> to{" "}
        <span className="font-medium">{Math.min(endIndex, sortedMeetings.length)}</span> of{" "}
        <span className="font-medium">{sortedMeetings.length}</span> recordings
      </div>
      <div className="flex items-center gap-2">
        <Button
          variant="outline"
          size="icon"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="h-8 w-8"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <div className="text-sm text-muted-foreground px-2">
          Page {currentPage} of {totalPages}
        </div>
        <Button
          variant="outline"
          size="icon"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="h-8 w-8"
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );

  return (
    <div className="pt-6 flex flex-col">
      <div className="mx-auto w-full px-4 sm:px-6 md:px-8">
        <div className="flex gap-2 items-center">
          <GoogleMeet className="w-8 h-8" />
          <h1 className="text-2xl font-semibold">Google Meet</h1>
        </div>

        <p className="text-muted-foreground mt-6 mb-4">We pull your meeting transcripts and provide you with valuable insights.</p>
        
        <div className="flex flex-col gap-4 rounded-lg border p-4 relative bg-card max-w-7xl mx-auto">
          <div className="absolute top-4 right-4">
            {integration.is_active ? (
              <div className="flex items-center gap-2">
                <span className="inline-block bg-green-500 h-2.5 w-2.5 rounded-full" />
                <span className="text-sm text-muted-foreground">Active</span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <span className="inline-block bg-red-500 h-2.5 w-2.5 rounded-full" />
                <span className="text-sm text-muted-foreground">Inactive</span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <h2 className="text-xl font-semibold">Integration</h2>
            {integration.last_synced_at && (
              <div className="flex gap-1 text-sm text-muted-foreground">
                <p>Last sync: </p>
                {integration.pending_sync ? (
                  <div className="flex items-center gap-2">
                    <p>currently syncing</p>
                    <Loader2 className="w-4 h-4 animate-spin" />
                  </div>
                ) : (
                  <p>{toTimeAgo(integration.last_synced_at)}</p>
                )}
              </div>
            )}
          </div>
          <p className="text-sm text-muted-foreground">{meetings ? meetings.length : 0} meeting transcripts pulled</p>
          <IntegrationActions integration={integration} />
        </div>

        <div className="mt-8 flex-1">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold">Recent Recordings</h2>
            <span className="text-sm text-muted-foreground">
              {sortedMeetings.length} total recordings
            </span>
          </div>
          <div className="rounded-lg border bg-card">
            <ScrollArea className="h-[calc(100vh-560px)]">
              <div className="space-y-3 p-4">
                {sortedMeetings.length > 0 ? (
                  currentMeetings.map((meeting) => (
                    <MeetingDetailTable key={meeting.id} meeting={meeting} />
                  ))
                ) : (
                  <div className="flex flex-col items-center justify-center py-12 text-muted-foreground">
                    <div className="bg-muted/30 p-4 rounded-full mb-4">
                      <Video className="w-8 h-8" />
                    </div>
                    <p className="font-medium mb-1">No recordings yet</p>
                    <p className="text-sm">Your meeting recordings will appear here once they're available</p>
                  </div>
                )}
              </div>
            </ScrollArea>
            {sortedMeetings.length > 0 && <PaginationControls />}
          </div>
        </div>
      </div>
    </div>
  );
};