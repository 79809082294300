import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { Loader2 } from "lucide-react";
import { cn } from "@attrove/ui-shadcn";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogFooter,
  Button,
  Input,
  Label,
  toast,
} from "@attrove/ui-shadcn";

function DeletionProgress({ step }: { step: number }) {
  const steps = [
    "Disconnecting integrations",
    "Removing data",
    "Deleting account",
    "Signing out"
  ];

  return (
    <div className="space-y-6 py-4">
      <div className="flex justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-destructive" />
      </div>
      <div className="space-y-4">
        {steps.map((text, index) => (
          <div
            key={text}
            className={cn(
              "flex items-center space-x-3 text-sm transition-all duration-300",
              index <= step ? "text-destructive" : "text-muted-foreground opacity-50"
            )}
          >
            <div
              className={cn(
                "h-2 w-2 rounded-full transition-all duration-300",
                index < step ? "bg-destructive" : "bg-muted-foreground",
                index === step ? "animate-pulse bg-destructive" : ""
              )}
            />
            <span>{text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export function DeleteAccount() {
  const navigate = useNavigate();
  const axiosClient = useContext(AxiosClientContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmation, setConfirmation] = useState("");
  const [deletionStep, setDeletionStep] = useState(0);

  useEffect(() => {
    if (isDeleting) {
      const interval = setInterval(() => {
        setDeletionStep(step => (step < 3 ? step + 1 : step));
      }, 800);
      return () => clearInterval(interval);
    } else {
      setDeletionStep(0);
    }
  }, [isDeleting]);

  const handleDelete = async () => {
    if (confirmation.toLowerCase() !== "delete my account") {
      toast({
        title: "Error",
        description: "Please type 'delete my account' to confirm",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsDeleting(true);
      
      // Get the current session
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('No active session');

      // Call our API to delete the user
      await axiosClient({
        method: 'delete',
        url: '/account',
      });

      // Sign out the user
      await supabase.auth.signOut();
      
      // Show success message
      toast({
        title: "Success",
        description: "Your account has been deleted",
      });
      
      // Redirect to home page
      navigate("/");
    } catch (error: any) {
      console.error("Error deleting account:", error);
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message || "Failed to delete account. Please try again or contact support.",
        variant: "destructive",
      });
    } finally {
      setIsDeleting(false);
      setIsOpen(false);
    }
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger asChild>
        <Button variant="destructive">Delete Account</Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="max-w-md">
        {!isDeleting ? (
          <>
            <AlertDialogHeader>
              <AlertDialogTitle className="text-destructive">
                Delete Account Permanently
              </AlertDialogTitle>
              <div className="space-y-4">
                <AlertDialogDescription>
                  This action <span className="font-semibold">cannot be undone</span>.
                  This will permanently delete your account and remove your data
                  from our servers.
                </AlertDialogDescription>
                <div className="space-y-4">
                  <div className="text-sm text-muted-foreground">
                    The following data will be permanently deleted:
                  </div>
                  <ul className="list-disc list-inside space-y-1 text-sm text-muted-foreground">
                    <li>All your personal information</li>
                    <li>Your preferences and settings</li>
                    <li>Your integration connections</li>
                    <li>All generated reports and insights</li>
                    <li>Notification preferences</li>
                  </ul>
                  <div className="space-y-2">
                    <Label htmlFor="confirm" className="text-sm font-medium">
                      Type &quot;delete my account&quot; to confirm
                    </Label>
                    <Input
                      id="confirm"
                      value={confirmation}
                      onChange={(e) => setConfirmation(e.target.value)}
                      placeholder="delete my account"
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </AlertDialogHeader>
            <AlertDialogFooter className="gap-2 sm:gap-0">
              <Button
                variant="ghost"
                onClick={() => setIsOpen(false)}
                disabled={isDeleting}
              >
                Cancel
              </Button>
              <Button
                variant="destructive"
                onClick={handleDelete}
                disabled={
                  isDeleting ||
                  confirmation.toLowerCase() !== "delete my account"
                }
              >
                Delete Account
              </Button>
            </AlertDialogFooter>
          </>
        ) : (
          <div className="py-4">
            <h2 className="text-lg font-semibold text-destructive text-center mb-2">
              Deleting Your Account
            </h2>
            <p className="text-sm text-muted-foreground text-center mb-6">
              Please wait while we securely delete your account and data...
            </p>
            <DeletionProgress step={deletionStep} />
          </div>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
} 