import { useSession } from "@attrove/attrove-ui/context/SessionContext";

export const Step1 = () => {
  const { user } = useSession();
  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-2xl font-bold">Welcome to Attrove!</h1>
      <div className="flex flex-col gap-4">
        <p>Hey {user?.full_name ? user.full_name : "there"},</p>

        <p>
          I'm Tim, Attrove's founder, and I'm thrilled you're joining our closed beta! You're now part of an exclusive group exploring
          organizational AI.
        </p>

        <div className="flex flex-col gap-2">
          <p>As you explore, remember:</p>

          <ol className="list-decimal list-inside pl-4">
            <li>You might spot some bugs—that's part of the adventure!</li>
            <li>Features are evolving rapidly, so keep checking back.</li>
            <li>Your feedback is invaluable—don't hesitate to share!</li>
          </ol>
        </div>

        <p>
          Feel free to reach out to me at{" "}
          <a href="mailto:tim@attrove.com" className="text-primary hover:underline">
            tim@attrove.com
          </a>{" "}
          with any thoughts or questions.
        </p>

        <p>Thank you for being an Attrove pioneer. Can't wait to hear what you think!</p>

        <div className="flex flex-col">
          <p>Best,</p>
          <p className="font-semibold">Tim Monzures</p>
          <p className="italic">Founder & CEO, Attrove</p>
        </div>
      </div>
    </div>
  );
};
