import { supabase } from "@attrove/attrove-ui/app/supabase";
import { toTimeAgo } from "@attrove/attrove-ui/lib/utils";
import {
  GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY,
  getIntegrationsWithCredById,
  getMessageCountForIntegration,
  MESSAGES_COUNT_QUERY_KEY
} from "@attrove/service-supabase";
import { Icons } from "@attrove/ui-shadcn";
import { useQuery } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";
import { IntegrationActions } from "../integration-actions";

type EmailProvider = 'gmail' | 'outlook';

const EMAIL_PROVIDER_CONFIG: Record<EmailProvider, { title: string; icon: keyof typeof Icons }> = {
  gmail: { title: 'Gmail', icon: 'gmail' },
  outlook: { title: 'Outlook', icon: 'outlook' },
};

export const EmailDetail: React.FC<{ integrationId: number }> = ({ integrationId }) => {
  const { data: integration, isLoading } = useQuery({
    queryKey: [GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY, integrationId],
    queryFn: () => getIntegrationsWithCredById(supabase, Number(integrationId)),
  });

  const { data: messageCount, isLoading: messagesLoading } = useQuery({
    queryKey: [MESSAGES_COUNT_QUERY_KEY, integrationId],
    queryFn: () => getMessageCountForIntegration(supabase, integrationId),
  });

  if (isLoading || messagesLoading) return <p>Loading...</p>;
  if (!integration || !messageCount) return null;

  const emailProvider = (integration.type?.toLowerCase() as EmailProvider) || 'gmail';
  const config = EMAIL_PROVIDER_CONFIG[emailProvider] || EMAIL_PROVIDER_CONFIG.gmail; // Default to Gmail if unknown type

  const IconComponent = Icons[config.icon];

  return (
    <div className="pt-6">
      <div className="mx-auto max-w-7xl flex gap-2 px-4 sm:px-6 md:px-8">
        <IconComponent className="w-8 h-8" />
        <h1 className="text-2xl">{config.title}</h1>
      </div>

      <div className="mx-auto max-w-7xl px-4 pt-6 sm:px-6 md:px-8">
        <p className="text-muted-foreground pb-4">We pull your emails and provide you with valuable insights.</p>
        <div className="flex flex-col gap-4 rounded-lg border p-4 relative bg-card max-w-7xl mx-auto">
          <div className="absolute top-4 right-4">
            {integration.is_active ? (
              <div className="flex items-center gap-2">
                <span className="inline-block bg-green-500 h-2.5 w-2.5 rounded-full" />
                <span className="text-sm text-muted-foreground">Active</span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <span className="inline-block bg-red-500 h-2.5 w-2.5 rounded-full" />
                <span className="text-sm text-muted-foreground">Inactive</span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <h2 className="text-xl">Integration</h2>
            {integration.last_synced_at && (
              <div className="flex gap-1 text-sm text-muted-foreground">
                <p>Last sync: </p>
                {integration.pending_sync ? (
                  <div className="flex items-center gap-2">
                    <p>currently syncing</p>
                    <Loader2 className="w-4 h-4 animate-spin" />
                  </div>
                ) : (
                  <p> {toTimeAgo(integration.last_synced_at)}</p>
                )}
              </div>
            )}
          </div>
          <p className="text-sm text-muted-foreground">
            {messageCount.toLocaleString()} messages pulled
          </p>
          <IntegrationActions integration={integration} />
        </div>
      </div>
    </div>
  );
};