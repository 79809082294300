import React, { useState, useEffect } from "react";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { ContentLayout } from "@attrove/attrove-ui/components/layouts/content-layout";
import { useQuery } from "@tanstack/react-query";
import { PeopleTable } from "@attrove/attrove-ui/features/people/table";
import { columns } from "@attrove/attrove-ui/features/people/columns";
import { PersonDetailView } from "@attrove/attrove-ui/features/people/PersonDetail";
import { DetailSidePanel } from "../../../components/layouts/detail-side-panel";
import { useTimePeriod } from "../../../context/TimeContext";
import { Users, Clock } from "lucide-react";

export type TopEntity = {
  entity_id: string;
  entity_name: string;
  total_interactions: number;
  email_count: number;
  chat_count: number;
  meeting_count: number;
  top_tags: string[];
  integration_details: IntegrationDetail[];
};

export interface IntegrationDetail {
  id: number;
  type: string;
  type_generic: string;
}

export type PeopleOptionsType = {
  tags: { id: string; value: string }[];
};

const PEOPLE_QUERY_KEY = "topEntities";

const getTopEntities = async (limit: number, timePeriod: number, startDate?: string, endDate?: string) => {
  const { data, error } = await supabase.rpc("get_top_entities", {
    p_limit: limit,
    p_start_date: startDate,
    p_end_date: endDate,
    p_time_period: timePeriod,
  });

  if (error) throw error;
  return data as TopEntity[];
};

export default function People() {
  const { timePeriod, setTimePeriod } = useTimePeriod();
  const [dateRange, setDateRange] = useState<{ startDate?: string; endDate?: string }>({});
  const [selectedPerson, setSelectedPerson] = useState<TopEntity | null>(null);
  const [entities, setEntities] = useState<TopEntity[]>([]);

  const {
    data: fetchedEntities,
    isLoading,
    error,
  } = useQuery<TopEntity[]>({
    queryKey: [PEOPLE_QUERY_KEY, timePeriod, dateRange],
    queryFn: () => getTopEntities(100, parseInt(timePeriod.toString()), dateRange.startDate, dateRange.endDate),
  });

  useEffect(() => {
    if (fetchedEntities) {
      setEntities(fetchedEntities);
    }
  }, [fetchedEntities]);

  const options: PeopleOptionsType = {
    tags: Array.from(new Set(entities?.flatMap((e) => e.top_tags) || [])).map((tag) => ({ id: tag, value: tag })),
  };

  const handleTimePeriodChange = (value: string) => {
    setTimePeriod(value);
    setDateRange({});
  };

  const handlePersonSelect = (person: TopEntity) => {
    setSelectedPerson(person);
  };

  const handleNameChange = (entityId: string, newName: string) => {
    setEntities((prevEntities) =>
      prevEntities.map((entity) => (entity.entity_id === entityId ? { ...entity, entity_name: newName } : entity)),
    );
    if (selectedPerson && selectedPerson.entity_id === entityId) {
      setSelectedPerson({ ...selectedPerson, entity_name: newName });
    }
  };

  if (isLoading) return <ContentLayout title="People">Loading...</ContentLayout>;
  if (error) return <ContentLayout title="People">Error: {(error as Error).message}</ContentLayout>;

  return (
    <ContentLayout>
      {/* Header Section */}
      <div className="border-b">
        <div className="max-w-7xl mx-auto px-4 py-6">
          {/* Title and Stats */}
          <div className="mb-6">
            <div className="flex items-center gap-4">
              <div>
                <h1 className="text-3xl font-bold">People</h1>
                <div className="flex items-center gap-3 mt-2 text-muted-foreground text-sm">
                  <div className="flex items-center gap-1.5">
                    <Users className="h-4 w-4 text-primary" />
                    <span>{entities.length} Total People</span>
                  </div>
                  <div className="flex items-center gap-1.5">
                    <Clock className="h-4 w-4 text-primary" />
                    <span>
                      Last {timePeriod} {timePeriod === "1" ? "day" : "days"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow overflow-auto">
        <div className="max-w-7xl mx-auto px-4 py-6">
          <PeopleTable
            columns={columns}
            data={entities}
            options={options}
            timePeriod={timePeriod.toString()}
            onTimePeriodChange={handleTimePeriodChange}
            onPersonSelect={handlePersonSelect}
          />
        </div>
      </div>

      <DetailSidePanel
        isOpen={!!selectedPerson}
        onClose={() => setSelectedPerson(null)}
        title={selectedPerson?.entity_name || "Person Details"}
      >
        {selectedPerson && (
          <PersonDetailView
            person={selectedPerson}
            onClose={() => setSelectedPerson(null)}
            timePeriod={timePeriod.toString()}
            onNameChange={handleNameChange}
            key={`${selectedPerson.entity_id}-${timePeriod}`}
          />
        )}
      </DetailSidePanel>
    </ContentLayout>
  );
}
