import React, { useEffect, useState, useContext, useRef } from "react";
import { cn, Button, Popover, PopoverContent, PopoverTrigger } from "@attrove/ui-shadcn";
import { Volume2, VolumeX, Play, Pause, Volume1 } from "lucide-react";
import { AxiosClientContext } from "@attrove/attrove-ui/main";

interface AudioProps {
  reportId: number;
}

export const Audio: React.FC<AudioProps> = ({ reportId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isVolumeOpen, setIsVolumeOpen] = useState(false);

  const axiosClient = useContext(AxiosClientContext);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const volumeBarRef = useRef<HTMLDivElement>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const cleanupFnRef = useRef<(() => void) | null>(null);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      try {
        const time = audioRef.current.currentTime;
        const audioDuration = audioRef.current.duration;
        
        setCurrentTime(time);
        if (!isNaN(audioDuration)) {
          setProgress((time / audioDuration) * 100);
        }
      } catch (err) {
        console.error('Error updating time:', err);
      }
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleVolumeChange = () => {
    if (audioRef.current) {
      setVolume(audioRef.current.volume);
      setIsMuted(audioRef.current.muted);
    }
  };

  const handleProgressClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!audioRef.current || !progressBarRef.current) return;

    const rect = progressBarRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = (x / rect.width) * 100;
    const time = (percentage / 100) * audioRef.current.duration;

    audioRef.current.currentTime = time;
    setProgress(percentage);
  };

  const cleanupStream = () => {
    // Execute any stored cleanup function
    if (cleanupFnRef.current) {
      cleanupFnRef.current();
      cleanupFnRef.current = null;
    }

    // Abort any ongoing stream
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }

    // Clean up audio element
    if (audioRef.current?.src) {
      URL.revokeObjectURL(audioRef.current.src);
      audioRef.current.src = '';
    }
  };

  const fetchAudioStream = async () => {
    if (!audioRef.current) return;
    
    setIsLoading(true);
    setError(null);

    // Clean up any existing stream
    cleanupStream();
    
    // Create new abort controller
    abortControllerRef.current = new AbortController();

    try {
      const response = await axiosClient({
        method: 'post',
        url: '/stream-audio',
        data: { report_id: reportId },
        responseType: 'arraybuffer',
        headers: {
          Accept: 'audio/mpeg',
        },
        onDownloadProgress: (progressEvent) => {
          const percentage = Math.round((progressEvent.loaded * 100) / (progressEvent.total || progressEvent.loaded + 1));
          setProgress(percentage);
        },
        signal: abortControllerRef.current.signal,
      });

      // Create blob chunks and start playing as they arrive
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const url = URL.createObjectURL(audioBlob);
      
      if (audioRef.current) {
        audioRef.current.src = url;
        const playPromise = audioRef.current.play();
        
        if (playPromise) {
          playPromise.catch((e) => {
            console.error('Error playing audio:', e);
            if (e.name === 'NotAllowedError') {
              setError('Autoplay blocked. Please click play to start audio.');
            }
          });
        }
        
        setIsPlaying(true);
      }

      // Store cleanup function
      cleanupFnRef.current = () => {
        URL.revokeObjectURL(url);
      };

    } catch (err: any) {
      if (err.name === 'CanceledError') {
        return;
      }
      
      console.error('Error streaming audio:', err);
      setError('Failed to stream audio. Please try again.');
      setIsPlaying(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlayPause = async () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      if (!audioRef.current.src) {
        await fetchAudioStream();
      } else {
        const playPromise = audioRef.current.play();
        if (playPromise) {
          playPromise.catch((e) => {
            console.error('Error resuming audio:', e);
            setError('Failed to resume audio. Please try again.');
          });
        }
        setIsPlaying(true);
      }
    }
  };

  const getVolumeIcon = () => {
    if (isMuted || volume === 0) return <VolumeX className="h-5 w-5" />;
    if (volume < 0.5) return <Volume1 className="h-5 w-5" />;
    return <Volume2 className="h-5 w-5" />;
  };

  useEffect(() => {
    return () => {
      cleanupStream();
    };
  }, []);

  return (
    <div className="w-full border bg-background hover:bg-accent/5 rounded-md px-4 py-2 transition-all duration-200 group relative">
      <div className="absolute inset-x-0 bottom-0 h-[2px] bg-accent/20 rounded-full overflow-hidden">
        <div
          className="h-full bg-gradient-to-r from-accent to-accent/70 transition-all duration-200"
          style={{ width: `${progress}%` }}
        />
      </div>
      
      <div className="flex items-center gap-4">
        <Button
          size="icon"
          variant="ghost"
          className={cn(
            "h-8 w-8 flex-shrink-0 transition-all duration-200",
            isPlaying ? "text-accent hover:text-accent/80" : "hover:text-accent"
          )}
          onClick={handlePlayPause}
          disabled={isLoading}
        >
          {isPlaying ? (
            <Pause className="h-5 w-5 text-primary" />
          ) : (
            <Play className="h-5 w-5 text-primary" />
          )}
        </Button>

        <div className="flex items-center flex-1 gap-4">
          <span className="text-sm text-muted-foreground min-w-[48px] font-medium">
            {formatTime(currentTime)}
          </span>
          
          <div className="flex-1">
            <div 
              ref={progressBarRef}
              className="h-1.5 bg-primary/10 rounded-full cursor-pointer group/progress relative"
              onClick={handleProgressClick}
            >
              <div
                className="absolute inset-0 bg-gradient-to-r from-primary to-primary/70 rounded-full transition-all duration-200"
                style={{ width: `${progress}%` }}
              />
              <div 
                className="absolute top-1/2 -translate-y-1/2 h-3 w-3 rounded-full bg-primary shadow-sm opacity-0 group-hover/progress:opacity-100 transition-opacity duration-200"
                style={{ left: `${progress}%`, transform: 'translate(-50%, -50%)' }}
              />
            </div>
          </div>

          <span className="text-sm text-muted-foreground min-w-[48px] font-medium">
            {formatTime(duration)}
          </span>
        </div>

        <Popover open={isVolumeOpen} onOpenChange={setIsVolumeOpen}>
          <PopoverTrigger asChild>
            <div
              role="button"
              tabIndex={0}
              className={cn(
                "h-8 w-8 inline-flex items-center justify-center rounded-md transition-all duration-200 relative group/volume hover:bg-accent/10",
                isVolumeOpen ? "text-primary" : "text-muted-foreground hover:text-primary"
              )}
            >
              {getVolumeIcon()}
              <div className="absolute -top-8 left-1/2 -translate-x-1/2 bg-primary/90 text-primary-foreground text-xs px-2 py-1 rounded opacity-0 group-hover/volume:opacity-100 transition-opacity duration-200 pointer-events-none whitespace-nowrap">
                {Math.round(volume * 100)}%
              </div>
            </div>
          </PopoverTrigger>
          <PopoverContent
            align="end"
            alignOffset={-8}
            className="w-[200px] p-4"
            sideOffset={5}
          >
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">Volume</span>
                <span className="text-sm text-muted-foreground">{Math.round(volume * 100)}%</span>
              </div>
              <div
                ref={volumeBarRef}
                className="h-1.5 bg-primary/10 rounded-full cursor-pointer relative group/volume-slider"
                onClick={(e) => {
                  if (!volumeBarRef.current || !audioRef.current) return;
                  const rect = volumeBarRef.current.getBoundingClientRect();
                  const x = e.clientX - rect.left;
                  const percentage = Math.min(Math.max(x / rect.width, 0), 1);
                  setVolume(percentage);
                  audioRef.current.volume = percentage;
                  if (percentage === 0) {
                    setIsMuted(true);
                    audioRef.current.muted = true;
                  } else if (isMuted) {
                    setIsMuted(false);
                    audioRef.current.muted = false;
                  }
                }}
              >
                <div
                  className="absolute inset-0 bg-gradient-to-r from-primary to-primary/70 rounded-full transition-all duration-200"
                  style={{ width: `${volume * 100}%` }}
                />
                <div
                  className="absolute top-1/2 -translate-y-1/2 h-3 w-3 rounded-full bg-primary shadow-sm opacity-0 group-hover/volume-slider:opacity-100 transition-opacity duration-200"
                  style={{ left: `${volume * 100}%`, transform: 'translate(-50%, -50%)' }}
                />
              </div>
              <div className="flex items-center justify-between text-xs text-muted-foreground">
                <div
                  role="button"
                  tabIndex={0}
                  className="h-6 px-2 text-xs rounded-md hover:bg-accent/10 transition-colors cursor-pointer flex items-center justify-center"
                  onClick={() => {
                    if (!audioRef.current) return;
                    const newVolume = Math.max(0, volume - 0.1);
                    setVolume(newVolume);
                    audioRef.current.volume = newVolume;
                    if (newVolume === 0) {
                      setIsMuted(true);
                      audioRef.current.muted = true;
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      if (!audioRef.current) return;
                      const newVolume = Math.max(0, volume - 0.1);
                      setVolume(newVolume);
                      audioRef.current.volume = newVolume;
                      if (newVolume === 0) {
                        setIsMuted(true);
                        audioRef.current.muted = true;
                      }
                    }
                  }}
                >
                  -10%
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  className="h-6 px-2 text-xs rounded-md hover:bg-accent/10 transition-colors cursor-pointer flex items-center justify-center"
                  onClick={() => {
                    if (!audioRef.current) return;
                    const newVolume = Math.min(1, volume + 0.1);
                    setVolume(newVolume);
                    audioRef.current.volume = newVolume;
                    if (isMuted) {
                      setIsMuted(false);
                      audioRef.current.muted = false;
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      if (!audioRef.current) return;
                      const newVolume = Math.min(1, volume + 0.1);
                      setVolume(newVolume);
                      audioRef.current.volume = newVolume;
                      if (isMuted) {
                        setIsMuted(false);
                        audioRef.current.muted = false;
                      }
                    }
                  }}
                >
                  +10%
                </div>
              </div>
            </div>
          </PopoverContent>
        </Popover>

        <audio
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onVolumeChange={handleVolumeChange}
          onEnded={() => {
            setIsPlaying(false);
            cleanupStream();
          }}
          onPause={() => setIsPlaying(false)}
          onPlay={() => setIsPlaying(true)}
        />
      </div>

      {error && (
        <p className="text-destructive text-sm mt-2">{error}</p>
      )}
    </div>
  );
};

export default Audio;