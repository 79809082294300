// import { NewIntegrationCard } from '@/attrove/attrove-ui/components/integrations/new-integration-card';

import { GmailIntegration } from "@attrove/attrove-ui/components/integrations/gmail";
import { GoogleCalendarIntegration } from "@attrove/attrove-ui/components/integrations/google-calendar";
import { GoogleMeetIntegration } from "@attrove/attrove-ui/components/integrations/google-meet";
import { OutlookIntegration } from "@attrove/attrove-ui/components/integrations/outlook";
import { SlackIntegration } from "@attrove/attrove-ui/components/integrations/slack";

export default function NewIntegration() {
  return (
    <div className="p-4">
      <div className="flex justify-items-center mb-2">
        <h1 className="text-2xl">New Integration</h1>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
        <GmailIntegration />
        <SlackIntegration />
        <OutlookIntegration />
        <GoogleCalendarIntegration />
        <GoogleMeetIntegration />
      </div>
    </div>
  );
}
