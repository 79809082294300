import { Logo } from "@attrove/ui-shadcn";
import { Outlet } from "react-router-dom";

export const AuthLayout = () => {
  return (
    <div className="flex min-h-screen flex-col justify-center md:flex-row-reverse bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex items-start w-full px-6 mx-auto md:px-0 md:items-center md:w-1/3">
        <div className="w-full max-w-sm mx-auto md:mx-0 my-auto min-w-min relative md:-left-5">
          <div className="pt-8 pb-6">
            <Logo iconClass="h-12 w-12" textClass="text-4xl" />
            <p className="mt-4 text-sm text-muted-foreground">
              Welcome to Attrove's closed beta. Sign in to continue.
            </p>
          </div>
        </div>
      </div>
      <div className="justify-center px-6 flex items-center md:w-2/3 md:border-r border-border/40">
        <div className="w-full max-w-sm py-8 mx-auto my-auto min-w-min">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
