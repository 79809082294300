import { type AttroveSupabaseClient, conversationsInsertSchema, conversationsRowSchema, DB } from "@attrove/service-supabase";
import { logError, logWarn, logInfo } from "@attrove/util-logs";
import { Logtail } from "@logtail/node";
import { z } from "zod";

export type ConversationsInsert = Omit<z.infer<typeof conversationsInsertSchema>, "id">;
export type Conversation = z.infer<typeof conversationsRowSchema>;

export const enumerateAndInsertConversations = async (
    supabaseClient: AttroveSupabaseClient,
    conversationsData: ConversationsInsert[],
    logtail?: Logtail
  ): Promise<Conversation[] | null> => {
    try {
      const validConversationsData = conversationsData.map(conv => ({
        conversation_id_3p: conv.conversation_id_3p,
        integration_id: conv.integration_id,
        title: conv.title,
        conversation_type: conv.conversation_type,
        active: conv.active ?? true,
        import_messages: conv.import_messages ?? false,
        created_at: conv.created_at ?? new Date().toISOString(),
        last_checked: conv.last_checked ?? new Date().toISOString(),
      }));

      let { data, error } = await supabaseClient
        .from(DB.CONVERSATIONS)
        .upsert(validConversationsData, {
          onConflict: 'conversation_id_3p,integration_id',
          ignoreDuplicates: false
        })
        .select();

      let errorResult = null;
      if (error) {
        // If the error is due to missing unique constraint, fall back to individual inserts
        if (error.code === '42P10') {
          logWarn("[enumerateAndInsertConversations] Unique constraint not found, falling back to individual inserts", {}, logtail);

          const insertPromises = validConversationsData.map(conv =>
            supabaseClient
              .from(DB.CONVERSATIONS)
              .upsert(conv, { onConflict: 'conversation_id_3p,integration_id' })
              .select()
          );

          const results = await Promise.all(insertPromises);
          data = results.flatMap(result => result.data || []);
          errorResult = results.find(result => result.error)?.error;
        }

        if (errorResult) {
          throw errorResult;
        }
      }
  
      logInfo("[enumerateAndInsertConversations] Conversations inserted/updated", 
        { count: data?.length }, 
        logtail
      );
  
      return data || null;
    } catch (error) {
      logError("[enumerateAndInsertConversations] Error inserting conversations", 
        { error }, 
        logtail
      );
      return null;
    }
  };

export const getConversations = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  logtail?: Logtail
): Promise<Conversation[] | null> => {
  try {
    const { data, error } = await supabaseClient
      .from(DB.CONVERSATIONS)
      .select('*')
      .eq('integration_id', integrationId);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    logError("[getConversations] Error fetching conversations", 
      { error, integrationId }, 
      logtail
    );
    return null;
  }
};

export const updateConversationPreferences = async (
    supabaseClient: AttroveSupabaseClient,
    conversationPreferences: Array<{ id: number, import_messages: boolean }>,
    logtail?: Logtail
  ): Promise<void> => {
    try {
      for (const pref of conversationPreferences) {
        const { error } = await supabaseClient
          .from(DB.CONVERSATIONS)
          .update({ import_messages: pref.import_messages })
          .eq('id', pref.id);
  
        if (error) {
          throw error;
        }
      }
  
      logInfo("[updateConversationPreferences] Preferences updated", 
        { count: conversationPreferences.length }, 
        logtail
      );
    } catch (error) {
      logError("[updateConversationPreferences] Error updating preferences", 
        { error }, 
        logtail
      );
      throw error;
    }
  };

export const getConversationsToSync = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  logtail?: Logtail
): Promise<Array<{ id: string, name: string, type: string, import_messages: boolean }> | null> => {
  try {
    const { data, error } = await supabaseClient
      .from(DB.CONVERSATIONS)
      .select('conversation_id_3p, title, conversation_type, import_messages')
      .eq('integration_id', integrationId)
      .eq('import_messages', true)
      .eq('active', true);

    if (error) {
      throw error;
    }

    return data.map(conv => ({
      id: conv.conversation_id_3p,
      name: conv.title,
      type: conv.conversation_type,
      import_messages: conv.import_messages
    }));
  } catch (error) {
    logError("[getConversationsToSync] Error fetching conversations to sync", 
      { error, integrationId }, 
      logtail
    );
    return null;
  }
};

export const updateLastSyncConversation = async (
  supabaseClient: AttroveSupabaseClient,
  conversationId: number,
  logtail?: Logtail
): Promise<string | null> => {
  try {
    const { data, error } = await supabaseClient
      .from(DB.CONVERSATIONS)
      .update({ last_checked: new Date().toISOString() })
      .eq('id', conversationId)
      .select()
      .single();

    if (error) {
      throw error;
    }

    return data.last_checked;
  } catch (error) {
    logError("[updateLastSyncConversation] Error updating last checked time", 
      { error, conversationId }, 
      logtail
    );
    return null;
  }
};