import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { format } from "date-fns";

// Converts dates stored as normalized UTC to the client-side date which is in
// the local timezone.
export function convertUTCDateToLocalDate(date: Date) {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();
  newDate.setHours(hours - offset);
  return newDate;
}

export const getRelativeTime = (date: string): { hours: number; minutes: number; past: string } => {
  let past = '';

  const endAtDate = new Date(date);
  const now = new Date();
  const diff = endAtDate.getTime() - now.getTime();
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff / (1000 * 60)) % 60);

  if (hours < 0) {
    dayjs.extend(relativeTime);
    const auctionEndAt = dayjs(date);
    past = auctionEndAt.from(dayjs());
  }

  return { hours, minutes, past };
};

export function formatStripeDate(dateString: string | null | undefined): string {
  if (!dateString) return 'Not available';
  
  try {
    // Parse the ISO 8601 string to Date
    const utcDate = new Date(dateString);
    // Convert to local timezone
    const localDate = convertUTCDateToLocalDate(utcDate);
    // Format the date
    return format(localDate, "MMMM d, yyyy");
  } catch (error) {
    console.error('Invalid date formatting:', error);
    return 'Invalid date';
  }
}