import React, { useMemo, useCallback } from 'react';
import { OptionsType } from "@attrove/attrove-ui/app/pages/dashboard/messages";
import { Badge } from "@attrove/ui-shadcn";
import { X } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { Icons } from "@attrove/ui-shadcn";

const Gmail = Icons.gmail;
const Outlook = Icons.outlook;
const Slack = Icons.slack;

const getIntegrationIcon = (type: string) => {
  switch (type.toLowerCase()) {
    case 'outlook':
      return Outlook;
    case 'gmail':
      return Gmail;
    case 'slack':
      return Slack;
    default:
      return Gmail;
  }
};

export const RenderTags: React.FC<{ options: OptionsType; urlParam: string; title: string }> = ({ options, urlParam, title }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = searchParams.get(urlParam)?.split(",").filter(Boolean) || [];

  // Get the specific options based on the URL parameter
  const specificOptions = useMemo(() => {
    if (!options) return [];
    
    let result = [];
    switch (urlParam) {
      case 'integrations':
        result = options.integrationOptions || [];
        break;
      case 'reports':
        result = options.reportOptions || [];
        break;
      case 'tags':
        result = options.tagOptions || [];
        break;
      default:
        result = [];
    }
    
    return result;
  }, [options, urlParam]);

  const handleFilterRemove = useCallback(
    (filterToRemove: string) => {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        const currentFilters = newParams.get(urlParam)?.split(",").filter(Boolean) || [];
        const updatedFilters = currentFilters.filter((f) => f !== filterToRemove);
        
        if (updatedFilters.length === 0) {
          newParams.delete(urlParam);
        } else {
          newParams.set(urlParam, updatedFilters.join(","));
        }
        
        return newParams;
      });
    },
    [setSearchParams, urlParam]
  );

  if (filters.length === 0) return null;

  return (
    <div className="flex flex-wrap gap-2">
      {filters.map((filter) => {
        let displayValue;
        let displayColon = ': ';
        let IntegrationIcon;

        // Entity titles are handled above this component for now
        if (urlParam === 'entity') {
          displayValue = ``;
          displayColon = ``;
        } else {
          // For integrations, we need to find the option by ID
          const option = urlParam === 'integrations' || urlParam === 'reports'
            ? specificOptions.find((option) => String(option.id) === String(filter))
            : specificOptions.find((option) => option.value === filter);

          // Default to filter value
          displayValue = filter;

          // If we found a matching option, use its value
          if (option) {
            displayValue = option.value;
            
            // Special handling for integrations, tags, and reports
            if (urlParam === 'integrations' || urlParam === 'tags' || urlParam === 'reports') {
              if (urlParam === 'integrations') {
                IntegrationIcon = getIntegrationIcon(option.value);
                displayValue = option.value.charAt(0).toUpperCase() + option.value.slice(1).toLowerCase();
              }
              displayColon = '';  // Don't show colon for integrations, tags, and reports
            }
          }
        }

        return (
          <Badge
            key={filter}
            variant="secondary"
            className="rounded-sm px-2 font-normal flex gap-2 items-center hover:cursor-pointer group"
          >
            {IntegrationIcon ? (
              <>
                <IntegrationIcon className="h-4 w-4" />
                <p className="text-sm">{displayValue}</p>
                <span className="sr-only">{title}</span>
              </>
            ) : (
              <p>{urlParam === 'tags' || urlParam === 'reports' ? displayValue : title + displayColon + displayValue}</p>
            )}
            <X 
              className="h-4 w-4 opacity-50 group-hover:opacity-100 transition-opacity" 
              onClick={(e) => {
                e.stopPropagation();
                handleFilterRemove(filter);
              }}
            />
          </Badge>
        );
      })}
    </div>
  );
};