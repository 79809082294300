// notifications.ts
import { z } from "zod";
import { Logtail } from "@logtail/node";
import { logError, logInfo } from "@attrove/util-logs";
import {
  AttroveSupabaseClient,
  DB,
  Json, // Add this import
  notificationsRowSchema,
  notificationChannelsRowSchema,
  notificationDeliveriesRowSchema,
  notificationTypesRowSchema,
  userNotificationPreferencesRowSchema,
} from "@attrove/service-supabase";

// Type definitions
export type NotificationType = z.infer<typeof notificationTypesRowSchema>;
export type NotificationChannel = z.infer<typeof notificationChannelsRowSchema>;
export type UserNotificationPreference = z.infer<typeof userNotificationPreferencesRowSchema>;
export type Notification = z.infer<typeof notificationsRowSchema>;
export type NotificationDelivery = z.infer<typeof notificationDeliveriesRowSchema>;

export interface NotificationWithDeliveries extends Notification {
  deliveries: NotificationDelivery[];  // Note: not nullable
}

export type NotificationDeliveryWithNotification = NotificationDelivery & {
  notification: Notification;
};

export interface NotificationCreateInput {
  user_id: string;
  notification_type_id: string;
  title: string;
  content: string;
  metadata?: Json;
  scheduled_for?: string;
  expires_at?: string;
  is_read?: boolean;
}

export interface UserNotificationPreferenceCreateInput {
  user_id: string;
  notification_type_id: string;
  notification_channel_id: string;
  integration_id?: number;
  is_enabled?: boolean;
}

// Query keys for caching
export const GET_USER_NOTIFICATIONS_KEY = "GET_USER_NOTIFICATIONS";
export const GET_USER_NOTIFICATION_PREFERENCES_KEY = "GET_USER_NOTIFICATION_PREFERENCES";

export const getUserNotifications = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  options?: {
    limit?: number;
    unreadOnly?: boolean;
    includeDeliveries?: boolean;
  },
): Promise<Notification[]> => {
  const select = options?.includeDeliveries ? `*, ${DB.NOTIFICATION_DELIVERIES} (*)` : "*";

  const query = supabaseClient.from(DB.NOTIFICATIONS).select(select).eq("user_id", userId).order("created_at", { ascending: false });

  if (options?.unreadOnly) {
    query.eq("is_read", false);
  }

  if (options?.limit) {
    query.limit(options.limit);
  }

  const { data, error } = await query;

  if (error) {
    throw error;
  }

  // Explicit type cast to handle the Supabase response type
  return (data || []) as unknown as Notification[];
};

export interface NotificationPreference {
  user_id: string;
  notification_channel_id: string;
  notification_type_id: string;
  is_enabled: boolean;
  integration_id?: number | null;
}

// Get user's notification preferences
export const getUserNotificationPreferences = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string
): Promise<Record<string, Record<string, boolean>>> => {
  const { data, error } = await supabaseClient
    .from('user_notification_preferences')
    .select('*')
    .eq('user_id', userId);

  if (error) {
    throw error;
  }

  // Transform into a more usable format:
  // { [typeId]: { email: boolean, in_app: boolean } }
  return data.reduce((acc, pref) => {
    if (!acc[pref.notification_type_id]) {
      acc[pref.notification_type_id] = {};
    }
    acc[pref.notification_type_id][pref.notification_channel_id] = pref.is_enabled;
    return acc;
  }, {} as Record<string, Record<string, boolean>>);
};

// Update a notification preference
export const updateNotificationPreference = async (
  supabaseClient: AttroveSupabaseClient,
  preference: NotificationPreference
): Promise<void> => {
  const { error } = await supabaseClient
    .from('user_notification_preferences')
    .upsert({
      user_id: preference.user_id,
      notification_channel_id: preference.notification_channel_id,
      notification_type_id: preference.notification_type_id,
      is_enabled: preference.is_enabled,
      integration_id: preference.integration_id,
      updated_at: new Date().toISOString()
    }, {
      onConflict: 'user_id,notification_type_id,notification_channel_id'
    });

  if (error) {
    throw error;
  }
};

export async function createNotificationWithDeliveries(
  supabase: AttroveSupabaseClient,
  notification: NotificationCreateInput,
  channels: string[],
  logtail: Logtail
): Promise<NotificationWithDeliveries | null> {
  try {
    // Create notification
    const { data: createdNotification, error } = await supabase
      .from('notifications')
      .insert(notification)
      .select<'notifications', Notification>()
      .single();

    if (error || !createdNotification) {
      logError(
        'Failed to create notification',
        { error, notification },
        logtail
      );
      return null;
    }

    // Create delivery records
    const deliveryPromises = channels.map(channelId =>
      supabase
        .from('notification_deliveries')
        .insert({
          notification_id: createdNotification.id,
          notification_channel_id: channelId,
          status: 'pending',
          attempt_count: 0,
        })
        .select<'notification_deliveries', NotificationDelivery>()
    );

    const deliveryResults = await Promise.all(deliveryPromises);
    const deliveries = deliveryResults
      .map(result => result.data?.[0])
      .filter((delivery): delivery is NotificationDelivery => delivery !== null);

    if (deliveries.length === 0) {
      logError(
        'Failed to create any delivery records',
        { notificationId: createdNotification.id },
        logtail
      );
      return null;
    }

    return {
      ...createdNotification,
      deliveries
    };
  } catch (error) {
    logError(
      'Error in createNotificationWithDeliveries',
      { error, notification },
      logtail
    );
    return null;
  }
}

export const markNotificationAsRead = async (
  supabaseClient: AttroveSupabaseClient,
  notificationId: string,
  userId: string,
  logtail?: Logtail,
): Promise<boolean> => {
  const { error } = await supabaseClient.from(DB.NOTIFICATIONS).update({ is_read: true }).eq("id", notificationId).eq("user_id", userId);

  if (error) {
    logError("Error marking notification as read", { error, notificationId, userId }, logtail);
    return false;
  }

  return true;
};

export const updateNotificationDeliveryStatus = async (
  supabaseClient: AttroveSupabaseClient,
  deliveryId: string,
  status: NotificationDelivery["status"],
  errorMessage?: string,
  logtail?: Logtail,
): Promise<boolean> => {
  try {
    // First, increment the attempt count
    const { data: newCount, error: rpcError } = await supabaseClient.rpc("increment_delivery_attempts", { delivery_id: deliveryId });

    if (rpcError) {
      throw rpcError;
    }

    const updateData: Partial<NotificationDelivery> = {
      status,
      last_attempt_at: new Date().toISOString(),
      attempt_count: newCount as number,
    };

    if (status === "delivered") {
      updateData.delivered_at = new Date().toISOString();
    } else if (status === "failed" && errorMessage) {
      updateData.error_message = errorMessage;
    }

    const { error } = await supabaseClient.from(DB.NOTIFICATION_DELIVERIES).update(updateData).eq("id", deliveryId);

    if (error) {
      throw error;
    }

    return true;
  } catch (error) {
    logError("Error updating notification delivery status", { error, deliveryId, status }, logtail);
    return false;
  }
};

export const setUserNotificationPreferences = async (
  supabaseClient: AttroveSupabaseClient,
  preferencesInput: UserNotificationPreferenceCreateInput[],
  logtail?: Logtail,
): Promise<boolean> => {
  try {
    // Ensure all required fields are present and set defaults
    const preferences = preferencesInput.map((pref) => ({
      user_id: pref.user_id,
      notification_type_id: pref.notification_type_id,
      notification_channel_id: pref.notification_channel_id,
      integration_id: pref.integration_id,
      is_enabled: pref.is_enabled ?? false,
    }));

    const { error } = await supabaseClient.from(DB.USER_NOTIFICATION_PREFERENCES).upsert(preferences, {
      onConflict: "user_id,notification_type_id,notification_channel_id",
    });

    if (error) throw error;
    return true;
  } catch (error) {
    logError("Error setting user notification preferences", { error, preferences: preferencesInput }, logtail);
    return false;
  }
};

export const getUndeliveredNotifications = async (
  supabaseClient: AttroveSupabaseClient,
  limit: number,
): Promise<NotificationDeliveryWithNotification[]> => {
  const { data, error } = await supabaseClient
    .from(DB.NOTIFICATION_DELIVERIES)
    .select(
      `
        *,
        notification:${DB.NOTIFICATIONS}!inner(*)
      `,
    )
    .eq("status", "pending")
    .order("created_at", { ascending: true })
    .limit(limit);

  if (error) {
    throw error;
  }

  // The !inner join ensures notification is never null
  return data as NotificationDeliveryWithNotification[];
};

// Add type guard functions to help with type safety
export const hasDeliveries = (notification: Notification | NotificationWithDeliveries): notification is NotificationWithDeliveries => {
  return "deliveries" in notification && notification.deliveries !== null;
};

export const isNotificationDeliveryWithNotification = (
  delivery: NotificationDelivery | NotificationDeliveryWithNotification,
): delivery is NotificationDeliveryWithNotification => {
  return "notification" in delivery && delivery.notification !== null;
};

export const getNotificationTypeByCode = async (
  supabaseClient: AttroveSupabaseClient,
  code: string,
  logtail?: Logtail,
): Promise<NotificationType | null> => {
  try {
    const { data, error } = await supabaseClient.from(DB.NOTIFICATION_TYPES).select("*").eq("code", code).single();

    if (error) {
      logError("Error fetching notification type", { error, code }, logtail);
      return null;
    }

    return data;
  } catch (error) {
    logError("Error in getNotificationTypeByCode", { error, code }, logtail);
    return null;
  }
};

export interface NotificationWithUser extends Notification {
  users: {
    id: string;
    full_name: string | null;
    auth_user: {
      email: string;
    };
  };
}

export const getNotificationWithUserDetails = async (
  supabaseClient: AttroveSupabaseClient,
  notificationId: string,
  logtail?: Logtail
): Promise<NotificationWithUser | null> => {
  try {

    const { data, error } = await supabaseClient
      .from(DB.NOTIFICATIONS)
      .select(`
        *,
        user_profiles!inner (
          id,
          full_name,
          email
        )
      `)
      .eq('id', notificationId)
      .single();

    if (error) {
      logError('Error fetching notification', { error, notificationId }, logtail);
      throw error;
    }

    if (!data || !data.user_profiles?.id || !data.user_profiles?.email) {
      logError('Missing required user profile data', { data, notificationId }, logtail);
      return null;
    }

    // Transform the data to match our interface
    const transformedData: NotificationWithUser = {
      ...data,
      users: {
        id: data.user_profiles.id,
        full_name: data.user_profiles.full_name,
        auth_user: {
          email: data.user_profiles.email
        }
      }
    };

    return transformedData;
  } catch (error) {
    logError(
      'Error fetching notification with user details',
      { error, notificationId },
      logtail
    );
    return null;
  }
};

export const getUserEmailPreference = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  notificationTypeId: string,
  logtail?: Logtail
): Promise<UserNotificationPreference | null> => {
  try {
    const { data, error } = await supabaseClient
      .from(DB.USER_NOTIFICATION_PREFERENCES)
      .select('*')
      .eq('user_id', userId)
      .eq('notification_channel_id', 'email')
      .eq('notification_type_id', notificationTypeId)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    logError(
      'Error fetching user email preference',
      { error, userId, notificationTypeId },
      logtail
    );
    return null;
  }
};

export const getNotificationChannelByCode = async (
  supabaseClient: AttroveSupabaseClient,
  code: string,
  logtail?: Logtail,
): Promise<NotificationChannel | null> => {
  try {
    const { data, error } = await supabaseClient
      .from(DB.NOTIFICATION_CHANNELS)
      .select('*')
      .eq('code', code)
      .single();

    if (error) {
      logError("Error fetching notification channel", { error, code }, logtail);
      return null;
    }

    return data;
  } catch (error) {
    logError("Error in getNotificationChannelByCode", { error, code }, logtail);
    return null;
  }
};

export const getNotificationTypes = async (
  supabaseClient: AttroveSupabaseClient,
  logtail?: Logtail
): Promise<NotificationType[]> => {
  try {
    const { data, error } = await supabaseClient
      .from(DB.NOTIFICATION_TYPES)
      .select('*')
      .order('name');

    if (error) {
      logError('Error fetching notification types', { error }, logtail);
      throw error;
    }

    return data;
  } catch (error) {
    logError('Error in getNotificationTypes', { error }, logtail);
    return [];
  }
};

export const getActiveNotificationChannels = async (
  supabaseClient: AttroveSupabaseClient,
  logtail?: Logtail
): Promise<NotificationChannel[]> => {
  try {
    const { data, error } = await supabaseClient
      .from(DB.NOTIFICATION_CHANNELS)
      .select('*')
      .eq('is_active', true)
      .order('name');

    if (error) {
      logError('Error fetching notification channels', { error }, logtail);
      throw error;
    }

    return data;
  } catch (error) {
    logError('Error in getActiveNotificationChannels', { error }, logtail);
    return [];
  }
};

// Default notification preferences configuration
export const DEFAULT_NOTIFICATION_PREFERENCES = {
  urgent_action: { email: true, in_app: true },
  meeting_insight: { email: true, in_app: true },
  insight: { email: true, in_app: true },
  report_ready: { email: true, in_app: true },
  daily_digest: { email: true, in_app: true }
};

export const createDefaultNotificationPreferences = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  logtail?: Logtail
): Promise<boolean> => {
  try {
    // Get all notification types and channels
    const [{ data: types }, { data: channels }] = await Promise.all([
      supabaseClient.from(DB.NOTIFICATION_TYPES).select('*'),
      supabaseClient.from(DB.NOTIFICATION_CHANNELS).select('*').eq('is_active', true)
    ]);

    if (!types || !channels) {
      throw new Error('Failed to fetch notification types or channels');
    }

    // Create preferences array based on defaults
    const preferences: UserNotificationPreferenceCreateInput[] = [];
    
    for (const type of types) {
      const defaultSettings = DEFAULT_NOTIFICATION_PREFERENCES[type.code as keyof typeof DEFAULT_NOTIFICATION_PREFERENCES];
      if (defaultSettings) {
        for (const channel of channels) {
          const isEnabled = defaultSettings[channel.code as keyof typeof defaultSettings] ?? false;
          preferences.push({
            user_id: userId,
            notification_type_id: type.id,
            notification_channel_id: channel.id,
            is_enabled: isEnabled
          });
        }
      }
    }

    // Insert all preferences in a single transaction
    const { error } = await supabaseClient
      .from(DB.USER_NOTIFICATION_PREFERENCES)
      .insert(preferences);

    if (error) {
      logError(
        'Error creating default notification preferences',
        { error, userId },
        logtail
      );
      return false;
    }

    logInfo(
      'Successfully created default notification preferences',
      { userId, preferencesCount: preferences.length },
      logtail
    );
    return true;
  } catch (error) {
    logError(
      'Unexpected error creating default notification preferences',
      { error, userId },
      logtail
    );
    return false;
  }
};
