import { LoadingButton } from "@attrove/ui-shadcn";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from "@attrove/ui-shadcn";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

const forgotPasswordSchema = z.object({
  email: z.string().email({
    message: "Invalid email address",
  }),
});

const FORGOT_PASSWORD_DEFAULT_VALUES = {
  email: "",
};

export default function ForgotPassword() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: FORGOT_PASSWORD_DEFAULT_VALUES,
  });

  const handleSubmit = async (data: { email: string }) => {
    setIsSubmitting(true);
    // const redirectPath = await requestPasswordUpdate(data);
    // router.push(redirectPath);
    setIsSubmitting(false);
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <h1 className="text-2xl font-semibold tracking-tight">Reset password</h1>
        <p className="text-sm text-muted-foreground">
          Enter your email address and we'll send you a link to reset your password.
        </p>
      </div>

      <Form {...form}>
        <form noValidate={true} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input 
                    autoCapitalize="none" 
                    autoComplete="email" 
                    autoCorrect="off" 
                    placeholder="name@company.com" 
                    {...field} 
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <LoadingButton 
            type="submit" 
            className="w-full" 
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Send reset link
          </LoadingButton>
        </form>
      </Form>

      <div className="text-center">
        <Link 
          to="/password_signin" 
          className="text-sm text-muted-foreground hover:text-primary"
        >
          Back to sign in
        </Link>
      </div>
    </div>
  );
}
