import { Card, Icons } from "@attrove/ui-shadcn";
import { truncate } from "lodash";
import { Loader2, ArrowRight } from "lucide-react";

export const NewIntegrationCard: React.FC<{
  provider: string;
  name: string;
  loading?: boolean;
  isCallback?: boolean;  // New prop to handle post-OAuth state
  onClick: () => void;
}> = ({ provider, name, loading, isCallback, onClick }) => {
  // We're in a loading state if either loading prop is true OR we're in callback
  const isLoading = loading || isCallback;
  const iconKey = provider.toLowerCase() as keyof typeof Icons;
  const Icon = Icons[iconKey];

  if (!Icon) {
    console.warn(`No icon found for provider: ${provider}`);
    return null;
  }

  return (
    <Card 
      className={`grow relative hover:cursor-pointer hover:bg-accent flex items-center ${
        isLoading ? 'pointer-events-none' : ''
      }`} 
      onClick={onClick}
    >
      <div className="flex items-center justify-center w-16 p-3 h-full rounded-l-lg bg-border">
        <Icon className="w-10 h-10" />
      </div>
      
      <div className="flex justify-between items-center w-full pr-4">
        <div className="p-2 pr-4 flex flex-col">
          <div className="flex items-center gap-2">
            <p className="text-xl">{truncate(name, { length: 22 })}</p>
          </div>
          {isLoading && (
            <p className="text-sm text-muted-foreground">
              {isCallback ? 'Setting up and syncing data...' : 'Connecting...'}
            </p>
          )}
        </div>
        
        {isLoading ? (
          <div className="flex items-center gap-2">
            <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
          </div>
        ) : (
          <ArrowRight className="h-5 w-5 opacity-50 group-hover:opacity-100 transition-opacity" />
        )}
      </div>
    </Card>
  );
};