import { useState } from "react";
import { voicesConfig } from "@attrove/attrove-ui/constants/voices-config";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import {
  Card,
  CardContent,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@attrove/ui-shadcn";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { updateUser } from "@attrove/service-supabase";
import { useMutation } from "@tanstack/react-query";
import { VoicePreview } from "./voice-preview";

const capitalize = (str: string): string =>
  str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const VoiceSelect = () => {
  const { user } = useSession();
  // const [selectedVoice, setSelectedVoice] = useState(user.voice_id);
  const [selectedVoice, setSelectedVoice] = useState(user.voice_id);

  const selectedVoiceName = voicesConfig.find((v) => v.voice_id === selectedVoice)?.name || "Select a voice";

  const previewUrl = voicesConfig.find((v) => v.voice_id === selectedVoice)?.preview_url || "";

  const updatePreferredName = useMutation({
    mutationFn: async (newVoiceId: string) => {
      return await updateUser(supabase, user.id, { voice_id: newVoiceId });
    },
  });

  return (
    <Card className="max-w-2xl">
      <CardContent className="p-6">
        <div className="space-y-4">
          <div className="space-y-2">
            <h2 className="text-lg font-semibold">Voice Preferences</h2>
          </div>

          <div className="flex gap-4 flex-col">
            <Select
              defaultValue={selectedVoice}
              onValueChange={(value) => {
                updatePreferredName.mutate(value);
                setSelectedVoice(value);
              }}
            >
              <SelectTrigger className="w-full">
                <SelectValue>{selectedVoiceName}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Voices</SelectLabel>
                  {voicesConfig.map((voice) => (
                    <SelectItem key={voice.voice_id} value={voice.voice_id}>
                      <div className="flex flex-col">
                        <span>{voice.name}</span>
                        <span className="text-xs text-gray-500">
                          {`${capitalize(voice.labels.description)} ${capitalize(voice.labels.accent)} ${capitalize(voice.labels.age)} ${capitalize(voice.labels.gender)}`}
                        </span>
                      </div>
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <VoicePreview audioUrl={previewUrl} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
