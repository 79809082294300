import {
  GET_INTEGRATIONS_WITH_CALS_BY_ID_QUERY_KEY,
  GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY,
  Integration,
  INTEGRATIONS_QUERY_KEY,
  toggleActive,
  updateIntegration,
} from "@attrove/service-supabase";
import { Button } from "@attrove/ui-shadcn";
import { useMutation } from "@tanstack/react-query";
import { supabase } from "../../app/supabase";
import { DeleteIntegrationButton } from "./delete-integration-button";
import { queryClient } from "@attrove/attrove-ui/app/react-query";

export const IntegrationActions: React.FC<{ integration: Integration }> = ({ integration }) => {
  const mutation = useMutation({
    mutationFn: async (integration: Integration) => {
      const { id, ...rest } = integration;

      return await toggleActive(supabase, id, integration.is_active);
    },
    onSuccess: () => {
      console.log("success");
      queryClient.invalidateQueries({
        queryKey: [INTEGRATIONS_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [GET_INTEGRATIONS_WITH_CALS_BY_ID_QUERY_KEY],
      });
    },
  });
  return (
    <div className="flex gap-2">
      <Button
        variant="outline"
        size="sm"
        onClick={(e) => {
          e.preventDefault();
          mutation.mutate({ ...integration, is_active: !integration.is_active });
        }}
        disabled={mutation.isPending}
      >
        {integration.is_active ? (mutation.isPending ? "Pausing..." : "Pause") : mutation.isPending ? "Resuming..." : "Resume"}
      </Button>
      <DeleteIntegrationButton integrationId={integration.id} />
    </div>
  );
};
