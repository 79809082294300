import { supabase } from "@attrove/attrove-ui/app/supabase";
import { ContentLayout } from "@attrove/attrove-ui/components/layouts/content-layout";
import { createColumns } from "@attrove/attrove-ui/features/messages/columns";
import { ThreadedMessagesComponent } from "@attrove/attrove-ui/features/messages/threaded-message";
import { Table, TableRow, TableBody, TableCell } from "@attrove/ui-shadcn";
import type { Database } from "@attrove/service-supabase";
import {
  ALL_MESSAGES_QUERY_KEY,
  getAllMessagesByUser,
  getMessagesByUser,
  MESSAGES_QUERY_KEY,
  EnhancedMessage,
  FilterOption,
  FilterOptions,
} from "@attrove/service-supabase";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { queryClient } from "../../react-query";

export type Message = Database["public"]["Tables"]["messages"]["Row"];

export type OptionsType = {
  integrations: FilterOption[];
  reports: FilterOption[];
  tags: FilterOption[];
};

export default function Messages() {
  const getMessageIds = (searchParams: URLSearchParams): number[] => {
    const messageIds = new Set<number>();

    // Check both 'message_ids' and 'message' parameters
    ["message_ids", "message"].forEach((param) => {
      // Try multiple parameters format
      let ids = searchParams.getAll(param);

      // If only one parameter with commas, split it
      if (ids.length === 1 && ids[0].includes(",")) {
        ids = ids[0].split(",");
      }

      // Convert to numbers and validate
      ids
        .filter(Boolean)
        .map((id) => parseInt(id.trim(), 10))
        .filter((id) => !isNaN(id))
        .forEach((id) => messageIds.add(id));
    });

    return Array.from(messageIds);
  };

  const [searchParams] = useSearchParams();
  const [expandedThreads, setExpandedThreads] = useState<Set<number>>(new Set());

  const reports = searchParams.getAll("reports").filter(Boolean);
  const integrations = searchParams.getAll("integrations").filter(Boolean);
  const tags = searchParams.getAll("tags").filter(Boolean);
  const message_ids = getMessageIds(searchParams);
  const event = searchParams.get("event");
  const entity = searchParams.get("entity");

  const queryParams = {
    reports: reports.length > 0 ? reports : undefined,
    integrations: integrations.length > 0 ? integrations : undefined,
    tags: tags.length > 0 ? tags : undefined,
    message_ids: message_ids.length > 0 ? message_ids.map(String) : undefined,
    event: event || undefined,
    entity: entity || undefined,
  };

  const { data: messages, isLoading } = useQuery<EnhancedMessage[]>({
    queryKey: [MESSAGES_QUERY_KEY, queryParams],
    queryFn: () => getMessagesByUser(supabase, queryParams, 1000),
    enabled: !!supabase,
  });

  const { data: enhancedFilterOptions, isLoading: isLoadingFilterOptions } = useQuery({
    queryKey: [ALL_MESSAGES_QUERY_KEY],
    queryFn: () => getAllMessagesByUser(supabase),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const options: OptionsType = useMemo(() => {
    return {
      integrations: enhancedFilterOptions?.integrationOptions || [],
      reports: enhancedFilterOptions?.reportOptions || [],
      tags: enhancedFilterOptions?.tagOptions || [],
    };
  }, [enhancedFilterOptions]);

  const filterOptions: FilterOptions = useMemo(() => {
    return {
      reports: searchParams.getAll("reports"),
      integrations: searchParams.getAll("integrations"),
      tags: searchParams.getAll("tags"),
      message_ids: message_ids.length > 0 ? message_ids.map(String) : undefined,
      event: searchParams.get("event") || undefined,
      entity: searchParams.get("entity") || undefined,
    };
  }, [searchParams, message_ids]);

  useEffect(() => {
    console.log("Invalidating messages query");
    queryClient.invalidateQueries({ queryKey: [MESSAGES_QUERY_KEY] });
  }, [searchParams]);

  const entityData = useMemo(() => {
    if (!messages) return {};
    return messages.reduce(
      (acc, message) => {
        acc[message.sender_entity_id] = {
          name: message.sender_name,
          entity_type: message.sender_entity_type,
        };
        return acc;
      },
      {} as { [key: string]: { name: string; entity_type: string } },
    );
  }, [messages]);

  const toggleThread = useCallback((threadId: string) => {
    setExpandedThreads((prev) => {
      const newSet = new Set(prev);
      const id = parseInt(threadId, 10);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  }, []);

  const columns = useMemo(() => createColumns(toggleThread, (message: EnhancedMessage) => expandedThreads.has(message.message_id)), [toggleThread, expandedThreads]);

  if (isLoading || isLoadingFilterOptions) {
    return (
      <ContentLayout>
        <MessagesPageSkeleton />
      </ContentLayout>
    );
  }

  if (!messages) return null;

  return (
    <ContentLayout>
      {/* Header Section */}
      <div className="border-b">
        <div className="max-w-7xl mx-auto px-4 py-6">
          {/* Title and Stats */}
          <div className="mb-6">
            <div className="flex items-center gap-4">
              <div>
                <h1 className="text-3xl font-bold">Messages</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow overflow-auto">
        <div className="max-w-7xl mx-auto px-4 py-6">
          <ThreadedMessagesComponent
            data={messages}
            columns={columns}
            options={options}
            entityData={entityData}
            queryParams={filterOptions}
            allFilterOptions={enhancedFilterOptions}
          />
        </div>
      </div>
    </ContentLayout>
  );
}

const MessagesPageSkeleton = () => {
  const skeletonRows = Array(20).fill(null);

  return (
    <>
      {/* Header Section */}
      <div className="border-b">
        <div className="max-w-7xl mx-auto px-4 py-6">
          {/* Title and Stats */}
          <div className="mb-6">
            <div className="flex items-center gap-4">
              <div>
                <h1 className="text-3xl font-bold">Messages</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow overflow-auto">
        <div className="max-w-7xl mx-auto px-4 py-6">
          <div className="w-full relative">
            {/* Message Filters Skeleton */}
            <div className="p-4 space-y-4">
              {/* Search and Sort Controls */}
              <div className="flex items-center justify-between">
                <div className="h-8 w-[384px] bg-accent/50 rounded-md animate-pulse" />
                <div className="h-8 w-[180px] bg-accent/50 rounded-md animate-pulse" />
              </div>

              {/* Filter Select Buttons */}
              <div className="flex flex-wrap gap-2">
                <div className="h-8 w-[150px] bg-accent/50 rounded-md animate-pulse" />
                <div className="h-8 w-[150px] bg-accent/50 rounded-md animate-pulse" />
                <div className="h-8 w-[150px] bg-accent/50 rounded-md animate-pulse" />
              </div>
            </div>

            {/* Messages Table Skeleton */}
            <div className="rounded-md border">
              <div className="h-[calc(100vh-400px)] overflow-y-auto rounded-lg border bg-card">
                <Table>
                  <TableBody>
                    {skeletonRows.map((_, index) => (
                      <TableRow key={index} className="group relative transition-all duration-200">
                        {/* Avatar Column */}
                        <TableCell className="w-12 p-4">
                          <div
                            className="h-8 w-8 rounded-full bg-accent/50 animate-pulse"
                            style={{
                              animationDelay: `${index * 50}ms`,
                            }}
                          />
                        </TableCell>

                        {/* Main Content Column */}
                        <TableCell className="w-full py-4 pr-4">
                          <div className="space-y-2">
                            <div className="flex items-center gap-2">
                              <div
                                className="h-4 w-32 bg-accent/50 rounded animate-pulse"
                                style={{
                                  animationDelay: `${index * 50 + 100}ms`,
                                }}
                              />
                              <div
                                className="h-4 w-24 bg-accent/50 rounded animate-pulse"
                                style={{
                                  animationDelay: `${index * 50 + 200}ms`,
                                }}
                              />
                            </div>
                            <div
                              className="h-4 w-3/4 bg-accent/50 rounded animate-pulse"
                              style={{
                                animationDelay: `${index * 50 + 300}ms`,
                              }}
                            />
                            <div className="flex gap-2">
                              <div
                                className="h-6 w-24 rounded-full bg-accent/50 animate-pulse"
                                style={{
                                  animationDelay: `${index * 50 + 400}ms`,
                                }}
                              />
                              <div
                                className="h-6 w-24 rounded-full bg-accent/50 animate-pulse"
                                style={{
                                  animationDelay: `${index * 50 + 500}ms`,
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>

                        {/* Timestamp Column */}
                        <TableCell className="w-32 py-4 pr-4">
                          <div
                            className="h-4 w-24 bg-accent/50 rounded animate-pulse"
                            style={{
                              animationDelay: `${index * 50 + 600}ms`,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
