import { useEffect, useState, useContext, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { GET_REPORTS_QUERY_KEY } from "@attrove/service-supabase";
import { Card, Progress } from "@attrove/ui-shadcn";
import { Mail, CheckCircle2, XCircle } from "lucide-react";
import { cn } from "@attrove/ui-shadcn";

export const OutlookCallback: React.FC = () => {
  const [searchParams] = useSearchParams();
  const axiosClient = useContext(AxiosClientContext);
  const navigate = useNavigate();
  const [status, setStatus] = useState<'processing' | 'success' | 'error'>('processing');
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const progressIntervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const handleOutlookCallback = async () => {
      const code = searchParams.get("code");
      if (!code) {
        setError("No authorization code found in the URL.");
        setStatus('error');
        return;
      }

      try {
        // Start progress animation
        progressIntervalRef.current = setInterval(() => {
          setProgress(prev => {
            if (prev >= 90) return prev; // Cap at 90% until success
            return prev + Math.random() * 15;
          });
        }, 1000);

        await axiosClient.post("/integrations/new/microsoft/outlook/callback", { code });
        queryClient.invalidateQueries({ queryKey: [GET_REPORTS_QUERY_KEY] });
        
        clearInterval(progressIntervalRef.current);
        setProgress(100);
        setStatus('success');
        
        // Redirect after showing success state
        setTimeout(() => navigate("/integrations"), 2000);
      } catch (error) {
        console.error("Error processing Outlook callback:", error);
        setError("An error occurred while processing your request. Please try again.");
        setStatus('error');
      }
    };

    handleOutlookCallback();

    // Cleanup function
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, [searchParams, axiosClient, navigate]); // Only dependencies that won't change during the component lifecycle

  return (
    <div className="min-h-screen flex items-center justify-center bg-background p-4">
      <Card className="w-full max-w-md p-8 space-y-6">
        <div className="flex flex-col items-center text-center space-y-4">
          {status === 'processing' && (
            <>
              <div className="relative">
                <div className="absolute inset-0 animate-pulse bg-primary/10 rounded-full" />
                <Mail className="w-12 h-12 text-primary relative z-10" />
              </div>
              <h2 className="text-2xl font-semibold tracking-tight">Connecting to Outlook</h2>
              <p className="text-muted-foreground">
                We're fetching your emails. This usually takes about 10 seconds...
              </p>
            </>
          )}
          
          {status === 'success' && (
            <>
              <div className="relative">
                <div className="absolute inset-0 animate-scale-up bg-green-500/10 rounded-full" />
                <CheckCircle2 className="w-12 h-12 text-green-500 relative z-10" />
              </div>
              <h2 className="text-2xl font-semibold tracking-tight">Successfully Connected!</h2>
              <p className="text-muted-foreground">
                Your Outlook account has been integrated with Attrove.
              </p>
            </>
          )}

          {status === 'error' && (
            <>
              <div className="relative">
                <div className="absolute inset-0 animate-scale-up bg-red-500/10 rounded-full" />
                <XCircle className="w-12 h-12 text-red-500 relative z-10" />
              </div>
              <h2 className="text-2xl font-semibold tracking-tight">Connection Failed</h2>
              <p className="text-muted-foreground">
                {error || "An unexpected error occurred. Please try again."}
              </p>
            </>
          )}

          <Progress 
            value={progress} 
            className={cn(
              "w-full h-2 transition-all duration-300",
              status === 'success' && "bg-green-100 [&>div]:bg-green-500",
              status === 'error' && "bg-red-100 [&>div]:bg-red-500"
            )}
          />

          {status === 'processing' && (
            <div className="grid grid-cols-3 gap-4 w-full pt-4">
              {['Authenticating', 'Fetching Emails', 'Processing'].map((step, index) => (
                <div 
                  key={step}
                  className={cn(
                    "text-xs text-center py-1 px-2 rounded-full transition-colors",
                    progress >= (index + 1) * 33
                      ? "bg-primary/10 text-primary"
                      : "bg-muted text-muted-foreground"
                  )}
                >
                  {step}
                </div>
              ))}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default OutlookCallback;