import { cn, Icons } from "@attrove/ui-shadcn";
import React from "react";

export const Priority: React.FC<{ priority: number }> = ({ priority }) => {
  const Icon = Icons.circleAlert;
  if (priority === 4) {
    return <Icon className="w-7 h-7" />;
  }

  return (
    <div className="inline-flex items-center justify-center w-7 h-7 rounded-full bg-primary">
      <div className="flex gap-0.5 items-end">
        <div className={cn("h-[4px] w-[2px] bg-muted/50 rounded-full", priority >= 1 && "bg-foreground")} />
        <div className={cn("h-[9px] w-[2px] bg-muted/50 rounded-full", priority >= 2 && "bg-foreground")} />
        <div className={cn("h-[14px] w-[2px] bg-muted/50 rounded-full", priority >= 3 && "bg-foreground")} />
      </div>
    </div>
    // <div className="inline-flex items-center justify-center w-7 h-7 rounded-full bg-foreground">
    //   <div className="flex gap-0.5 items-end">
    //     <div className={cn("h-[4px] w-[2px] bg-muted-foreground rounded-full", priority >= 1 && "bg-secondary")} />
    //     <div className={cn("h-[9px] w-[2px] bg-muted-foreground rounded-full", priority >= 2 && "bg-secondary")} />
    //     <div className={cn("h-[14px] w-[2px] bg-muted-foreground rounded-full", priority >= 3 && "bg-secondary")} />
    //   </div>
    // </div>
  );
};
