import type { EnhancedMessage } from "@attrove/service-supabase";
import { getInitials, toMonthDay, toTimeAgo } from "@attrove/attrove-ui/lib/utils";
import { cn, Avatar, AvatarFallback, Badge, Button } from "@attrove/ui-shadcn";
import { MessagesSquare } from "lucide-react";
import { ColumnDef } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { DetailsColumn } from "./details-column";
import { customGlobalFilter } from "./filtering/global-filter";
import { Priority } from "./relevance";
import { Votes } from "./votes";
import { format, formatDistanceToNow, differenceInDays } from "date-fns";

const formatDate = (date: Date): { display: string; isRecent: boolean } => {
  const now = new Date();
  const diffDays = differenceInDays(now, date);

  if (diffDays === 0) {
    return {
      display: formatDistanceToNow(date, { addSuffix: true }),
      isRecent: true,
    };
  } else if (diffDays === 1) {
    return {
      display: "Yesterday",
      isRecent: true,
    };
  } else if (diffDays < 7) {
    return {
      display: format(date, "EEEE"), // Full day name
      isRecent: true,
    };
  } else {
    return {
      display: format(date, "MMMM d"), // Month and day for older dates
      isRecent: false,
    };
  }
};

const PriorityIndicator = ({ priority }: { priority: string }) => {
  const priorityMap: { [key: string]: number } = {
    low: 1,
    medium: 2,
    high: 3,
    urgent: 4,
  };

  return (
    <div className="flex items-center gap-2">
      <span className="text-xs text-muted-foreground">
        <Priority priority={priorityMap[priority.toLowerCase()]} />
      </span>
    </div>
  );
};

const ThreadCount = ({ count }: { count: number }) => {
  if (count <= 1) return null;

  return (
    <Button
      variant="secondary"
      size="sm"
      className="h-7 px-2 gap-1.5 bg-primary/5 hover:bg-primary/10 text-primary hidden md:flex items-center"
    >
      <MessagesSquare className="h-3.5 w-3.5" />
      <span className="text-xs font-medium">{count}</span>
    </Button>
  );
};

export const createColumns = (
  handleEntityFilter: (entityId: string) => void,
  handleRowClick: (message: EnhancedMessage) => void,
): ColumnDef<EnhancedMessage>[] => [
  {
    id: "priority",
    accessorKey: "priority",
    header: () => null,
    // The column itself should be hidden at smaller screens
    size: 40,
    maxSize: 40,
    minSize: 40,
    enableHiding: true,
    cell: ({ row }) => (
      <div className="justify-center hidden lg:flex w-10">
        {row.original.priority && <PriorityIndicator priority={row.original.priority} />}
      </div>
    ),
  },
  {
    id: "entity",
    accessorKey: "entity",
    header: () => null,
    size: 250,
    maxSize: 250,
    minSize: 250,
    cell: ({ row }) => (
      <div className="hidden sm:block w-[200px]">
        <SenderCell message={row.original} handleEntityFilter={handleEntityFilter} />
      </div>
    ),
  },
  {
    id: "thread",
    accessorKey: "thread_message_count",
    header: () => null,
    // Keep consistent width even when hidden or empty
    size: 80,
    maxSize: 80,
    minSize: 80,
    cell: ({ row }) => {
      const message = row.original;
      return (
        // Remove conditional rendering wrapper to maintain consistent width
        <div className="justify-center hidden lg:flex w-8 max-w-8 ml-2">
          {row.original.thread_message_count > 1 && (
            <Button
              onClick={() => handleRowClick(message)}
              variant="secondary"
              size="sm"
              className="h-7 px-2 gap-1.5 bg-primary/5 hover:bg-primary/10 text-primary flex items-center"
            >
              <MessagesSquare className="h-3.5 w-3.5" />
              <span className="text-xs font-medium">{row.original.thread_message_count}</span>
            </Button>
          )}
        </div>
      );
    },
  },
  {
    id: "details",
    accessorKey: "details",
    header: () => null,
    // Force column to take remaining space
    size: Number.MAX_SAFE_INTEGER,
    minSize: 200, // Prevent column from becoming too small
    maxSize: Number.MAX_SAFE_INTEGER,
    // Override any default table cell styles
    meta: {
      // Force table cell to be left aligned and take full width
      className: "!p-0 !w-full align-top",
      cellClassName: "!w-full",
    },
    cell: ({ row }) => {
      const message = row.original;
      return (
        <div
          onClick={() => handleRowClick(message)}
          // Force full width and left alignment at every level
          className="w-full h-full flex items-start px-4 py-2"
        >
          <div className="w-full min-w-0">
            <DetailsColumn message={message} />
          </div>
        </div>
      );
    },
  },
  {
    id: "date",
    accessorKey: "received_at",
    header: () => null,
    size: 140,
    maxSize: 140,
    minSize: 140,
    cell: ({ row }) => {
      const date = new Date(row.original.received_at);
      const { display, isRecent } = formatDate(date);

      return (
        <div className="text-xs whitespace-nowrap pl-4 hidden lg:flex w-[140px]">
          <span className={cn(isRecent ? "text-foreground" : "text-muted-foreground", "font-medium")}>{display}</span>
        </div>
      );
    },
  },
];

const SenderCell: React.FC<{
  message: EnhancedMessage;
  handleEntityFilter: (entityId: string) => void;
}> = ({ message, handleEntityFilter }) => {
  const [, setSearchParams] = useSearchParams();

  const handleSenderClick = (event: React.MouseEvent) => {
    event.preventDefault();
    handleEntityFilter(message.sender_entity_id);
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.set("entity", message.sender_entity_id);
      return newParams;
    });
  };

  return (
    <div className="flex items-center gap-3">
      <div className="relative flex-shrink-0">
        <Avatar className="h-8 w-8 ring-2 ring-background ml-2">
          <AvatarFallback className="bg-primary/10 text-primary">{getInitials(message.sender_name)}</AvatarFallback>
        </Avatar>
      </div>

      <div className="min-w-0">
        <button
          onClick={handleSenderClick}
          className="text-sm font-medium hover:text-primary transition-colors block truncate max-w-[160px]"
        >
          {message.sender_name}
        </button>
        <p className="text-xs text-muted-foreground truncate max-w-[160px]">{message.subject}</p>
      </div>
    </div>
  );
};
