import { FullNameInput } from "@attrove/attrove-ui/components/profile/full-name";
import { PreferredNameInput } from "@attrove/attrove-ui/components/profile/preferred-name";
import { Card, CardContent, Input, Label } from "@attrove/ui-shadcn";
import { SettingsDebounceInput } from "@attrove/attrove-ui/components/profile/settings-debounce-input";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { useCallback, useState, memo, useMemo } from "react";
import { type User, updateUser } from "@attrove/service-supabase";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { RoleInput, RoleType } from "@attrove/attrove-ui/components/profile/role";

interface NameSettingsProps {
  user: User;
}

const NameInput = memo(({ 
  field, 
  value, 
  onChange, 
  onSave,
  isLoading,
  Component,
}: { 
  field: 'full_name' | 'preferred_name';
  value: string;
  onChange: (field: 'full_name' | 'preferred_name', value: string) => void;
  onSave: (field: 'full_name' | 'preferred_name', value: string) => void;
  isLoading: boolean;
  Component: typeof FullNameInput | typeof PreferredNameInput;
}) => {
  // Memoize the callbacks to prevent unnecessary re-renders
  const handleChange = useCallback((value: string) => {
    onChange(field, value);
  }, [onChange, field]);

  const handleSave = useCallback((value: string) => {
    onSave(field, value);
  }, [onSave, field]);

  return (
    <Component
      DebounceInput={SettingsDebounceInput}
      value={value}
      onChange={handleChange}
      onSave={handleSave}
      isLoading={isLoading}
    />
  );
});

NameInput.displayName = 'NameInput';

export const NameSettings = memo(({ user }: NameSettingsProps) => {
  const { session } = useSession();
  const queryClient = useQueryClient();
  const [values, setValues] = useState({
    full_name: user.full_name || "",
    preferred_name: user.preferred_name || "",
    role: user.role as RoleType
  });
  const [savingFields, setSavingFields] = useState<Set<string>>(new Set());

  const updateUserMutation = useMutation({
    mutationFn: async ({ field, value }: { field: keyof typeof values; value: string }) => {
      await updateUser(supabase, user.id, { [field]: value });
      return { field, value };
    },
    onMutate: ({ field, value }) => {
      setSavingFields(prev => new Set(prev).add(field));
      
      // Optimistic update
      queryClient.setQueryData(['user', user.id], (oldData: User | undefined) => {
        if (!oldData) return oldData;
        return { ...oldData, [field]: value };
      });
    },
    onSettled: ({ field }) => {
      setSavingFields(prev => {
        const next = new Set(prev);
        next.delete(field);
        return next;
      });
    },
    onError: (error, { field, value }) => {
      // Revert optimistic update
      queryClient.setQueryData(['user', user.id], (oldData: User | undefined) => {
        if (!oldData) return oldData;
        return { ...oldData, [field]: values[field] };
      });
    }
  });

  const handleFieldChange = useCallback((field: keyof typeof values, value: string) => {
    setValues(prev => ({ ...prev, [field]: value }));
  }, []);

  const handleFieldSave = useCallback((field: keyof typeof values, value: string) => {
    if (value === user[field] || savingFields.has(field)) return;
    updateUserMutation.mutate({ field, value });
  }, [updateUserMutation, user, savingFields]);

  const handleRoleChange = useCallback((role: RoleType) => {
    if (role === user.role || savingFields.has('role')) return;
    setValues(prev => ({ ...prev, role }));
    updateUserMutation.mutate({ field: 'role', value: role });
  }, [updateUserMutation, user.role, savingFields]);

  // Memoize the email input to prevent unnecessary re-renders
  const emailInput = useMemo(() => (
    <div className="flex flex-col gap-2">
      <Label>Email</Label>
      <Input disabled placeholder={session.user.email} />
    </div>
  ), [session.user.email]);

  return (
    <Card className="max-w-2xl">
      <CardContent className="p-6">
        <div className="space-y-4">
          <div className="space-y-2">
            <h2 className="text-lg font-semibold">Personal Information</h2>
          </div>

          <div className="flex gap-4 flex-col">
            <NameInput
              field="full_name"
              value={values.full_name}
              onChange={handleFieldChange}
              onSave={handleFieldSave}
              isLoading={savingFields.has('full_name')}
              Component={FullNameInput}
            />
            <NameInput
              field="preferred_name"
              value={values.preferred_name}
              onChange={handleFieldChange}
              onSave={handleFieldSave}
              isLoading={savingFields.has('preferred_name')}
              Component={PreferredNameInput}
            />
            <RoleInput
              value={values.role}
              onChange={handleRoleChange}
              isLoading={savingFields.has('role')}
            />
            {emailInput}
          </div>
        </div>
      </CardContent>
    </Card>
  );
});
