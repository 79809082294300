export const stringToUrl = (str: string): URL | void => {
  try {
    return new URL(str);
  } catch (error) {
    console.error(error);
  }
}

export function capitalizeFirstLetter(string: string | undefined | null): string {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}