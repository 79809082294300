// table.tsx
import React, { useState } from 'react';
import { ColumnDef, ColumnFiltersState, flexRender, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable, SortingState } from "@tanstack/react-table";
import { Table, TableBody, TableCell, Card, ScrollArea, TableHeader, TableRow } from "@attrove/ui-shadcn";
import { PeopleFilters, tagFilterFn } from "@attrove/attrove-ui/features/people/filtering/filtering";
import { TopEntity, PeopleOptionsType } from "@attrove/attrove-ui/app/pages/dashboard/people";

interface PeopleTableProps {
  columns: ColumnDef<TopEntity, any>[];
  data: TopEntity[];
  options: PeopleOptionsType;
  timePeriod: string;
  onTimePeriodChange: (value: string) => void;
  onPersonSelect: (person: TopEntity) => void;
}

export function PeopleTable({ columns, data, options, timePeriod, onTimePeriodChange, onPersonSelect}: PeopleTableProps) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnFilters,
      sorting,
    },
    filterFns: {
      tagFilter: tagFilterFn,
    },
  });

  return (
    <div className="w-full space-y-4">
      <PeopleFilters 
        options={options} 
        table={table} 
        timePeriod={timePeriod}
        onTimePeriodChange={onTimePeriodChange}
      />  
      <Card className="relative overflow-hidden">
        <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-accent to-accent/30" />
        <ScrollArea className="h-[calc(100vh-412px)]">
                  <Table>
          <TableHeader>
            {/* ... (keep the existing header code) */}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow 
                  key={row.id} 
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => onPersonSelect(row.original)}
                  className="cursor-pointer hover:bg-accent/50 data-[state=selected]:bg-muted transition-all duration-200"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        </ScrollArea>
      </Card>
    </div>
  );
}