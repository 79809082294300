import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { supabase } from "@attrove/attrove-ui/app/supabase";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { Onboarding } from "@attrove/attrove-ui/features/onboarding";
import { MobileNav } from "./mobile-nav";
import { DashboardNav } from "./sidebar-nav";
import { useNotifications } from "@attrove/attrove-ui/hooks/notifications";
import NotificationBell from "@attrove/attrove-ui/components/NotificationElements";
import { Toaster } from "@attrove/ui-shadcn";

const sidebarNav = [
  {
    title: "Intelligence",
    items: [
      // {
      //   title: "Dashboard",
      //   href: "/dashboard",
      //   icon: "dashboard",
      // },
      {
        title: "Reports",
        href: "/reports?reportId=latest",
        icon: "clipboard",
      },
      {
        title: "Schedule",
        href: "/schedule",
        icon: "calendar",
      },
      {
        title: "People",
        href: "/people",
        icon: "people",
      },
      // {
      //   title: "Generations",
      //   href: "/generations",
      //   icon: "sparkles",
      // },
      // {
      //   title: "Insights",
      //   href: "/insights",
      //   icon: "lightbulb",
      // },
      {
        title: "Tags",
        href: "/tags",
        icon: "tag",
      },
    ],
  },
  {
    title: "Input",
    items: [
      {
        title: "Messages",
        href: "/messages",
        icon: "messages",
      },
      // {
      //   title: "Flow",
      //   href: "/flow",
      //   icon: "workflow",
      // },
      {
        title: "Integrations",
        href: "/integrations",
        icon: "usb",
      },
    ],
  },
];

export default function DashboardLayout() {
  const navigate = useNavigate();
  const { user, session } = useSession();
  const { notifications, unreadCount, markAsRead, dismissNotification, dismissAllRead, undoDismiss } = useNotifications(supabase, user.id);

  if (!user.auth_completed) return <Navigate to="/complete-auth" replace />;

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.log(error);
      return;
    }
    navigate("/password-signin");
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <DashboardNav items={sidebarNav} onLogout={handleLogout} userEmail={session.user?.email || "User"} className="hidden md:block" />
      <MobileNav
        items={sidebarNav}
        onLogout={handleLogout}
        userEmail={session.user?.email || "User"}
        className="md:hidden"
        notificationCount={unreadCount}
        notifications={notifications}
        onMarkAsRead={(id: string) => id && markAsRead(id)}
      />
      <main className="flex w-full flex-1 flex-col">
        <header className="h-16 shrink-0 border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex h-full items-center justify-end gap-4 px-4">
          <NotificationBell 
              count={unreadCount} 
              notifications={notifications.map(n => ({
                ...n,
                is_dismissed: n.is_dismissed ?? undefined,
                metadata: n.metadata || undefined
              }))} 
              onMarkAsRead={markAsRead}
              onDismiss={dismissNotification}
              onDismissAllRead={dismissAllRead}
              onUndoDismiss={undoDismiss}
            />          
            </div>
        </header>
        <div className="flex-1 flex-col overflow-hidden md:p-3 md:pl-0">
          <div className="h-full overflow-y-auto md:rounded-md md:border bg-muted">{user.onboarded ? <Outlet /> : <Onboarding />}</div>
        </div>
      </main>
      <Toaster />
    </div>
  );
}
