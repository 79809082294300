import { z } from "zod";
import { type AttroveSupabaseClient, DB, entityRowSchema, entityRelationRowSchema } from "@attrove/service-supabase";
import { logError, logInfo, logWarn } from "@attrove/util-logs";
import { Logtail } from "@logtail/node";
import { exponentialBackoff, LocalCache } from "@attrove/util-generic";

// Define types based on the database schema
export type Entity = z.infer<typeof entityRowSchema>;
export type EntityRelation = z.infer<typeof entityRelationRowSchema>;

const EntityCache = new LocalCache<string>()

function generateKey(userId: string, externalId: string): string {
  return `${userId}:${externalId.toLowerCase()}`;
}

// Create wrapper methods for the cache
const entityCache = {
  get: (userId: string, externalId: string) =>
    EntityCache.get(generateKey(userId, externalId)),

  set: (userId: string, externalId: string, value: string) =>
    EntityCache.set(generateKey(userId, externalId), value),

  setInFlightRequest: (userId: string, externalId: string, promise: Promise<string>) =>
    EntityCache.setInFlightRequest(generateKey(userId, externalId), promise),

  clear: () => EntityCache.clear()
};


export type EntityData = {
  name: string;
  entity_type: string;
  external_ids: Array<string>;
  identity_type: string;
  metadata?: Record<string, any>;
};

export class EntityManager {
  private supabaseClient: AttroveSupabaseClient;
  private logtail?: Logtail;
  private MAX_RETRIES = 3;
  private BASE_DELAY = 100; // milliseconds
  private MAX_DELAY = 1000; // milliseconds

  constructor(supabaseClient: AttroveSupabaseClient, logtail?: Logtail) {
    this.supabaseClient = supabaseClient;
    this.logtail = logtail;
  }

  async getOrCreateEntity(
    userId: string,
    integrationId: number,
    entityData: EntityData,
    retryCount = 0
  ): Promise<string> {

    try {
      if (!entityData || typeof entityData !== 'object') {
        throw new Error(`Invalid entity data: ${JSON.stringify(entityData)}`);
      }

      const { entity_type, metadata } = entityData;
      let { name, external_ids } = entityData;

      if (!entity_type || !external_ids || external_ids.length === 0) {
        throw new Error(`Missing required entity data: ${JSON.stringify(entityData)}`);
      }

      // Use fallback name if name is empty
      if (!name || !name.trim()) {
        const emailId = external_ids.find(id => id.includes('@'));
        const emailParts = emailId ? emailId.split('@') : ['Unknown'];
        name = emailParts[0] || 'Unknown';
      }

      external_ids = external_ids.map(id => id.toLowerCase());


      // Check both cache and in-flight requests
      const cachedResult = entityCache.get(userId, external_ids[0]);
      if (cachedResult) {
        return cachedResult;
      }

      // Create new request promise and store it
      const entity_id_promise = (async () => {
        const startTime = Date.now();
        const { data, error } = await this.supabaseClient.rpc(
          'get_or_create_entity',
          {
            p_user_id: userId,
            p_name: name,
            p_entity_type: entity_type,
            p_external_ids: external_ids
          }
        );
        
        const [result] = data || [];
        const { entity_id, all_external_ids } = result || {};

        const duration = Date.now() - startTime;
        if (duration > 1000) {
          logError("[getOrCreateEntity] LONG OPERATION", { 
            duration,
            userId,
            name,
            entity_type,
            integrationId,
            external_ids,
            metadata: metadata ? JSON.stringify(metadata) : undefined
          }, this.logtail);
        }

      if (error) {
        if (error.message.includes('unique constraint') && retryCount < this.MAX_RETRIES) {
          const delay = exponentialBackoff(retryCount, this.BASE_DELAY, this.MAX_DELAY);
          logWarn("[getOrCreateEntity] Encountered conflict, retrying", { 
            retryCount: retryCount + 1, 
            maxRetries: this.MAX_RETRIES, 
            external_id: external_ids,
            delay : delay
          }, this.logtail);
          
          await new Promise(resolve => setTimeout(resolve, delay));
          return this.getOrCreateEntity(userId, integrationId, entityData, retryCount + 1);
        }
        throw new Error(`Database error: ${error.message}`);
      }

        if (!entity_id) {
          throw new Error("No entity ID returned from get_or_create_entity");
        }

        for (const external_id of all_external_ids) { 
          entityCache.set(userId, external_id, entity_id);
        }

        return entity_id;
      })();

      // Store the same promise for all external IDs
      const promise = entity_id_promise;
      for (const external_id of external_ids) { 
        entityCache.setInFlightRequest(userId, external_id, promise);
      }
      return promise;

    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      logError("[getOrCreateEntity] Error", { 
        error: errorMessage, 
        userId, 
        integrationId, 
        entityData,
        retryCount,
        stack: error instanceof Error ? error.stack : undefined
      }, this.logtail);
      throw error;
    }
  }

  async batchGetOrCreateEntities(
    userId: string,
    integrationId: number,
    entitiesData: EntityData[]
  ): Promise<string[]> {
    try {
      // logInfo("[batchGetOrCreateEntities] Starting", { userId, integrationId, entityCount: entitiesData.length }, this.logtail);
  
      if (!entitiesData || entitiesData.length === 0) {
        // logInfo("[batchGetOrCreateEntities] No entities to process", {}, this.logtail);
        return [];
      }
  
      const results = await Promise.all(
        entitiesData.map(async (entityData) => {
          try {
            return await this.getOrCreateEntity(userId, integrationId, entityData);
          } catch (error) {
            logWarn("[batchGetOrCreateEntities] Error processing entity", { error, entityData }, this.logtail);
            return null;
          }
        })
      );
  
      const validResults = results.filter((result): result is string => result !== null);
  
      // logInfo("[batchGetOrCreateEntities] Completed", { 
      //   processedCount: entitiesData.length,
      //   successCount: validResults.length,
      //   failedCount: entitiesData.length - validResults.length
      // }, this.logtail);
  
      return validResults;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      logError("[batchGetOrCreateEntities] Error", { error: errorMessage, userId, integrationId }, this.logtail);
      throw error;
    }
  }

  async createEntityRelation(
    userId: string,
    entityId1: string,
    entityId2: string,
    relationType: string,
    metadata?: any
  ): Promise<EntityRelation> {
    try {
      const { data, error } = await this.supabaseClient
        .from(DB.ENTITY_RELATION)
        .insert({
          user_id: userId,
          entity_id_1: entityId1,
          entity_id_2: entityId2,
          relation_type: relationType,
          metadata
        })
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      logError("Error in createEntityRelation", { error, userId, entityId1, entityId2, relationType }, this.logtail);
      throw error;
    }
  }

  async getEntityRelations(userId: string, entityId: string): Promise<EntityRelation[]> {
    try {
      const { data, error } = await this.supabaseClient
        .from(DB.ENTITY_RELATION)
        .select('*')
        .or(`entity_id_1.eq.${entityId},entity_id_2.eq.${entityId}`)
        .eq('user_id', userId);

      if (error) throw error;
      return data;
    } catch (error) {
      logError("Error in getEntityRelations", { error, userId, entityId }, this.logtail);
      throw error;
    }
  }

  async updateEntity(entityId: string, updateData: Partial<Entity>): Promise<Entity> {
    try {
      const { data, error } = await this.supabaseClient
        .from(DB.ENTITY)
        .update(updateData)
        .eq('id', entityId)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      logError("Error in updateEntity", { error, entityId, updateData }, this.logtail);
      throw error;
    }
  }

  async deleteEntity(entityId: string): Promise<void> {
    try {
      const { error } = await this.supabaseClient
        .from(DB.ENTITY)
        .delete()
        .eq('id', entityId);

      if (error) throw error;
    } catch (error) {
      logError("Error in deleteEntity", { error, entityId }, this.logtail);
      throw error;
    }
  }

  async searchEntities(userId: string, searchTerm: string, limit: 10): Promise<Entity[]> {
    try {
      const { data, error } = await this.supabaseClient
        .from(DB.ENTITY)
        .select('*')
        .eq('user_id', userId)
        .or(`name.ilike.%${searchTerm}%,entity_type.ilike.%${searchTerm}%`)
        .limit(limit);

      if (error) throw error;
      return data;
    } catch (error) {
      logError("Error in searchEntities", { error, userId, searchTerm }, this.logtail);
      throw error;
    }
  }

  async getEntityName(entityId: string): Promise<string> {
    try {
      const { data, error } = await this.supabaseClient
        .from(DB.ENTITY)
        .select('name')
        .eq('id', entityId)
        .single();

      if (error) throw error;

      if (!data || !data.name) {
        logWarn("[getEntityName] Entity not found or name is null", { entityId }, this.logtail);
        return "Unknown";
      }

      return data.name;
    } catch (error) {
      logError("[getEntityName] Error fetching entity name", { 
        error: error instanceof Error ? error.message : String(error),
        entityId,
        stack: error instanceof Error ? error.stack : undefined
      }, this.logtail);
      throw error;
    }
  }
  
  clearCache(): void {
    entityCache.clear();
    logInfo("[EntityManager] Cache cleared", {}, this.logtail);
  }
}