import { Check } from "lucide-react";
import { useTimezoneSelect } from "react-timezone-select";

import { supabase } from "@attrove/attrove-ui/app/supabase";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { updateUser } from "@attrove/service-supabase";
import {
  buttonVariants,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@attrove/ui-shadcn";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";

export const TimezoneCombobox = () => {
  const { user } = useSession();
  const [open, setOpen] = useState(false);

  function getUniqueTimezones(timezones: any[]) {
    return Array.from(new Map(timezones.map((tz) => [tz.altName, tz])).values());
  }

  const { options, parseTimezone } = useTimezoneSelect({});

  const uniqueOptions = getUniqueTimezones(options);

  // Keep the IANA format for internal use
  const currentTimezoneIANA = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Use the display name only for rendering
  const currentTimezoneDisplay = parseTimezone(currentTimezoneIANA).altName;

  const [altNameToIANA, ianaToAltName] = useMemo(() => {
    const altToIANA = new Map();
    const ianaToAlt = new Map();
    uniqueOptions.forEach((option) => {
      altToIANA.set(option.altName, option.value);
      ianaToAlt.set(option.value, option.altName);
    });
    return [altToIANA, ianaToAlt];
  }, [uniqueOptions]);

  const [value, setValue] = useState(convertFromIANA(user.timezone ?? currentTimezoneIANA));

  // Function to convert altName to IANA timezone
  const convertToIANA = (altName: string) => {
    return altNameToIANA.get(altName) || altName;
  };

  // Function to convert IANA timezone to altName
  function convertFromIANA(ianaTimezone: string | undefined) {
    if (!ianaTimezone) return "Invalid timezone";

    try {
      // Attempt to create a valid date with the given timezone
      new Date().toLocaleString("en-US", { timeZone: ianaTimezone });

      // If the above doesn't throw an error, it's a valid IANA timezone
      return ianaToAltName.get(ianaTimezone) || ianaTimezone;
    } catch (error) {
      console.error("Invalid timezone:", error);
      return "Invalid timezone";
    }
  }

  const { mutate } = useMutation({
    mutationFn: async (timezone: string) => {
      return await updateUser(supabase, user.id, {
        timezone: convertToIANA(timezone),
      });
    },
  });

  useEffect(() => {
    if (!user.timezone && currentTimezoneIANA) {
      mutate(currentTimezoneIANA);
    }
  }, [currentTimezoneIANA, mutate, user.timezone]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div
          aria-expanded={open}
          className={cn(
            buttonVariants({ variant: "outline" }),
            "justify-between px-3 bg-transparent flex w-full text-foreground items-center",
          )}
        >
          <p className="w-full text-left">
            {value ? uniqueOptions.find((option) => option.altName === value)?.altName : "Select timezone..."}
          </p>

          <CaretSortIcon className="h-4 w-4 opacity-50" />
        </div>
      </PopoverTrigger>
      <PopoverContent className=" p-0">
        <Command>
          <CommandInput placeholder="Search timezones..." />
          <CommandList>
            <CommandEmpty>No timezone found.</CommandEmpty>
            <CommandGroup>
              {uniqueOptions.map((option, index) => (
                <CommandItem
                  key={index}
                  value={option.altName}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                    mutate(currentValue);
                  }}
                >
                  <Check className={cn("mr-2 h-4 w-4", value === option.altName ? "opacity-100" : "opacity-0")} />
                  {option.altName}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
