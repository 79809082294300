import { ReportTimeInput } from "@attrove/attrove-ui/components/profile/report-time";
import { NameSettings } from "@attrove/attrove-ui/features/settings/name";
import { ThemeDropdown } from "@attrove/attrove-ui/features/settings/theme";
import { VoiceSelect } from "@attrove/attrove-ui/features/settings/voice-select";
import { DeleteAccount } from "@attrove/attrove-ui/features/settings/delete-account";
import { GoalSettings } from "@attrove/attrove-ui/features/settings/goal";
import {
  Card,
  CardContent,
} from "@attrove/ui-shadcn";
import { NotificationPreferences } from "@attrove/attrove-ui/components/profile/notification-preferences";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";

export default function Profile() {
  const { user } = useSession();

  return (
    <div className="flex flex-col gap-4">
      <div className="max-w-2xl w-full">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-xl font-bold">Profile</h1>
          <div className="flex items-center gap-2">
            <span className="text-sm text-muted-foreground">Experience Personalization</span>
          </div>
        </div>
      </div>

      <GoalSettings user={user} />

      <NameSettings user={user} />

      <Card className="max-w-2xl">
        <CardContent className="p-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <h2 className="text-lg font-semibold">Daily Rundown Time</h2>
            </div>
            <ReportTimeInput />
          </div>
        </CardContent>
      </Card>

      <VoiceSelect />

      <ThemeDropdown />

      <NotificationPreferences />

      <Card className="max-w-2xl">
        <CardContent className="p-6">
          <div className="flex flex-col gap-4">
            <h2 className="text-lg font-semibold text-destructive">Danger Zone</h2>
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-medium">Delete Account</h3>
                <p className="text-sm text-muted-foreground">
                  Permanently delete your account and all associated data
                </p>
              </div>
              <DeleteAccount />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
