import { useContext, useEffect, useState } from "react";
import { NewIntegrationCard } from "@attrove/attrove-ui/components/integrations/new-integration-card";

import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { useNavigate, useSearchParams } from "react-router-dom";
import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { GET_REPORTS_QUERY_KEY } from "@attrove/service-supabase";

export const GmailIntegration: React.FC<{
  onSuccess?: () => void;
}> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const axiosClient = useContext(AxiosClientContext);
  const navigate = useNavigate();

  const provider = searchParams.get("provider");
  const code = searchParams.get("code");

  useEffect(() => {
    async function handleGmailCallback() {
      if (onSuccess) {
        onSuccess();
      }

      try {
        const response = await axiosClient.get(`/integrations/new?provider=gmail&code=${code}`);
      } catch (error) {
        console.log("error", error);
      }

      navigate("/integrations");
      queryClient.invalidateQueries({ queryKey: [GET_REPORTS_QUERY_KEY] });
    }

    if (provider === "gmail" && code) {
      setLoading(false);
      handleGmailCallback();
    }
  }, [provider, code]);

  const handleGmailIntegration = async () => {
    setLoading(true);

    const response = await axiosClient.get("/integrations/new?provider=gmail");
    console.log("response", response.data);

    if (response.status === 200 && response.data.authUrl) {
      window.location.href = response.data.authUrl;
    }
  };

  return <NewIntegrationCard provider={"gmail"} name={"Gmail"} loading={loading} onClick={handleGmailIntegration} />;
};
