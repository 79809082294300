import { Textarea, Label } from "@attrove/ui-shadcn";

const EXAMPLE_GOALS = [
  "I want to improve team collaboration and reduce communication bottlenecks",
  "I need to stay on top of critical project deadlines and dependencies",
  "I want to identify and resolve team blockers more efficiently",
  "I aim to better understand my team's progress and challenges"
];

// Select a random goal once at module level, not on each render
const DEFAULT_PLACEHOLDER = EXAMPLE_GOALS[Math.floor(Math.random() * EXAMPLE_GOALS.length)];

type InputProps = {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
};

export const CurrentGoalInput: React.FC<InputProps> = ({ 
  value, 
  onChange,
  onBlur,
  isLoading,
  disabled
}) => {
  return (
    <div className="flex flex-col gap-2">
      <Label>What's your current goal?</Label>
      <Textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        placeholder={DEFAULT_PLACEHOLDER}
        disabled={isLoading || disabled}
        className="min-h-[100px] resize-none"
      />
    </div>
  );
};
