export const API_URL = import.meta.env.VITE_API_URL || "https://api2.attrove.com/";

export const integrations = [
  {
    name: "Gmail",
    icon: "gmail",
  },
  {
    name: "Github",
    icon: "github",
  },
  {
    name: "Discord",
    icon: "discord",
  },
  {
    name: "Slack",
    icon: "slack",
  },
];

export const starterIntegrations = [
  {
    name: "Gmail",
    icon: "gmail",
  },
  {
    name: "Outlook",
    icon: "outlook",
  },
  {
    name: "Slack",
    icon: "slack",
  },
];

export type Meeting = (typeof meetings)[number];

export const meetings = [
  {
    id: "1",
    title: "Project Review",
    time: "8:00 AM",
    organizer: {
      name: "Alice Johnson",
      email: "alice.johnson@example.com",
      profilePicture: "https://picsum.photos/200/200",
    },
    relatedEmails: [
      {
        sender: {
          name: "Bob Smith",
          email: "bob.smith@example.com",
          profilePicture: "https://picsum.photos/200/300",
        },
        datetime: "7:52 AM",
        subject: "New Marketing Strategy",
        body: "Looking forward to our meeting tomorrow. I've prepared some slides for review.",
      },
      {
        sender: {
          name: "Alice Johnson",
          email: "alice.johnson@example.com",
          profilePicture: "https://picsum.photos/200/300",
        },
        datetime: "8:00 AM",
        subject: "New Marketing Strategy",
        body: "Great, Bob. I'll make sure to review them before our meeting.",
      },
    ],
    relatedMessages: [
      {
        sender: {
          name: "Charlie Davis",
          profilePicture: "https://picsum.photos/200/300",
        },
        message: "Hey team, don't forget to bring your project updates to the meeting!",
        datetime: "8:00 AM",
      },
      {
        sender: {
          name: "Diana Evans",
          profilePicture: "https://picsum.photos/200/300",
        },
        message: "Thanks for the reminder, Charlie. I've just finished my report.",
        datetime: "8:00 AM",
      },
    ],
    participants: [
      {
        name: "Alice Johnson",
        email: "alice.johnson@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
      {
        name: "Bob Smith",
        email: "bob.smith@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
      {
        name: "Charlie Davis",
        email: "charlie.davis@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
      {
        name: "Diana Evans",
        email: "diana.evans@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
    ],
    attachments: [
      {
        type: "Google Doc",
        link: "https://docs.google.com/document/d/abc123",
      },
      {
        type: "Google Slides",
        link: "https://docs.google.com/presentation/d/xyz789",
      },
    ],
    description: "Quarterly project review meeting to discuss progress, challenges, and next steps for Q3 2024.",
    summary: `This quarterly project review meeting is a crucial checkpoint for the team's progress in Q3 2024. Led by Alice Johnson, the meeting will bring together key team members to assess the current state of ongoing projects, identify challenges, and outline strategic next steps.

Participants are expected to come prepared with their project updates, as emphasized in the Slack messages. Bob Smith has prepared slides for review, which will likely form a significant part of the discussion. The meeting will cover both completed milestones and any obstacles encountered during the quarter.

Two important documents are attached for reference: a Google Doc, which may contain detailed project reports or agenda items, and a Google Slides presentation, possibly containing visual representations of project data or proposed strategies. These documents will serve as focal points for the discussion and decision-making process.

The meeting aims to align the team on priorities for the remainder of Q3 and potentially set the groundwork for Q4 planning. Attendees should be ready to contribute insights, raise concerns, and collaboratively develop solutions to keep projects on track.`,
  },
  {
    id: "2",
    title: "Marketing Sync-Up",
    time: "9:00 AM",
    organizer: {
      name: "Eva Fischer",
      email: "eva.fischer@example.com",
      profilePicture: "https://picsum.photos/200/100",
    },
    relatedEmails: [
      {
        sender: {
          name: "Frank Garcia",
          email: "frank.garcia@example.com",
          profilePicture: "https://picsum.photos/200/300",
        },
        datetime: "9:15 AM",
        subject: "New Marketing Strategy",
        body: "Eva, can we discuss the new marketing strategy during tomorrow's meeting?",
      },
      {
        sender: {
          name: "Eva Fischer",
          email: "eva.fischer@example.com",
          profilePicture: "https://picsum.photos/200/300",
        },
        datetime: "10:30 AM",
        subject: "New Marketing Strategy",
        body: "Absolutely, Frank. I'll add it to the agenda.",
      },
    ],
    relatedMessages: [
      {
        sender: {
          name: "Grace Hill",
          profilePicture: "https://picsum.photos/200/300",
        },
        datetime: "10:30 AM",
        message: "Just a heads up, I might be a few minutes late to the meeting. Traffic is heavy today.",
      },
      {
        sender: {
          name: "Eva Fischer",
          profilePicture: "https://picsum.photos/200/300",
        },
        message: "No problem, Grace. We'll wait for you before starting the main discussion.",
        datetime: "10:30 AM",
      },
    ],
    participants: [
      {
        name: "Eva Fischer",
        email: "eva.fischer@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
      {
        name: "Frank Garcia",
        email: "frank.garcia@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
      {
        name: "Grace Hill",
        email: "grace.hill@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
      {
        name: "Henry Irving",
        email: "henry.irving@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
    ],
    attachments: [
      {
        type: "Google Doc",
        link: "https://docs.google.com/document/d/def456",
      },
    ],
    description: "Marketing team sync-up to review current campaign performance and discuss new strategies for Q4 2024.",
    summary: `This Marketing Sync-Up, organized by Eva Fischer, is a critical meeting for the marketing team to align on current performance and strategize for the upcoming quarter. The primary focus will be twofold: evaluating the effectiveness of ongoing campaigns and brainstorming new approaches for Q4 2024.

A key agenda item, as requested by Frank Garcia, will be the discussion of a new marketing strategy. This suggests that the team is looking to pivot or enhance their current approach, possibly in response to market trends or performance data.

The attached Google Doc likely contains relevant marketing metrics, campaign reports, or draft strategies for the team to review. Participants should familiarize themselves with this document before the meeting to ensure productive discussions.

While the meeting is set to start at 9:00 AM, there's flexibility to accommodate Grace Hill's potential late arrival due to traffic. This demonstrates the team's collaborative nature and commitment to ensuring all voices are heard in the strategic planning process.

Attendees should come prepared to share insights on their respective areas, discuss the effectiveness of current strategies, and contribute ideas for innovative marketing approaches for the final quarter of 2024. The meeting will likely conclude with actionable next steps and assigned responsibilities to implement the new strategies discussed.`,
  },
  {
    id: "3",
    title: "Product Demo",
    time: "12:00 PM",
    organizer: {
      name: "Ian Jones",
      email: "ian.jones@example.com",
      profilePicture: "https://picsum.photos/200/500",
    },
    relatedEmails: [
      {
        sender: {
          name: "Julia Kim",
          email: "julia.kim@example.com",
          profilePicture: "https://picsum.photos/200/300",
        },
        datetime: "12:00 PM",
        subject: "New Marketing Strategy",
        body: "Ian, I've finished the prototype. Excited to demo it at tomorrow's meeting!",
      },
      {
        sender: {
          name: "Ian Jones",
          email: "ian.jones@example.com",
          profilePicture: "https://picsum.photos/200/300",
        },
        datetime: "12:00 PM",
        subject: "New Marketing Strategy",
        body: "That's great news, Julia! Looking forward to seeing it.",
      },
    ],
    relatedMessages: [
      {
        sender: {
          name: "Kelly Lee",
          profilePicture: "https://picsum.photos/200/300",
        },
        datetime: "12:00 PM",
        message: "Does anyone need me to bring extra HDMI cables for the demo?",
      },
      {
        sender: {
          name: "Liam Moore",
          profilePicture: "https://picsum.photos/200/300",
        },
        message: "Yes, please! Better safe than sorry.",
        datetime: "12:00 PM",
      },
    ],
    participants: [
      {
        name: "Ian Jones",
        email: "ian.jones@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
      {
        name: "Julia Kim",
        email: "julia.kim@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
      {
        name: "Kelly Lee",
        email: "kelly.lee@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
      {
        name: "Liam Moore",
        email: "liam.moore@example.com",
        profilePicture: "https://picsum.photos/200/300",
      },
    ],
    attachments: [
      {
        type: "Google Slides",
        link: "https://docs.google.com/presentation/d/ghi789",
      },
    ],
    description: "Product team meeting to review and demo the latest prototype for the upcoming feature release.",
    summary: `This Product Demo meeting, scheduled for 12:00 PM and organized by Ian Jones, marks a significant milestone in the development of a new feature. The primary purpose of this gathering is to showcase and review the latest prototype, which has just been completed by Julia Kim.

The meeting will center around Julia's demonstration of the prototype, likely showcasing the functionality, user interface, and any innovative aspects of the upcoming feature. This presentation is crucial for gathering feedback, identifying potential improvements, and aligning the team on the next steps in the development process.

A Google Slides presentation is attached to the meeting, which may contain supplementary information about the prototype, such as technical specifications, user stories, or performance metrics. All participants should review this document prior to the meeting to ensure a productive discussion.

This demo is a critical step in the product development cycle, serving to validate the work done so far and guide the direction of future efforts. It's an opportunity for the team to collectively ensure that the new feature meets both technical requirements and user needs before moving forward with the release process.`,
  },
];

// export const meetings = [
//   {
//     id: "3",
//     title: "Product Demo",
//     time: "12:00 PM",
//     organizer: {
//       name: "Ian Jones",
//       email: "ian.jones@example.com",
//       profilePicture: "https://picsum.photos/200/500",
//     },
//     relatedEmails: [
//       {
//         sender: {
//           name: "Julia Kim",
//           email: "julia.kim@example.com",
//           profilePicture: "https://picsum.photos/200/300",
//         },
//         subject: "New Marketing Strategy",
//         body: "Ian, I've finished the prototype. Excited to demo it at tomorrow's meeting!",
//       },
//       {
//         sender: {
//           name: "Ian Jones",
//           email: "ian.jones@example.com",
//           profilePicture: "https://picsum.photos/200/300",
//         },
//         subject: "New Marketing Strategy",
//         body: "That's great news, Julia! Looking forward to seeing it.",
//       },
//     ],
//     relatedMessages: [
//       {
//         sender: {
//           name: "Kelly Lee",
//           profilePicture: "https://picsum.photos/200/300",
//         },
//         message: "Does anyone need me to bring extra HDMI cables for the demo?",
//       },
//       {
//         sender: {
//           name: "Liam Moore",
//           profilePicture: "https://picsum.photos/200/300",
//         },
//         message: "Yes, please! Better safe than sorry.",
//       },
//     ],
//     participants: [
//       {
//         name: "Ian Jones",
//         email: "ian.jones@example.com",
//         profilePicture: "https://picsum.photos/200/300",
//       },
//       {
//         name: "Julia Kim",
//         email: "julia.kim@example.com",
//         profilePicture: "https://picsum.photos/200/300",
//       },
//       {
//         name: "Kelly Lee",
//         email: "kelly.lee@example.com",
//         profilePicture: "https://picsum.photos/200/300",
//       },
//       {
//         name: "Liam Moore",
//         email: "liam.moore@example.com",
//         profilePicture: "https://picsum.photos/200/300",
//       },
//     ],
//     attachments: [
//       {
//         type: "Google Slides",
//         link: "https://docs.google.com/presentation/d/ghi789",
//       },
//     ],
//     description: "Product team meeting to review and demo the latest prototype for the upcoming feature release.",
//     summary: `This Product Demo meeting, scheduled for 12:00 PM and organized by Ian Jones, marks a significant milestone in the development of a new feature. The primary purpose of this gathering is to showcase and review the latest prototype, which has just been completed by Julia Kim.

// The meeting will center around Julia's demonstration of the prototype, likely showcasing the functionality, user interface, and any innovative aspects of the upcoming feature. This presentation is crucial for gathering feedback, identifying potential improvements, and aligning the team on the next steps in the development process.

// A Google Slides presentation is attached to the meeting, which may contain supplementary information about the prototype, such as technical specifications, user stories, or performance metrics. All participants should review this document prior to the meeting to ensure a productive discussion.

// This demo is a critical step in the product development cycle, serving to validate the work done so far and guide the direction of future efforts. It's an opportunity for the team to collectively ensure that the new feature meets both technical requirements and user needs before moving forward with the release process.`,
//   },
// ];
