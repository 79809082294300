import React from 'react';
import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { toTimeAgo } from "@attrove/attrove-ui/lib/utils";
import {
  getIntegrationsWithCalendarCounts,
  updateCalendar,
} from "@attrove/service-supabase";
import { Checkbox, Icons, ScrollArea } from "@attrove/ui-shadcn";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Loader2, Calendar as CalendarIcon } from "lucide-react";
import { IntegrationActions } from "../integration-actions";

export const GET_INTEGRATION_WITH_CALENDAR_COUNTS = "getIntegrationWithCalendarCounts";

interface CalendarItemProps {
  calendar: any;
  onToggle: (checked: boolean) => void;
}

const CalendarItem: React.FC<CalendarItemProps> = ({ calendar, onToggle }) => (
  <div className="group grid grid-cols-[auto_1fr] gap-4 items-start p-4 bg-card rounded-lg border  max-w-7xl mx-auto transition-all duration-200 hover:bg-accent/5 hover:shadow-sm">
    <Checkbox
      id={`calendar-${calendar.id}`}
      checked={calendar.active}
      onCheckedChange={onToggle}
      className="mt-1"
    />
    <div className="min-w-0">
      <div className="flex items-center gap-3 mb-2">
        <div className="flex-shrink-0 p-2 rounded-full bg-primary/10">
          <CalendarIcon className="w-4 h-4 text-primary" />
        </div>
        <div>
          <h3 className="font-semibold text-foreground group-hover:text-primary transition-colors">
            {calendar.title}
          </h3>
          <p className="text-sm text-muted-foreground">
            {calendar.event_count} events
          </p>
        </div>
      </div>
      {calendar.description && (
        <p className="text-sm text-muted-foreground">
          {calendar.description}
        </p>
      )}
    </div>
  </div>
);

export const CalendarDetail: React.FC<{ integrationId: number }> = ({ integrationId }) => {
  const { data: integration, isLoading, error } = useQuery({
    queryKey: [GET_INTEGRATION_WITH_CALENDAR_COUNTS, integrationId],
    queryFn: () => getIntegrationsWithCalendarCounts(supabase, integrationId),
  });

  const toggleActiveCalendar = useMutation({
    mutationFn: async ({ calendarId, active }: { calendarId: number; active: boolean }) => {
      return await updateCalendar(supabase, calendarId, { active });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ 
        queryKey: [GET_INTEGRATION_WITH_CALENDAR_COUNTS, integrationId] 
      });
    },
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-64 text-destructive">
        Error loading calendar details. Please try again.
      </div>
    );
  }

  if (!integration?.calendars) {
    return (
      <div className="flex items-center justify-center h-64 text-muted-foreground">
        No calendar data available.
      </div>
    );
  }

  function removeCalendars<T extends { calendars?: any }>(obj: T): Omit<T, "calendars"> {
    const { calendars, ...rest } = obj;
    return rest;
  }

  const integrationWithoutCals = removeCalendars(integration);
  const Gcal = Icons["google_calendar"];

  return (
    <div className="pt-6 flex flex-col">
      <div className="mx-auto w-full px-4 sm:px-6 md:px-8">
        <div className="flex gap-2 items-center">
          <Gcal className="w-8 h-8" />
          <h1 className="text-2xl font-semibold">Google Calendar</h1>
        </div>

        <p className="text-muted-foreground mt-6 mb-4">
          We pull your calendar events and provide you with the most relevant information for your meetings.
        </p>

        <div className="flex flex-col gap-4 rounded-lg border p-4 relative bg-card max-w-7xl mx-auto">
          <div className="absolute top-4 right-4">
            {integration.is_active ? (
              <div className="flex items-center gap-2">
                <span className="inline-block bg-green-500 h-2.5 w-2.5 rounded-full" />
                <span className="text-sm text-muted-foreground">Active</span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <span className="inline-block bg-red-500 h-2.5 w-2.5 rounded-full" />
                <span className="text-sm text-muted-foreground">Inactive</span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <h2 className="text-xl font-semibold">Integration</h2>
            {integration.last_synced_at && (
              <div className="flex gap-1 text-sm text-muted-foreground">
                <p>Last sync: </p>
                {integration.pending_sync ? (
                  <div className="flex items-center gap-2">
                    <p>currently syncing</p>
                    <Loader2 className="w-4 h-4 animate-spin" />
                  </div>
                ) : (
                  <p>{toTimeAgo(integration.last_synced_at)}</p>
                )}
              </div>
            )}
          </div>
          <p className="text-sm text-muted-foreground">
          {integration.total_events.toLocaleString()} {integration.total_events === 1 ? 'current & upcoming event' : 'current & upcoming events'}
          </p>
          <IntegrationActions integration={integrationWithoutCals} />
        </div>

        <div className="mt-8 flex-1 flex flex-col min-h-0">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold">Connected Calendars</h2>
            <span className="text-sm text-muted-foreground">
              {integration.calendars.filter(cal => cal.active).length} of {integration.calendars.length} enabled
            </span>
          </div>
          <ScrollArea className="flex-1 min-h-0">
            <div className="space-y-3">
              {integration.calendars.length > 0 ? (
                integration.calendars.map((calendar) => (
                  <CalendarItem
                    key={calendar.id}
                    calendar={calendar}
                    onToggle={(checked) => 
                      toggleActiveCalendar.mutate({ 
                        calendarId: calendar.id, 
                        active: checked 
                      })
                    }
                  />
                ))
              ) : (
                <div className="flex flex-col items-center justify-center h-32 text-muted-foreground">
                  <CalendarIcon className="w-8 h-8 mb-2" />
                  <p>No calendars connected.</p>
                </div>
              )}
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};