import { Input, Label } from "@attrove/ui-shadcn";
import { memo, useEffect, useRef, useCallback } from "react";
import { useDebounce } from "use-debounce";
import { Loader2 } from "lucide-react";

interface DebounceInputProps {
  value: string;
  setValue: (value: string) => void;
  onChange: (value: string) => void;
  onBlur?: () => void;
  label: string;
  placeholder: string;
  isLoading?: boolean;
  disabled?: boolean;
}

export const SettingsDebounceInput = memo(({ 
  value,
  setValue, 
  onChange, 
  onBlur,
  label, 
  placeholder,
  isLoading,
  disabled
}: DebounceInputProps) => {
  const [debouncedValue] = useDebounce(value, 1000);
  const previousValue = useRef(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const wasFocused = useRef(false);

  // Track focus state
  const handleFocus = useCallback(() => {
    wasFocused.current = true;
  }, []);

  const handleBlur = useCallback(() => {
    wasFocused.current = false;
    onBlur?.();
  }, [onBlur]);

  useEffect(() => {
    // Only trigger save if the value has actually changed
    if (debouncedValue !== previousValue.current) {
      previousValue.current = debouncedValue;
      onChange(debouncedValue);
    }
  }, [debouncedValue, onChange]);

  // Restore focus after loading state changes
  useEffect(() => {
    if (!isLoading && wasFocused.current && inputRef.current) {
      inputRef.current.focus();
      
      // Restore cursor position to end
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
    }
  }, [isLoading]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Label>{label}</Label>
        {isLoading && (
          <div className="flex items-center gap-2 text-xs text-muted-foreground">
            <Loader2 className="h-3 w-3 animate-spin" />
            <span>Saving...</span>
          </div>
        )}
      </div>
      <Input 
        ref={inputRef}
        placeholder={placeholder} 
        value={value} 
        onChange={(e) => setValue(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        className="pr-8"
      />
    </div>
  );
});
