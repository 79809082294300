import * as React from "react";

type ContentLayoutProps = {
  children: React.ReactNode;
  title?: string;
  header?: React.ReactNode;
  isLoading?: boolean;
  className?: string;
};

export const ContentLayout = ({ children, title, header, isLoading }: ContentLayoutProps) => {
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">{header ? header : title && <h1 className="text-2xl">{title}</h1>}</div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">{children}</div>
    </div>
  );
};
