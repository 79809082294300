import { supabase } from "@attrove/attrove-ui/app/supabase";
import { IntegrationCard } from "@attrove/attrove-ui/features/integrations/integration-card";
import { INTEGRATIONS_QUERY_KEY, getIntegrations } from "@attrove/service-supabase";
import { Button, Card, Skeleton } from "@attrove/ui-shadcn";
import { useQuery } from "@tanstack/react-query";
import { Unplug } from "lucide-react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Integrations() {
  const {
    data: integrations,
    isLoading,
    isError,
    error,
  } = useQuery({ queryKey: [INTEGRATIONS_QUERY_KEY], queryFn: () => getIntegrations(supabase) });

  useEffect(() => {
    async function getSession() {
      const { data, error } = await supabase.auth.getSession();
      // console.log("session: inter", data);
    }
    getSession();
  }, []);

  if (isLoading)
    return (
      <div className="p-4 gap-4 flex flex-col text-2xl">
        <h1>Integrations</h1>
        <div className="grid lg:grid-cols-3 gap-4 grid-cols-1">
          <IntegrationSkeleton />
          <IntegrationSkeleton />
        </div>
      </div>
    );

  if (isError)
    return (
      <div className="p-4">
        <h1>Integrations</h1>
        <div>Error: {error?.message}</div>
      </div>
    );

  if (!integrations || integrations.length === 0) {
    return (
      <div className="p-4">
        <h1>Integrations</h1>
        <div className="w-full flex flex-col items-center justify-center h-full mt-4">
          <Unplug className="w-20 h-20 mb-4 text-muted-foreground" />
          <p className="text-xl mb-2">You have no integrations connected yet</p>
          <p className="text-sm">Click the top right button above to connect your first integration</p>
          <Link className="mt-4" to="/integrations/new">
            <Button variant="default">Add Integration</Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex justify-items-center mb-2">
        <h1 className="text-2xl ">Integrations</h1>
        <Link className="ml-auto" to="/integrations/new">
          <Button variant="default">Add Integration</Button>
        </Link>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
        {integrations.map((integration) => (
          <IntegrationCard key={integration.id} integration={integration} />
        ))}
      </div>
    </div>
  );
}

const IntegrationSkeleton = () => {
  return (
    <Card className="flex items-center p-4 h-24 space-x-4 w-full">
      <Skeleton className="h-12 w-12 shrink-0 rounded-full" />
      <div className="space-y-2 w-full">
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-3/4" />
      </div>
    </Card>
  );
};
