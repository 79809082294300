import { OnboardingInput } from "./onboarding-input";

type InputProps = {
  value: string;
  onChange: (value: string) => void;
};

export const OnboardingFullNameInput: React.FC<InputProps> = ({ 
  value, 
  onChange
}) => {
  return (
    <OnboardingInput
      value={value}
      onChange={onChange}
      label="What's your full name?"
      placeholder="John Doe"
    />
  );
};

export const OnboardingPreferredNameInput: React.FC<InputProps> = ({ 
  value, 
  onChange
}) => {
  return (
    <OnboardingInput
      value={value}
      onChange={onChange}
      label="What should we call you?"
      placeholder="John"
    />
  );
}; 