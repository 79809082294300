import React from 'react';
import { TopEntity, PeopleOptionsType } from "@attrove/attrove-ui/app/pages/dashboard/people";
import { Badge } from "@attrove/ui-shadcn";
import { X } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { Table } from "@tanstack/react-table";

interface RenderTagsProps {
  options: PeopleOptionsType;
  urlParam: string;
  title: string;
  table: Table<TopEntity>;
}

export const RenderTags: React.FC<RenderTagsProps> = ({ options, urlParam, title, table }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = searchParams.getAll(urlParam);
  const specificOptions = options[urlParam as keyof PeopleOptionsType] || [];

  const handleFilterRemove = (filterToRemove: string) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      const currentFilters = newParams.getAll(urlParam);
      newParams.delete(urlParam);
      const updatedFilters = currentFilters.filter(filter => filter !== filterToRemove);
      updatedFilters.forEach(filter => newParams.append(urlParam, filter));

      // Update the table filter
      const column = table.getColumn("top_tags");
      if (column) {
        column.setFilterValue(updatedFilters);
      }

      return newParams;
    });
  };

  if (filters.length === 0) return null;

  return (
    <div className="flex gap-2 items-center flex-wrap">
      {filters.map((filter) => (
        <Badge
          key={filter}
          variant="secondary"
          className="rounded-sm px-2 font-normal flex gap-2 hover:cursor-pointer"
          onClick={() => handleFilterRemove(filter)}
        >
          <p>{title}:</p>
          {specificOptions.find((option) => option.id === filter)?.value || filter}
          <X className="h-4 w-4" />
        </Badge>
      ))}
    </div>
  );
};