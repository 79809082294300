import { useContext, useState, useEffect } from "react";
import { 
  Alert, 
  AlertDescription, 
  Button, 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle, 
  Progress, 
  useToast 
} from "@attrove/ui-shadcn";
import { formatStripeDate } from "@attrove/util-formatting";
import { CreditCard, Loader2, RefreshCw, CheckCircle } from "lucide-react";
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { useSubscription } from "../../../../hooks/useSubscription";
import type { SubscriptionTier } from '@attrove/service-supabase';
import axios, { AxiosError } from 'axios';

type TierFeatures = {
  features: string[];
  cta: {
    text: string;
    variant: "default" | "outline";
  };
};

const TIER_FEATURES: Record<SubscriptionTier, TierFeatures> = {
  free: {
    features: [
      "Sync up to 5 communication channels",
      "Basic integrations only",
      "Daily rundown generation",
      "Email support (response within 48 hours)",
    ],
    cta: {
      text: "Upgrade to Pro",
      variant: "default"
    }
  },
  pro: {
    features: [
      "Connect unlimited integrations",
      "Priority email support (response within 24 hours)",
      "Access to premium integrations",
      "Team collaboration features",
    ],
    cta: {
      text: "Manage Subscription",
      variant: "outline"
    }
  }
} as const;

export const Billing: React.FC = () => {
  const { 
    subscription,
    usage,
    isLoading: isSubscriptionLoading,
    isPro,
    refetch: refetchSubscription,
    currentTier
  } = useSubscription();
  
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [upgradeStatus, setUpgradeStatus] = useState<'success' | 'cancelled' | null>(null);
  const { toast } = useToast();
  const axiosClient = useContext(AxiosClientContext);

  // Check URL parameters for upgrade status
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const upgrade = params.get('upgrade');
    if (upgrade === 'success' || upgrade === 'cancelled') {
      setUpgradeStatus(upgrade);
      window.history.replaceState({}, '', window.location.pathname);
      
      if (upgrade === 'success') {
        toast({
          title: "Upgrade Successful!",
          description: "Welcome to Pro! Your new features are now available.",
          duration: 5000,
        });
        refetchSubscription();
      }
    }
  }, [toast, refetchSubscription]);

  const handleBillingAccess = async () => {
    try {
      setIsActionLoading(true);
      const response = await axiosClient.get<{ url: string }>("/api/stripe/billing-session");
      
      if (response.data?.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      const axiosError = error as AxiosError<{ error: string }>;
      const errorMessage = axiosError.response?.data?.error || "Failed to access billing";
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsActionLoading(false);
    }
  };

  if (isSubscriptionLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  // Show success message if upgrade just completed
  if (upgradeStatus === 'success') {
    return (
      <Card className="max-w-2xl mx-auto mt-8">
        <CardHeader>
          <div className="flex items-center space-x-2">
            <CheckCircle className="h-6 w-6 text-green-500" />
            <CardTitle>Welcome to Pro!</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <p className="text-lg">Your upgrade was successful! You now have access to:</p>
            <ul className="list-disc pl-5 space-y-2 text-sm text-muted-foreground">
              {TIER_FEATURES.pro.features.map((feature: string, index: number) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <Button 
              onClick={() => setUpgradeStatus(null)}
              className="mt-4"
            >
              View Billing Dashboard
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  // Main billing content - now handles both subscription and no-subscription cases
  const tierFeatures = TIER_FEATURES[currentTier];
  const usagePercentage = usage ? Math.min((usage.used / usage.limit) * 100, 100) : 0;

  return (
    <div className="flex flex-col gap-4">
      <div className="max-w-2xl w-full">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-xl font-bold">Billing</h1>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={() => refetchSubscription()}
            disabled={isSubscriptionLoading}
          >
            <RefreshCw className={`h-4 w-4 mr-2 ${isSubscriptionLoading ? 'animate-spin' : ''}`} />
            Refresh
          </Button>
        </div>
      </div>

      <Card className="max-w-2xl">
        <CardHeader>
          <CardTitle>{isPro ? "Pro Plan" : "Free Plan"}</CardTitle>
          {!subscription && (
            <Alert>
              <AlertDescription>
                You're currently on our free tier. Upgrade to Pro to unlock all features!
              </AlertDescription>
            </Alert>
          )}
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <ul className="list-disc pl-5 space-y-2 text-sm text-muted-foreground">
              {tierFeatures.features.map((feature: string, index: number) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>

            {subscription?.prices?.unit_amount && (
              <div className="text-sm text-muted-foreground">
                <p>
                  ${(subscription.prices.unit_amount / 100).toFixed(2)} / {subscription.prices.interval}
                  {subscription.prices.interval_count && subscription.prices.interval_count > 1 && 
                    ` (${subscription.prices.interval_count} ${subscription.prices.interval}s)`
                  }
                </p>
              </div>
            )}

            {subscription?.cancelAtPeriodEnd && (
              <Alert>
                <AlertDescription>
                  Your subscription will be canceled on {formatStripeDate(subscription.currentPeriodEnd)}
                </AlertDescription>
              </Alert>
            )}

            <div className="flex justify-end">
              <Button
                onClick={handleBillingAccess}
                variant={tierFeatures.cta.variant}
                disabled={isActionLoading}
              >
                {isActionLoading ? (
                  <Loader2 className="h-4 w-4 animate-spin mr-2" />
                ) : isPro ? (
                  <CreditCard className="h-4 w-4 mr-2" />
                ) : null}
                {tierFeatures.cta.text}
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      {usage && (
        <Card className="max-w-2xl">
          <CardHeader>
            <CardTitle>Usage</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex justify-between text-sm text-muted-foreground">
                <span>
                  {usage.used} / {usage.limit} messages
                </span>
                <span>Resets on {formatStripeDate(usage.periodEnd)}</span>
              </div>
              <Progress value={usagePercentage} />
              {usagePercentage > 80 && !isPro && (
                <Alert>
                  <AlertDescription>
                    You're approaching your message limit. Consider upgrading to our Pro plan for unlimited messages.
                  </AlertDescription>
                </Alert>
              )}
            </div>
          </CardContent>
        </Card>
      )}

      {isPro && (
        <Card className="max-w-2xl">
          <CardHeader>
            <CardTitle>Payment Method</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex justify-between items-center">
              <p className="text-sm text-muted-foreground">
                Update your payment method through our secure payment portal
              </p>
              <Button 
                onClick={handleBillingAccess}
                variant="outline"
                disabled={isActionLoading}
              >
                <CreditCard className="h-4 w-4 mr-2" />
                Manage Payment
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Billing;