import { Card, CardContent, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, useTheme } from "@attrove/ui-shadcn";
import { Laptop, Moon, Sun } from "lucide-react";

export const ThemeDropdown = () => {
  const { setTheme, theme } = useTheme();

  return (
    <Card className="max-w-2xl">
      <CardContent className="p-6">
        <div className="flex flex-col gap-4">
          <p className="text-sm text-muted-foreground">Set theme</p>

          <Select value={theme} onValueChange={setTheme}>
            <SelectTrigger>
              <SelectValue placeholder="Select a theme" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="system">
                <div className="flex items-center gap-2">
                  <Laptop className="h-4 w-4" />
                  <span>System</span>
                </div>
              </SelectItem>
              <SelectItem value="light">
                <div className="flex items-center gap-2">
                  <Sun className="h-4 w-4" />
                  <span>Light</span>
                </div>
              </SelectItem>
              <SelectItem value="dark">
                <div className="flex items-center gap-2">
                  <Moon className="h-4 w-4" />
                  <span>Dark</span>
                </div>
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
      </CardContent>
    </Card>
  );
};
