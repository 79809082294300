import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from "@attrove/ui-shadcn";
import { LoadingButton } from "@attrove/ui-shadcn";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import { Oauth } from "@attrove/attrove-ui/features/auth/oauth-button";
import { supabase } from "../../supabase";

// Define prop type with allowEmail boolean
interface SignUpProps {
  allowEmail: boolean;
  redirectMethod: string;
}

const signupSchema = z.object({
  email: z.string().email({
    message: "Invalid email address",
  }),
  password: z.string().min(8, {
    message: "Password must be at least 8 characters",
  }),
});

const SIGN_UP_DEFAULT_VALUES = {
  email: "",
  password: "",
};

export default function SignUp() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (data: { email: string; password: string }) => {
    setIsSubmitting(true);
    try {
      const { error: signUpError, data: authData } = await supabase.auth.signUp({
        email: data.email,
        password: data.password
      });
      
      if (signUpError) {
        console.error('Signup error:', signUpError.message);
        return;
      }

      navigate('/dashboard');
    } catch (err) {
      console.error('Unexpected error:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const form = useForm({
    resolver: zodResolver(signupSchema),
    defaultValues: SIGN_UP_DEFAULT_VALUES,
  });

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-xl  font-semibold md:text-2xl">Sign up</h2>
      <Form {...form}>
        <form noValidate={true} onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-muted-foreground">Email</FormLabel>
                    <FormControl>
                      <Input autoCapitalize="none" autoComplete="email" autoCorrect="off" placeholder="m@example.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex items-center">
                      <FormLabel className="text-muted-foreground">Password</FormLabel>
                    </div>
                    <FormControl>
                      <Input autoCapitalize="none" autoComplete="password" autoCorrect="off" placeholder="Password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <LoadingButton loading={isSubmitting} disabled={isSubmitting} variant="default">
              Sign up
            </LoadingButton>
          </div>
        </form>
      </Form>
      <div className="flex items-center gap-2">
        <hr className="w-full" />
        <p className="text-xs text-muted-foreground">OR</p>
        <hr className="w-full" />
      </div>
      <Oauth />
      <div className="flex flex-col justify-center gap-2 font-light text-muted-foreground underline text-center w-full text-sm">
        <Link to="/password_signin">Sign in with email and password</Link>
        {/* <Link to="/email_signin">Sign in via magic link</Link> */}
      </div>
      <div className="text-sm text-muted-foreground text-center mt-4">
        <p className="inline">
          By signing up you agree to Attrove's{" "}
          <Link to="https://attrove.com/terms" className="text-primary hover:underline">
            Terms of Service
          </Link>{" "}
          and acknowledge that Attrove's{" "}
          <Link to="https://attrove.com/privacy" className="text-primary hover:underline">
            Privacy Policy
          </Link>{" "}
          applies to you.
        </p>
      </div>
    </div>
  );
}
