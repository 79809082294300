import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { z } from "zod";

import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { GET_USER_QUERY_KEY, updateUser } from "@attrove/service-supabase";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input, LoadingButton } from "@attrove/ui-shadcn";
import { useMutation } from "@tanstack/react-query";
import { Eye, EyeOff } from "lucide-react";
import { queryClient } from "../../react-query";
import { supabase } from "../../supabase";
import { axiosClient } from "@attrove/attrove-ui/main";

const passwordSignInSchema = z.object({
  password: z.string().min(8, {
    message: "Password must be at least 8 characters",
  }),
});

const PASSWORD_SIGN_IN_DEFAULT_VALUES = {
  password: "",
};

export default function CompleteAuth() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useSession();

  const form = useForm({
    resolver: zodResolver(passwordSignInSchema),
    defaultValues: PASSWORD_SIGN_IN_DEFAULT_VALUES,
  });

  const setAuthCompleted = useMutation({
    mutationFn: async () => {
      return await updateUser(supabase, user.id, { auth_completed: true });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_USER_QUERY_KEY] });
      axiosClient.post("/api/stripe/customers");
      navigate("/reports");
    },
  });

  const handleSubmit = async (data: { password: string }) => {
    setIsSubmitting(true);

    const { data: newUser, error } = await supabase.auth.updateUser({
      password: data.password,
    });

    if (error) {
      console.log(error);
    }

    setAuthCompleted.mutate();

    setIsSubmitting(false);
    navigate("/");
  };

  if (user.auth_completed) return <Navigate to="/reports" replace />;

  return (
    <div className="flex justify-center items-center min-h-screen bg-background px-4">
      <div className="w-full max-w-md">
        <div className="bg-card shadow-md rounded-lg p-6 space-y-6">
          <h2 className="text-2xl font-semibold text-center">Complete Sign Up</h2>
          <Form {...form}>
            <form noValidate={true} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-muted-foreground">Password</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          autoCapitalize="none"
                          autoComplete="new-password"
                          type={showPassword ? "text" : "password"}
                          autoCorrect="off"
                          placeholder="Enter your password"
                          {...field}
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute right-2 top-1/2 -translate-y-1/2"
                        >
                          {showPassword ? (
                            <EyeOff className="text-muted-foreground h-4 w-4" />
                          ) : (
                            <Eye className="text-muted-foreground h-4 w-4" />
                          )}
                        </button>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <LoadingButton loading={isSubmitting} disabled={isSubmitting} className="w-full">
                Add Password
              </LoadingButton>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}