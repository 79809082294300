import { columns } from "./columns";
import { ActionItemsTable } from "./table";
import { toast } from "@attrove/ui-shadcn";

enum Priority {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

export type ActionItem = {
  dueDate: string;
  priority: Priority;
  task: string;
};

export const ActionItems: React.FC<{ actionItems: ActionItem[] }> = ({ actionItems }) => {
  return actionItems.length > 0 && <ActionItemsTable data={actionItems} columns={columns} />;
};