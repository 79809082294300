import React, { useState } from "react";
import { ContentLayout } from "@attrove/attrove-ui/components/layouts/content-layout";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { ActionItems } from "@attrove/attrove-ui/features/reports/action-items-table";
import { Audio } from "@attrove/attrove-ui/features/reports/audio";
import { toFullDate, toTime, parseTag } from "@attrove/attrove-ui/lib/utils";
import { GET_REPORTS_QUERY_KEY, getReports, getReportTopics } from "@attrove/service-supabase";
import {
  cn,
  Badge,
  Button,
  Card,
  Progress,
  ScrollArea,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@attrove/ui-shadcn";
import { useQuery } from "@tanstack/react-query";
import { ArrowLeft, Calendar, CheckCircle2, MessageCircle, ChevronRight, ChevronDown, Clock, FileText, Hash, CheckSquare } from "lucide-react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { supabase } from "../../supabase";
import { format, formatDistanceToNow, differenceInDays } from "date-fns";
import { Icons } from "@attrove/ui-shadcn";

const LoadingState = () => (
  <ContentLayout>
    <div className="flex flex-col items-center justify-center h-screen gap-4">
      <div className="w-64">
        <Progress className="h-2 bg-accent/20" value={null} />
      </div>
      <p className="text-muted-foreground">Loading reports...</p>
    </div>
  </ContentLayout>
);

// Helper to group reports by month
const groupReportsByMonth = (reports) => {
  return reports.reduce((acc, report) => {
    const date = new Date(report.created_at);
    const monthKey = `${date.getFullYear()}-${date.getMonth()}`;
    if (!acc[monthKey]) {
      acc[monthKey] = {
        title: date.toLocaleString("default", { month: "long", year: "numeric" }), // Removed year
        year: date.getFullYear(),
        reports: [],
      };
    }
    acc[monthKey].reports.push(report);
    return acc;
  }, {});
};

const formatDate = (date: Date): { display: string; isRecent: boolean } => {
  const now = new Date();
  const diffDays = differenceInDays(now, date);

  if (diffDays === 0) {
    return {
      display: formatDistanceToNow(date, { addSuffix: true }),
      isRecent: true,
    };
  } else if (diffDays === 1) {
    return {
      display: "Yesterday",
      isRecent: true,
    };
  } else if (diffDays < 7) {
    return {
      display: format(date, "EEEE"), // Full day name
      isRecent: true,
    };
  } else {
    return {
      display: format(date, "MMMM d"), // Month and day for older dates
      isRecent: false,
    };
  }
};

// Timeline section component
const TimelineSection = ({ title, year, reports, selectedReport, setSearchParams, isExpanded, onToggle }) => {
  return (
    <div className="mb-2">
      <button
        onClick={onToggle}
        className="w-full flex items-center justify-between p-2 hover:bg-accent/10 rounded-lg text-sm transition-all duration-200 group"
      >
        <div className="flex items-center gap-2">
          {isExpanded ? <ChevronDown className="h-4 w-4 text-primary" /> : <ChevronRight className="h-4 w-4 text-primary" />}
          <span className="font-semibold text-foreground">{title}</span>
        </div>
        <span className="text-xs text-muted-foreground font-medium group-hover:text-accent transition-colors">{year}</span>
      </button>
      {isExpanded && (
        <div className="space-y-1 ml-6">
          {reports.map((report) => {
            const date = new Date(report.created_at);
            const { display: dateDisplay, isRecent } = formatDate(date);
            const isToday = differenceInDays(new Date(), date) === 0;
            const isSelected = report.id === selectedReport?.id;

            return (
              <div
                key={report.id}
                onClick={() => setSearchParams(`?reportId=${report.id}`)}
                className={cn(
                  "p-2 rounded-lg transition-all duration-200 cursor-pointer relative group/item",
                  isSelected ? "bg-primary text-accent-foreground shadow-sm" : "hover:bg-accent/10",
                )}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <div
                      className={cn(
                        "text-xs font-medium rounded-md px-2 py-0.5",
                        isSelected
                          ? "bg-white/20 text-white"
                          : isRecent
                            ? isToday
                              ? "bg-accent/20 text-muted-foreground font-semibold"
                              : "bg-muted text-muted-foreground"
                            : "bg-muted/50 text-muted-foreground",
                      )}
                    >
                      {dateDisplay}
                    </div>
                  </div>

                  {/* Show time for today and yesterday */}
                  {isRecent && differenceInDays(new Date(), date) <= 1 && (
                    <span className={cn("text-xs", isSelected ? "text-white/70" : "text-muted-foreground")}>{format(date, "h:mm a")}</span>
                  )}
                </div>

                {!isSelected && (
                  <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-accent/0 to-accent/0 opacity-0 group-hover/item:opacity-100 transition-opacity" />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default function Reports() {
  const { user } = useSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("summary");

  const {
    data: reports,
    isLoading,
    error,
  } = useQuery({
    queryKey: [GET_REPORTS_QUERY_KEY],
    queryFn: () => getReports(supabase, user.id),
    retry: 1,
  });

  const reportId = searchParams.get("reportId") || "latest";

  const [expandedMonths, setExpandedMonths] = useState(() => {
    // Default to showing current month
    const currentDate = new Date();
    return { [`${currentDate.getFullYear()}-${currentDate.getMonth()}`]: true };
  });

  if (isLoading) {
    return <LoadingState />;
  }

  if (error || !reports?.length) {
    return (
      <ContentLayout>
        <div className="flex flex-col items-center justify-center h-screen gap-4">
          <p className="text-lg text-muted-foreground">{error ? "Unable to load reports" : "No reports found"}</p>
          <Button onClick={() => navigate("/")}>Return Home</Button>
        </div>
      </ContentLayout>
    );
  }

  const selectedReport = reportId === "latest" ? reports[0] : (reports.find((r) => String(r.id) === reportId) ?? reports[0]);
  
  // Content is already parsed in getReports, no need to parse again
  const actionItemsCount = selectedReport.content.keyActionItems?.length || 0;
  const showReportList = !reportId || reportId === "latest";

  const groupedReports = groupReportsByMonth(reports);

  const toggleMonth = (monthKey) => {
    setExpandedMonths((prev) => ({
      ...prev,
      [monthKey]: !prev[monthKey],
    }));
  };

  return (
    <ContentLayout>
      {/* Header Section */}
      <div className="border-b">
        <div className="max-w-7xl mx-auto px-4 py-6">
          {/* Title and Date */}
          <div className="mb-6">
            <div className="flex items-center gap-4">
              {!showReportList && (
                <Button variant="ghost" size="icon" className="lg:hidden" onClick={() => navigate("/reports")}>
                  <ArrowLeft className="h-5 w-5" />
                </Button>
              )}
              <div>
                <h1 className="text-3xl font-bold">Daily Rundown</h1>
                <div className="flex items-center gap-3 mt-2 text-muted-foreground text-sm">
                  <div className="flex items-center gap-1.5">
                    <Calendar className="h-4 w-4 text-primary" />
                    <span>{toFullDate(selectedReport.created_at)}</span>
                  </div>
                  <div className="flex items-center gap-1.5">
                    <Clock className="h-4 w-4 text-primary" />
                    <span>Generated at {toTime(selectedReport.created_at)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Actions Row */}
          <div className="grid lg:grid-cols-4 gap-6">
            <div className="flex-shrink-0">
              <Link to={`/messages?reports=${selectedReport.id}`} className="w-full block">
                <Button variant="outline" className="w-full gap-2 whitespace-nowrap px-4 py-6">
                  <MessageCircle className="h-5 w-5 text-primary" />
                  View Messages
                  <Badge variant="secondary" className="ml-1">
                    {selectedReport.message_count}
                  </Badge>
                </Button>
              </Link>
            </div>
            <div className="lg:col-span-3">
              <Audio key={selectedReport.id} reportId={selectedReport.id} />
            </div>
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="max-w-7xl mx-auto px-4 py-6">
        {/* Mobile view remains the same */}
        <div className="lg:hidden">
          {showReportList ? (
            <MobileReportList reports={reports} setSearchParams={setSearchParams} />
          ) : (
            <ReportContent
              selectedReport={selectedReport}
              actionItemsCount={actionItemsCount}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          )}
        </div>

        {/* Desktop view */}
        <div className="hidden lg:grid lg:grid-cols-4 gap-6">
          {/* Timeline */}
          <Card className="h-[calc(100vh-348px)] lg:col-span-1">
            <div className="p-4 border-b">
              <h2 className="text-lg font-semibold">Timeline</h2>
            </div>
            <ScrollArea className="h-[calc(100vh-412px)]">
              <div className="p-2">
                {Object.entries(groupedReports).map(([monthKey, { title, reports }]) => (
                  <TimelineSection
                    key={monthKey}
                    title={title}
                    reports={reports}
                    selectedReport={selectedReport}
                    setSearchParams={setSearchParams}
                    isExpanded={expandedMonths[monthKey]}
                    onToggle={() => toggleMonth(monthKey)}
                  />
                ))}
              </div>
            </ScrollArea>
          </Card>

          {/* Main Content */}
          <div className="lg:col-span-3">
            <Tabs value={activeTab} onValueChange={setActiveTab}>
              <TabsList className="grid w-full grid-cols-3">
                <TabsTrigger value="summary" className="truncate">
                  <div className="flex items-center gap-2">
                    <FileText className="h-4 w-4" />
                    Summary
                  </div>
                </TabsTrigger>
                <TabsTrigger value="topics" className="truncate">
                  <div className="flex items-center gap-2">
                    <Hash className="h-4 w-4" />
                    Key Topics
                  </div>
                </TabsTrigger>
                <TabsTrigger value="actions" disabled={actionItemsCount === 0} className="truncate">
                  <div className="flex items-center gap-2">
                    <CheckSquare className="h-4 w-4" />
                    Action Items
                    {actionItemsCount > 0 && (
                      <Badge variant="secondary" className="ml-1">
                        {actionItemsCount}
                      </Badge>
                    )}
                  </div>
                </TabsTrigger>
              </TabsList>

              <div className="mt-4">
                <TabsContent value="summary" className="m-0">
                  <ReportSummary report={selectedReport} />
                </TabsContent>

                <TabsContent value="topics" className="m-0">
                  <ReportTopics reportId={selectedReport.id} />
                </TabsContent>

                <TabsContent value="actions" className="m-0">
                  <ReportActions report={selectedReport} />
                </TabsContent>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
}

const MobileReportList = ({ reports, setSearchParams }) => {
  return (
    <div className="space-y-4 py-4">
      {reports.map((report, index) => {
        const isToday = new Date(report.created_at).toDateString() === new Date().toDateString();
        const isYesterday = new Date(report.created_at).toDateString() === new Date(Date.now() - 86400000).toDateString();

        let dateDisplay = "";
        if (isToday) dateDisplay = "Today";
        else if (isYesterday) dateDisplay = "Yesterday";
        else dateDisplay = toFullDate(report.created_at);

        const showMonthHeader =
          index === 0 || new Date(report.created_at).getMonth() !== new Date(reports[index - 1].created_at).getMonth();

        return (
          <React.Fragment key={report.id}>
            {showMonthHeader && (
              <div className="text-sm font-semibold text-muted-foreground mt-6 mb-2">
                {new Date(report.created_at).toLocaleString("default", { month: "long", year: "numeric" })}
              </div>
            )}
            <Card
              onClick={() => setSearchParams(`?reportId=${report.id}`)}
              className="p-4 cursor-pointer hover:bg-accent/50 transition-colors"
            >
              <p className="font-medium">{dateDisplay}</p>
            </Card>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const ReportContent = ({ selectedReport, actionItemsCount, activeTab, setActiveTab }) => {
  return (
    <Tabs value={activeTab} onValueChange={setActiveTab} className="h-full flex flex-col">
      <TabsList className="grid w-full grid-cols-3">
        <TabsTrigger value="summary" className="truncate">
          <div className="flex items-center gap-2">
            <FileText className="h-4 w-4" />
            Summary
          </div>
        </TabsTrigger>
        <TabsTrigger value="topics" className="truncate">
          <div className="flex items-center gap-2">
            <Hash className="h-4 w-4" />
            Key Topics
          </div>
        </TabsTrigger>
        <TabsTrigger value="actions" disabled={actionItemsCount === 0} className="truncate">
          <div className="flex items-center gap-2">
            <CheckSquare className="h-4 w-4" />
            Action Items
            {actionItemsCount > 0 && (
              <Badge variant="secondary" className="ml-1">
                {actionItemsCount}
              </Badge>
            )}
          </div>
        </TabsTrigger>
      </TabsList>

      <div className="flex-1 mt-4">
        <TabsContent value="summary" className="h-full m-0">
          <ReportSummary report={selectedReport} />
        </TabsContent>

        <TabsContent value="topics" className="h-full m-0">
          <ReportTopics reportId={selectedReport.id} />
        </TabsContent>

        <TabsContent value="actions" className="h-full m-0">
          <ReportActions report={selectedReport} />
        </TabsContent>
      </div>
    </Tabs>
  );
};

const ReportSummary = ({ report }) => {
  // The content is already parsed, no need for JSON.parse
  const summary = report.content.executiveSummary.summary;

  const IntegrationButton = ({ type, integrationId }) => {
    const Icon = Icons[type.toLowerCase()];
    return (
      <div
        className={cn(
          "flex items-center justify-center p-1 rounded-lg border transition-all duration-200 cursor-pointer",
          "bg-brand-primary/5 border-brand-primary/20",
          "hover:bg-brand-primary/10 hover:border-brand-primary/30 group"
        )}
      >
        <Link to={`/messages?integrations=${integrationId}`}>
          <div className="group-hover:text-brand-primary transition-colors">
            {Icon && <Icon className="w-4 h-4" />}
          </div>
        </Link>
      </div>
    );
  };

  const ActionButton = ({ ids = [], type, icon }: { ids: string[]; type: "messages" | "events"; icon: React.ReactNode }) => {
    const validIds = (ids || []).filter((id) => id && id !== "undefined");
    const queryParam = type === "messages" ? "message" : "event";

    // Base button component
    const ButtonContent = () => (
      <Button variant="outline" size="sm" disabled={validIds.length === 0} className="min-w-[72px] w-full">
        {icon}
        <span className="ml-2">{validIds.length}</span>
      </Button>
    );

    // If it's a messages button with valid IDs, wrap in tooltip
    if (type === "messages" && validIds.length > 0) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Link to={`/${type}?${queryParam}=${validIds.join(",")}`}>
                <ButtonContent />
              </Link>
            </TooltipTrigger>
            <TooltipContent side="left">
              <p className="text-xs">View source messages</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    // For events or disabled messages buttons, return without tooltip
    return validIds.length > 0 ? (
      <Link to={`/${type}?${queryParam}=${validIds.join(",")}`}>
        <ButtonContent />
      </Link>
    ) : (
      <ButtonContent />
    );
  };

  return (
    <Card className="relative overflow-hidden lg:h-[calc(100vh-400px)]">
      <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-accent to-accent/30" />
      <div className="p-6 border-b">
        <h3 className="text-xl font-semibold">Executive Summary</h3>
      </div>
      <ScrollArea className="lg:h-[calc(100vh-480px)]">
        <div className="divide-y divide-border">
          {summary.map((item, index) => {
            const validMessageIds = (item.messageIds || []).filter((id) => id && id !== "undefined");
            const validEventIds = (item.eventIds || []).filter((id) => id && id !== "undefined");
            
            // Get unique integrations from messages and events
            const integrationMap = {};
            validMessageIds.forEach(id => {
              const msg = report.messages?.find(m => m.id === Number(id));
              if (msg?.integration) {
                integrationMap[msg.integration.type] = msg.integration.id;
              }
            });
            validEventIds.forEach(id => {
              const event = report.events?.find(e => e.id === Number(id));
              if (event?.calendar?.integration) {
                integrationMap[event.calendar.integration.type] = event.calendar.integration.id;
              }
            });

            return (
              <div key={index} className="flex items-start gap-6 p-4 hover:bg-accent/5 transition-all duration-200">
                <div className="flex flex-col items-center gap-2">
                  <div className="flex-shrink-0 w-6 h-6 bg-primary/10 text-muted-foreground rounded-full flex items-center justify-center font-medium">
                    {index + 1}
                  </div>
                  {Object.keys(integrationMap).length > 0 && (
                    <div className="flex flex-col gap-1">
                      {Object.entries(integrationMap).map(([type, id]) => (
                        <TooltipProvider key={`${type}-${id}`}>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <div>
                                <IntegrationButton type={type} integrationId={id} />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className="text-xs">View all {type.replace('_', ' ')} messages</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      ))}
                    </div>
                  )}
                </div>
                <div className="flex-1">
                  <p className="text-muted-foreground">{item.text}</p>
                </div>
                <div className="flex-shrink-0 flex flex-col gap-2 w-[72px]">
                  <ActionButton ids={validMessageIds} type="messages" icon={<MessageCircle className="h-4 w-4" />} />
                  <ActionButton ids={validEventIds} type="events" icon={<Calendar className="h-4 w-4" />} />
                </div>
              </div>
            );
          })}
        </div>
      </ScrollArea>
    </Card>
  );
};

const ReportTopics = ({ reportId }) => {
  const { data: reportTags, isLoading } = useQuery({
    queryKey: ["reportTags", reportId],
    queryFn: () => getReportTopics(supabase, reportId),
  });

  if (isLoading) {
    return <LoadingState />;
  }

  const TagItem = ({ tag, count }) => (
    <Link to={`/messages?tags=${tag}`}>
      <div className={cn(
        "flex items-center justify-between px-3 py-1.5 rounded-lg border transition-all duration-200 cursor-pointer",
        "bg-brand-primary/5 border-brand-primary/20",
        "hover:bg-brand-primary/10 hover:border-brand-primary/30 group"
      )}>
        <span className={cn(
          "text-sm font-medium transition-colors",
          "group-hover:text-brand-primary"
        )}>
          {parseTag(tag).split(' ').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          ).join(' ')}
        </span>
        {count > 1 && (
          <span className={cn(
            "ml-2 text-xs text-muted-foreground",
            "group-hover:text-brand-primary"
          )}>
            {count} mentions
          </span>
        )}
      </div>
    </Link>
  );

  return (
    <Card className="relative overflow-hidden lg:h-[calc(100vh-400px)]">
      <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-accent to-accent/30" />
      <div className="p-6 border-b">
        <h3 className="text-xl font-semibold">Key Topics</h3>
      </div>
      <ScrollArea className="lg:h-[calc(100vh-500px)]">
        <div className="p-6">
          <div className="grid grid-cols-2 gap-2">
            {reportTags?.topics.map((tag) => (
              <TagItem 
                key={tag} 
                tag={tag}
                count={reportTags.topicFrequency?.[tag] || 1}
              />
            ))}
          </div>
        </div>
      </ScrollArea>
    </Card>
  );
};

const ReportActions = ({ report }) => {
  // Content is already parsed, no need for JSON.parse
  const actionItems = report.content.keyActionItems || [];

  return (
    <Card className="relative overflow-hidden lg:h-[calc(100vh-400px)]">
      <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-accent to-accent/30" />
      <div className="p-6 border-b">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-semibold">Action Items</h3>
          <Badge variant="secondary" className="gap-2">
            <CheckCircle2 className="h-4 w-4 text-accent" />
            {actionItems.length} items
          </Badge>
        </div>
      </div>
      <ScrollArea className="lg:h-[calc(100vh-500px)]">
        <div className="p-6">
          <ActionItems actionItems={actionItems} />
        </div>
      </ScrollArea>
    </Card>
  );
};
