import { parseTag } from "@attrove/attrove-ui/lib/utils";
import type { EnhancedMessage } from "@attrove/service-supabase";
import { Icons } from "@attrove/ui-shadcn";
import { useSearchParams } from "react-router-dom";
import { cn } from "@attrove/ui-shadcn";

const Gmail = Icons.gmail;
const Outlook = Icons.outlook;
const Slack = Icons.slack;

// Utility function to decode HTML entities
const decodeHtml = (html: string): string => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

interface DetailsColumnProps {
  message: EnhancedMessage;
  onClick?: () => void;
}

const TagItem = ({ tag, tagId, onClick }) => (
  <div
    onClick={onClick}
    className={cn(
      "flex items-center p-2 rounded-lg border transition-all duration-200 cursor-pointer",
      "bg-brand-primary/5 border-brand-primary/20",
      "hover:bg-brand-primary/10 hover:border-brand-primary/30 group"
    )}
  >
    <span className={cn(
      "text-xs font-medium transition-colors",
      "group-hover:text-brand-primary"
    )}>
      {parseTag(tag).split(' ').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ).join(' ')}
    </span>
  </div>
);

const IntegrationButton = ({ onClick, children }) => (
  <div
    onClick={onClick}
    className={cn(
      "flex items-center justify-center px-2 py-1 rounded-lg border transition-all duration-200 cursor-pointer",
      "bg-brand-primary/5 border-brand-primary/20",
      "hover:bg-brand-primary/10 hover:border-brand-primary/30 group"
    )}
  >
    <div className="group-hover:text-brand-primary transition-colors">
      {children}
    </div>
  </div>
);

export const DetailsColumn: React.FC<DetailsColumnProps> = ({ message, onClick }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tags = message.tags ?? [];
  const allTags = searchParams.get("tags")?.split(",").filter(Boolean) || [];
  const displayText = message.brief !== null ? message.brief : message.snippet;
  const decodedDisplayText = displayText ? decodeHtml(displayText) : '';

  const getIntegrationIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'outlook':
        return Outlook;
      case 'gmail':
        return Gmail;
      case 'slack':
        return Slack;
      default:
        return Gmail;
    }
  };

  const IntegrationIcon = getIntegrationIcon(message.integration_type);

  return (
    <div 
      onClick={onClick}
      className="w-full cursor-pointer"
    >
      {/* Force full width regardless of content */}
      <div className="flex flex-col w-full">
        {/* Message Preview */}
        <div className="w-full">
          <p className="text-sm text-muted-foreground line-clamp-2 min-h-[40px]">
            {decodedDisplayText || 'No preview available'}
          </p>
        </div>

        {/* Bottom Row: Integration Icon & Tags */}
        <div className="flex w-full">
          {/* Integration Icon */}
          <div className="flex-shrink-0 mr-4">
            <IntegrationButton
              onClick={(e) => {
                e.stopPropagation();
                setSearchParams((prevParams) => {
                  const newParams = new URLSearchParams(prevParams);
                  newParams.append("integrations", String(message.integration_id));
                  return newParams;
                });
              }}
            >
              <IntegrationIcon className="w-6 h-6" />
            </IntegrationButton>
          </div>

          {/* Tags Container - Takes remaining width */}
          <div className="flex-1 flex gap-2 items-center overflow-x-auto scrollbar-none">
            {tags.slice(0, 3).map((tag, index) => (
              <TagItem
                key={index}
                tag={tag}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!allTags.includes(tag)) {
                    setSearchParams((prevParams) => {
                      const currentTags = prevParams.get("tags")?.split(",").filter(Boolean) || [];
                      const newTags = [...currentTags, tag];
                      return {
                        ...Object.fromEntries(prevParams),
                        tags: newTags.join(","),
                      };
                    });
                  }
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};