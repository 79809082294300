import React from "react";
import { Link } from "react-router-dom";
import { getEmailFromString, toTimeAgo } from "@attrove/attrove-ui/lib/utils";
import { Integration } from "@attrove/service-supabase";
import { Badge, Card, Icons } from "@attrove/ui-shadcn";
import { IntegrationActions } from "./integration-actions";
import { Loader2 } from "lucide-react";

const PROVIDER_DISPLAY_NAMES: Record<string, string> = {
  gmail: "Gmail",
  google: "Gmail",
  google_calendar: "Google Calendar",
  google_meet: "Google Meet",
  // Add more mappings as needed
};

const getProviderDisplayName = (provider: string): string =>
  PROVIDER_DISPLAY_NAMES[provider] || provider.charAt(0).toUpperCase() + provider.slice(1);

interface IntegrationCardProps {
  integration: Integration;
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({ integration }) => {
  const Icon = Icons[integration.provider as keyof typeof Icons];
  const title = getProviderDisplayName(integration.provider);
  const email = getEmailFromString(integration.name);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if ((e.target as HTMLElement).closest(".delete-button")) {
      e.preventDefault();
    }
  };

  return (
    <Link to={`/integrations/${integration.id}`} onClick={handleClick}>
      <Card className="overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
        <div className="p-6">
          <div className="flex items-center space-x-4 mb-4">
            <div className="bg-primary/10 p-3 rounded-lg">
              <Icon className="w-6 h-6 text-primary" />
            </div>
            <div>
              <div className="flex items-center gap-2">
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">{title}</h3>
                <Badge
                  className={`${
                    integration.is_active
                      ? "bg-green-500/70 animate-pulse-subtle hover:bg-green-500/70"
                      : "bg-red-500/70 hover:bg-red-500/70"
                  } text-xs font-medium text-foreground uppercase tracking-wide `}
                >
                  {integration.is_active ? "Active" : "Inactive"}
                </Badge>
              </div>

              <p className="text-sm text-muted-foreground">{email}</p>
            </div>
          </div>

          <div className="flex gap-1 text-sm text-muted-foreground">
            {integration.pending_sync ? (
              <div className="flex items-center gap-2">
                <p>Currently syncing</p>
                <Loader2 className="w-4 h-4 animate-spin" />
              </div>
            ) : integration.last_synced_at ? (
              <p>Last synced: {toTimeAgo(integration.last_synced_at)}</p>
            ) : (
              <p>Not synced yet</p>
            )}
          </div>
          <div className="mt-4">
            <IntegrationActions integration={integration} />
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default IntegrationCard;
