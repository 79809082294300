import React from 'react';
import { useSearchParams } from "react-router-dom";
import { Table } from "@tanstack/react-table";
import { TopEntity, PeopleOptionsType } from "@attrove/attrove-ui/app/pages/dashboard/people";
import {
  buttonVariants,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@attrove/ui-shadcn";
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { capitalizeFirstLetter } from "@attrove/util-formatting";

interface FilterSelectProps {
  options: PeopleOptionsType;
  title: string;
  urlParam: string;
  table: Table<TopEntity>;
}

export const FilterSelect: React.FC<FilterSelectProps> = ({ options, title, urlParam, table }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = searchParams.getAll(urlParam);

  const specificOptions = options[urlParam as keyof PeopleOptionsType] || [];

  const isOptionSelected = (option: (typeof specificOptions)[number]) => {
    return filters.includes(String(option.id));
  };

  const handleOptionToggle = (optionId: string) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      const currentValues = newParams.getAll(urlParam);
      
      if (currentValues.includes(optionId)) {
        newParams.delete(urlParam);
        currentValues.filter(v => v !== optionId).forEach(v => newParams.append(urlParam, v));
      } else {
        newParams.append(urlParam, optionId);
      }
      
      // Update the table filter
      const column = table.getColumn("top_tags");
      if (column) {
        column.setFilterValue(newParams.getAll(urlParam));
      }

      return newParams;
    });
  };

  const clearFilters = () => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete(urlParam);
      
      // Clear the table filter
      const column = table.getColumn("top_tags");
      if (column) {
        column.setFilterValue([]);
      }
      
      return newParams;
    });
  };

  return (
    <div className="flex items-center">
      <Popover>
        <PopoverTrigger asChild className="w-full">
          <div className={cn(buttonVariants({ variant: "outline" }), "bg-transparent w-full flex justify-start")}>
            <PlusCircledIcon className="mr-2 h-4 w-4" />
            {capitalizeFirstLetter(title)}
            {filters.length > 0 && (
              <span className="ml-2 rounded-full bg-primary text-primary-foreground px-2 py-0.5 text-xs">
                {filters.length}
              </span>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0" align="start">
          <Command>
            <CommandInput placeholder={capitalizeFirstLetter(title)} />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {specificOptions.map((option) => {
                  const isSelected = isOptionSelected(option);
                  return (
                    <CommandItem
                      key={option.id}
                      onSelect={() => handleOptionToggle(String(option.id))}
                    >
                      <div
                        className={cn(
                          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                          isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible",
                        )}
                      >
                        <CheckIcon className={cn("h-4 w-4")} />
                      </div>
                      <span>{capitalizeFirstLetter(option.value)}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              {filters.length > 0 && (
                <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem onSelect={clearFilters} className="justify-center text-center">
                      Clear filters
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};