import { fromZonedTime } from "date-fns-tz";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export function convertFromUtc(time: string | undefined, timezone: string): string {
  if (!time) {
    console.warn(`Received undefined or null time for timezone ${timezone}`);
    return "Invalid time";
  }

  try {
    const utcTime = dayjs.utc(time, "HH:mm:ss");

    // Convert to the specified timezone
    const zonedTime = utcTime.tz(timezone);
    return zonedTime.format("HH:mm:ss");
  } catch (error) {
    console.error(`Error converting time ${time} to timezone ${timezone}:`, error);
    return "Invalid time";
  }
}

export function convertToUtc(timeString: string, timezone: string): string {
  // Create a Date object for today with the given time
  const today = new Date();
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const zonedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes, seconds);

  // Convert the zoned time to UTC
  const utcDate = fromZonedTime(zonedDate, timezone);

  // Format and return the UTC time
  return utcDate.toISOString().slice(11, 19);
}

export function convertFromDateTimeUtc(dateTimeString: string, timezone: string): string {
  if (!dateTimeString) {
    console.warn(`Received undefined or null dateTime for timezone ${timezone}`);
    return "Invalid date/time";
  }

  try {
    // Parse the UTC date-time string
    const utcDateTime = dayjs.utc(dateTimeString);

    // Convert to the specified timezone
    const localDateTime = utcDateTime.tz(timezone);

    // Format the result
    // You can adjust the format string as needed
    return localDateTime.format('YYYY-MM-DD HH:mm:ss z');
  } catch (error) {
    console.error(`Error converting date/time ${dateTimeString} to timezone ${timezone}:`, error);
    return "Invalid date/time";
  }
}

export function parseTime(timeString: string): {
  hour: string;
  minute: string;
  period: string;
} {
  const [hourStr, minuteStr] = timeString.split(":");

  let hour = parseInt(hourStr);
  const minute = minuteStr;
  const period = hour >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  if (hour > 12) {
    hour -= 12;
  } else if (hour === 0) {
    hour = 12;
  }

  return {
    hour: hour.toString(), // Remove padStart to allow single-digit hours
    minute,
    period,
  };
}

export function formatTime(hour: string | number, minute: string | number, period: string): string {
  // Convert hour to number if it's a string
  let hourNum = typeof hour === "string" ? parseInt(hour) : hour;

  // Convert to 24-hour format
  if (period.toUpperCase() === "PM" && hourNum < 12) {
    hourNum += 12;
  } else if (period.toUpperCase() === "AM" && hourNum === 12) {
    hourNum = 0;
  }

  // Pad hour and minute with zeros if necessary
  const paddedHour = hourNum.toString().padStart(2, "0");
  const paddedMinute = minute.toString().padStart(2, "0");

  // Return the formatted string
  return `${paddedHour}:${paddedMinute}:00`;
}

/**
 * Formats a date string or Date object into a localized date format (e.g., "October 23, 2024")
 * 
 * @param date - ISO date string or Date object to format
 * @param locale - Optional locale string (defaults to 'en-US')
 * @returns Formatted date string
 * @throws Error if date parsing fails
 */
export function formatToLocalizedDate(date: string | Date, locale: string = 'en-US'): string {
  try {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    
    if (isNaN(dateObj.getTime())) {
      throw new Error('Invalid date input');
    }

    return dateObj.toLocaleDateString(locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  } catch (error: unknown) {
    // Properly type check the error before accessing message property
    if (error instanceof Error) {
      throw new Error(`Failed to format date: ${error.message}`);
    }
    // If it's not an Error instance, convert to string
    throw new Error(`Failed to format date: ${String(error)}`);
  }
}