import { Badge, Button, cn } from "@attrove/ui-shadcn";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { Copy, Check } from "lucide-react";
import { useState } from "react";
import { toast } from "@attrove/ui-shadcn";

interface ActionItem {
  priority: string;
  task: string;
  dueDate: string | Date;
}

const CopyButton = ({ text }: { text: string }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      toast({
        description: "Copied to clipboard",
        duration: 2000,
      });
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      toast({
        description: "Failed to copy text",
        variant: "destructive",
      });
    }
  };

  return (
    <Button
      variant="ghost"
      size="sm"
      className={cn(
        "h-8 w-8 transition-all",
        copied ? "text-green-500" : "text-muted-foreground hover:text-foreground"
      )}
      onClick={copyToClipboard}
      title="Copy to clipboard"
    >
      {copied ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
      <span className="sr-only">Copy task</span>
    </Button>
  );
};

export const columns: ColumnDef<ActionItem>[] = [
  {
    accessorKey: "priority",
    header: "Priority",
    cell: ({ row }) => {
      function getPriorityClass(priority: string): string {
        switch (priority.toLowerCase()) {
          case "high":
            return "bg-red-500";
          case "medium":
            return "bg-yellow-500";
          case "low":
            return "bg-green-500";
          default:
            return "bg-gray-500";
        }
      }
      const priority = row.getValue("priority") as string;
      return (
        <div className="flex items-center justify-center h-full w-full">
          <div className={cn("w-2 h-2 rounded-full", getPriorityClass(priority))} />
        </div>
      );
    },
    meta: {
      cellClassName: "w-12",
    },
  },
  {
    accessorKey: "task",
    header: "Task",
    cell: ({ row }) => {
      const task = row.getValue("task") as string;
      return <div className="font-medium">{task}</div>;
    },
  },
  {
    accessorKey: "dueDate",
    header: "Due Date",
    cell: ({ row }) => {
      const dueDate = row.getValue("dueDate") as string | Date;
      try {
        const date = new Date(dueDate);
        if (isNaN(date.getTime())) {
          return <div className="text-muted-foreground">Invalid date</div>;
        }
        return <div className="text-muted-foreground">{format(date, "MMM d")}</div>;
      } catch (error) {
        return <div className="text-muted-foreground">Invalid date</div>;
      }
    },
    meta: {
      cellClassName: "whitespace-nowrap",
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const task = row.getValue("task") as string;
      return (
        <div className="flex justify-center">
          <CopyButton text={task} />
        </div>
      );
    },
    meta: {
      cellClassName: "w-14",
    },
  },
];