import { useContext, useEffect, useState } from "react";
import { NewIntegrationCard } from "./new-integration-card";
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { useNavigate, useSearchParams } from "react-router-dom";
import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { GET_REPORTS_QUERY_KEY } from "@attrove/service-supabase";

export const SlackIntegration: React.FC<{
  onSuccess?: () => void;
}> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const axiosClient = useContext(AxiosClientContext);
  const navigate = useNavigate();

  const provider = searchParams.get("provider");
  const code = searchParams.get("code");
  
  // Check if we're in a callback state
  const isCallback = provider === "slack" && code;

  useEffect(() => {
    async function handleSlackCallback() {
      if (onSuccess) {
        onSuccess();
      }

      try {
        const response = await axiosClient.get(`/integrations/new?provider=slack&code=${code}`);
        console.log("Slack callback response:", response.data);
      } catch (error) {
        console.error("Error in Slack callback:", error);
      }

      navigate("/integrations");
      queryClient.invalidateQueries({ queryKey: [GET_REPORTS_QUERY_KEY] });
    }

    if (isCallback) {
      handleSlackCallback();
    }
  }, [provider, code]);

  const handleSlackIntegration = async () => {
    setLoading(true);

    try {
      const response = await axiosClient.get("/integrations/new?provider=slack");
      console.log("Slack integration response:", response.data);

      if (response.status === 200 && response.data.authUrl) {
        window.location.href = response.data.authUrl;
      }
    } catch (error) {
      console.error("Error initiating Slack integration:", error);
      setLoading(false);
    }
  };

  return (
    <NewIntegrationCard 
      provider="slack" 
      name="Slack" 
      loading={loading}
      isCallback={isCallback}
      onClick={handleSlackIntegration}
    />
  );
};