import { userRoleSchema } from "@attrove/service-supabase";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem, Label } from "@attrove/ui-shadcn";
import { z } from "zod";
import { Loader2 } from "lucide-react";

export type RoleType = z.infer<typeof userRoleSchema>;

type RoleInputProps = {
  value?: RoleType;
  onChange: (value: RoleType) => void;
  isLoading?: boolean;
};

export const RoleInput: React.FC<RoleInputProps> = ({ value, onChange, isLoading }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Label>What's your role at work?</Label>
        {isLoading && (
          <div className="flex items-center gap-2 text-xs text-muted-foreground">
            <Loader2 className="h-3 w-3 animate-spin" />
            <span>Saving...</span>
          </div>
        )}
      </div>
      <Select onValueChange={onChange} value={value} disabled={isLoading}>
        <SelectTrigger>
          <SelectValue placeholder="Select a role" />
        </SelectTrigger>

        <SelectContent>
          {userRoleSchema.options.map((role) => (
            <SelectItem key={role.value} value={role.value}>
              {role.value.charAt(0).toUpperCase() + role.value.slice(1)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
