import React, { useState } from "react";
import {
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Logo,
} from "@attrove/ui-shadcn";
import { CircleUser, Menu, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import NotificationBell from "@attrove/attrove-ui/components/NotificationElements";

type MobileNavProps = {
  items: any[];
  userEmail: string;
  onLogout: () => void;
  className?: string;
  notificationCount?: number;
  notifications?: any[];
  onMarkAsRead?: (id: string | null) => void;
};

export const MobileNav: React.FC<MobileNavProps> = ({
  items,
  userEmail,
  onLogout,
  className,
  notificationCount = 0,
  notifications = [],
  onMarkAsRead = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const handleNavigation = (href: string) => {
    setOpen(false);
    navigate(href);
  };

  return (
    <div className={cn("md:hidden", className)}>
      <div className="fixed top-0 left-0 right-0 z-50 bg-background border-b border-border">
        <div className="flex justify-between items-center p-4">
          <Logo />
          <div className="flex items-center gap-2">
            <NotificationBell count={notificationCount} notifications={notifications} onMarkAsRead={onMarkAsRead} />
            <Button variant="ghost" size="icon" onClick={() => setOpen(!open)} aria-label={open ? "Close menu" : "Open menu"}>
              {open ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </Button>
          </div>
        </div>
      </div>

      <div
        className={cn(
          "fixed inset-0 top-[64px] z-40 bg-background transition-transform duration-300 ease-in-out transform",
          open ? "translate-x-0" : "translate-x-full",
        )}
      >
        <div className="h-full overflow-y-auto">
          <div className="px-4 py-6 flex flex-col h-full">
            <nav className="space-y-4 flex-grow">
              {items.map((item, index) => (
                <div key={index} className="pb-4">
                  <h4 className="mb-1 px-2 py-1 text-xs uppercase font-semibold">{item.title}</h4>
                  {item.items && (
                    <div className="space-y-1">
                      {item.items.map((subItem: any, subIndex: number) => (
                        <Button
                          key={subIndex}
                          variant="ghost"
                          className={cn("w-full justify-start", pathname === subItem.href ? "bg-accent" : "hover:bg-accent")}
                          onClick={() => handleNavigation(subItem.href)}
                        >
                          {subItem.title}
                        </Button>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </nav>
            <div className="mt-auto pt-6">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="w-full justify-start">
                    <CircleUser className="mr-2 h-4 w-4" />
                    <span className="truncate">{userEmail}</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-[calc(100vw-2rem)] mx-4">
                  <DropdownMenuLabel>My Account</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={() => handleNavigation("/settings/profile")}>Settings</DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleNavigation("/settings/billing")}>Billing</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() => {
                      setOpen(false);
                      onLogout();
                    }}
                  >
                    Sign Out
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
