import { z } from "zod";
import { type AttroveSupabaseClient, reportsInsertSchema, reportsRowSchema, DB, Message, Json } from "@attrove/service-supabase";
import { logError, logInfo } from "@attrove/util-logs";

export type ReportInsert = Omit<z.infer<typeof reportsInsertSchema>, "id">;
export type Report = z.infer<typeof reportsRowSchema>;

// Type for updating report IDs
export interface UpdateItem {
  id: number;
  type: "message" | "event";
}

type ValidTable = "messages" | "events";

/**
 * Update report IDs for messages and events in batches
 */
export async function updateReportIds(
  supabaseClient: AttroveSupabaseClient,
  reportId: number,
  items: UpdateItem[]
): Promise<void> {
  if (items.length === 0) {
    logInfo("[updateReportIds] No items to update");
    return;
  }

  const batchSize = 1000; // Adjust based on database capabilities
  const messageIds: number[] = [];
  const eventIds: number[] = [];

  // Separate message and event IDs
  items.forEach((item) => {
    if (item.type === "message") messageIds.push(item.id);
    else eventIds.push(item.id);
  });

  const updateBatch = async (table: ValidTable, ids: number[]) => {
    for (let i = 0; i < ids.length; i += batchSize) {
      const batchIds = ids.slice(i, i + batchSize);
      try {
        const { error } = await supabaseClient.from(table).update({ report_id: reportId }).in("id", batchIds);

        if (error) throw error;
      } catch (error) {
        logError(`[updateReportIds] Error updating ${table}`, { error, batchSize, start: i });
        // Continue with the next batch instead of throwing
      }
    }
  };

  await Promise.all([updateBatch("messages", messageIds), updateBatch("events", eventIds)]);
}

export const insertReport = async (
  supabaseClient: AttroveSupabaseClient,
  reportData: ReportInsert,
): Promise<Report | null> => {
  reportsInsertSchema.parse(reportData);

  const { data: report, error: insertReportError } = await supabaseClient
    .from(DB.REPORTS)
    .insert(reportData as any)
    .select()
    .single();

  if (insertReportError) {
    logError(
      "error inserting report",
      {
        error: insertReportError,
        reportData,
      },
    );
    return null;
  }
  return report;
};

export const GET_REPORTS_QUERY_KEY = "GET_REPORTS";
export type ReportWithMessages = Report & { messages: Message[] };

export interface ReportWithMessageCount {
  id: number;
  created_at: string;
  content: Json;
  user_id: string;
  message_count: number;
}

/**
 * Get reports for a user with message counts
 */
export const getReports = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
  options?: {
    limit?: number;
    offset?: number;
    startDate?: Date;
    endDate?: Date;
  }
): Promise<ReportWithMessageCount[]> => {
  try {
    let query = supabaseClient
      .from(DB.REPORTS)
      .select(`
        id,
        created_at,
        content,
        user_id,
        message_count:${DB.MESSAGES}(count),
        messages:${DB.MESSAGES}(
          id,
          integration:integrations!inner(
            id,
            type
          )
        ),
        events:${DB.EVENTS}(
          id,
          calendar:calendars!calendar_id_fkey(
            integration:integrations!inner(
              id,
              type
            )
          )
        )
      `)
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    // Apply optional filters
    if (options?.startDate) {
      query = query.gte('created_at', options.startDate.toISOString());
    }
    if (options?.endDate) {
      query = query.lte('created_at', options.endDate.toISOString());
    }
    if (options?.limit) {
      query = query.limit(options.limit);
    }
    if (options?.offset) {
      query = query.range(
        options.offset,
        options.offset + (options.limit || 20) - 1
      );
    }

    const { data: reports, error } = await query;

    if (error) {
      console.error('[getReports] Database error:', error);
      throw error;
    }

    if (!reports) {
      return [];
    }

    // Transform the raw reports to match our interface
    return reports.map((report: any) => {
      // Create a map of message IDs to their integration types
      const messageIntegrations = report.messages?.reduce((acc: Record<string, { type: string; id: string }>, msg: { id: number; integration?: { type: string; id: string } }) => ({
        ...acc,
        [msg.id]: {
          type: msg.integration?.type,
          id: msg.integration?.id
        }
      }), {}) || {};

      // Create a map of event IDs to their integration types
      const eventIntegrations = report.events?.reduce((acc: Record<string, { type: string; id: string }>, event: { id: number; calendar?: { integration?: { type: string; id: string } } }) => ({
        ...acc,
        [event.id]: {
          type: event.calendar?.integration?.type,
          id: event.calendar?.integration?.id
        }
      }), {}) || {};

      // Parse content if it's a string, otherwise use as is
      const content = typeof report.content === 'string' ? JSON.parse(report.content) : report.content;

      // Add integration information to the content
      if (content.executiveSummary?.summary) {
        content.executiveSummary.summary = content.executiveSummary.summary.map((item: { messageIds?: string[]; eventIds?: string[]; text: string }) => ({
          ...item,
          messageIntegrations: (item.messageIds || [])
            .filter((id: string) => id && id !== "undefined")
            .map((id: string) => messageIntegrations[id])
            .filter(Boolean),
          eventIntegrations: (item.eventIds || [])
            .filter((id: string) => id && id !== "undefined")
            .map((id: string) => eventIntegrations[id])
            .filter(Boolean)
        }));
      }

      return {
        id: report.id,
        created_at: report.created_at,
        content: content,
        user_id: report.user_id,
        message_count: Number(report.message_count[0]?.count || 0),
        messages: report.messages,
        events: report.events
      };
    });

  } catch (error) {
    if (error instanceof Error) {
      console.error('[getReports] Error fetching reports:', error.message);
      throw new Error(`Failed to fetch reports for user ${userId}: ${error.message}`);
    }
    throw new Error(`Failed to fetch reports for user ${userId}: Unknown error`);
  }
};

/**
 * Get a single report by ID
 */
export const getReportById = async (
  supabaseClient: AttroveSupabaseClient,
  reportId: number,
): Promise<Report | null> => {
  try {
    const { data: report, error } = await supabaseClient
      .from(DB.REPORTS)
      .select('*')
      .eq('id', reportId)
      .single();

    if (error) {
      logError('[getReportById] Database error:', { error, reportId });
      return null;
    }

    return report;
  } catch (error) {
    logError('[getReportById] Error fetching report:', { error, reportId });
    return null;
  }
};