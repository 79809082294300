import { useContext, useEffect, useState } from "react";
import { NewIntegrationCard } from "@attrove/attrove-ui/components/integrations/new-integration-card";
import { useToast } from "@attrove/ui-shadcn";
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { useNavigate, useSearchParams } from "react-router-dom";

export const GoogleCalendarIntegration: React.FC<{
  onSuccess?: () => void;
}> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const axiosClient = useContext(AxiosClientContext);
  const navigate = useNavigate();
  const { toast } = useToast();

  const provider = searchParams.get("provider");
  const code = searchParams.get("code");
  const error = searchParams.get("error");
  const errorMessage = searchParams.get("message");

  useEffect(() => {
    async function handleGcalCallback() {
      try {
        setLoading(true);
        const response = await axiosClient.get(`/integrations/new?provider=google_calendar&code=${code}`);
        
        // If successful, call onSuccess callback if provided
        if (onSuccess) {
          onSuccess();
        }
        
        navigate("/integrations");
      } catch (error: any) {
        const errorMsg = error.response?.data?.message || "Failed to connect Google Calendar. Please try again.";
        
        toast({
          title: "Integration Error",
          description: errorMsg,
          variant: "destructive",
          duration: 5000,
        });
        
        navigate("/integrations/new");
      } finally {
        setLoading(false);
      }
    }

    // Handle error parameters if present
    if (error === 'insufficient_scopes') {
      toast({
        title: "Missing Required Permissions",
        description: errorMessage || "Please grant all required calendar permissions to continue.",
        variant: "destructive",
        duration: 6000,
      });
      // Clean up URL parameters
      navigate("/integrations/new");
    }
    
    // Handle the OAuth callback
    if (provider === "google_calendar" && code) {
      handleGcalCallback();
    }
  }, [provider, code, error, errorMessage, navigate, axiosClient, onSuccess, toast]);

  const handleGcalIntegration = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get("/integrations/new?provider=google_calendar");
      
      if (response.status === 200 && response.data.authUrl) {
        window.location.href = response.data.authUrl;
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      toast({
        title: "Connection Error",
        description: "Unable to start Google Calendar integration. Please try again later.",
        variant: "destructive",
        duration: 5000,
      });
      setLoading(false);
    }
  };

  return (
    <NewIntegrationCard 
      provider={"google_calendar"} 
      name={"Google Calendar"} 
      loading={loading} 
      onClick={handleGcalIntegration} 
    />
  );
};