import React, { forwardRef, useState } from "react";
import { Bell, CheckCircle2, Clock, AlertCircle, FileText, XIcon, Trash2 } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Badge,
  cn,
  Separator,
  useToast,
} from "@attrove/ui-shadcn";

interface Notification {
  id: string;
  title: string;
  content: string;
  created_at: string;
  is_read: boolean;
  is_dismissed?: boolean;
  type?: "success" | "info" | "warning" | "error";
  metadata?: {
    report_id?: number;
    [key: string]: unknown;
  };
}

interface NotificationItemProps {
  notification: Notification;
  onMarkAsRead: () => void;
  onDismiss: () => void;
  onClose?: () => void;
}

const NotificationIcon = ({ type }: { type?: Notification["type"] }) => {
  switch (type) {
    case "success":
      return <CheckCircle2 className="h-5 w-5 text-green-500" />;
    case "warning":
      return <AlertCircle className="h-5 w-5 text-yellow-500" />;
    case "error":
      return <AlertCircle className="h-5 w-5 text-red-500" />;
    default:
      return <Clock className="h-5 w-5 text-blue-500" />;
  }
};

const TimeAgo = ({ date }: { date: string }) => {
  const getTimeAgo = (date: string) => {
    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) return `${interval} years ago`;
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return `${interval} months ago`;
    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `${interval} days ago`;
    interval = Math.floor(seconds / 3600);
    if (interval > 1) return `${interval} hours ago`;
    interval = Math.floor(seconds / 60);
    if (interval > 1) return `${interval} minutes ago`;
    return "just now";
  };

  return <span className="text-xs text-muted-foreground">{getTimeAgo(date)}</span>;
};

const NotificationItem = forwardRef<HTMLDivElement, NotificationItemProps>(({ notification, onMarkAsRead, onDismiss, onClose }, ref) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    onMarkAsRead();

    if (notification.metadata?.report_id) {
      onClose?.();
      setTimeout(() => {
        navigate(`/reports?reportId=${notification.metadata.report_id}`);
      }, 100);
    }
  };

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, height: 0, y: -5 }}
      className={cn(
        "flex items-start gap-4 p-4 transition-all relative group",
        !notification.is_read && "bg-accent/20",
        notification.metadata?.report_id && "hover:bg-accent/80",
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Main notification content - clickable area */}
      <div className="flex-1 flex items-start gap-4 cursor-pointer" onClick={handleClick}>
        <div className="flex-shrink-0 mt-1">
          {notification.metadata?.report_id ? (
            <FileText className="h-5 w-5 text-blue-500" />
          ) : (
            <NotificationIcon type={notification.type} />
          )}
        </div>
        <div className="flex-1 space-y-1 min-w-0">
          <div className="flex items-start justify-between gap-2">
            <p className={cn("text-sm line-clamp-2", !notification.is_read && "font-medium")}>{notification.title}</p>
          </div>
          <p className="text-sm text-muted-foreground line-clamp-2">
            {notification.content}
            {notification.metadata?.report_id && <span className="block text-blue-500 text-xs mt-1">Click to view report →</span>}
          </p>
          <TimeAgo date={notification.created_at} />
        </div>
      </div>

      {/* Dismiss button - appears on hover */}
      <AnimatePresence>
        {isHovered && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            className="absolute right-2 top-2"
          >
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8 text-muted-foreground hover:text-foreground"
              onClick={(e) => {
                e.stopPropagation();
                onDismiss();
              }}
            >
              <XIcon className="h-4 w-4" />
            </Button>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Unread indicator */}
      {!notification.is_read && <div className="w-2 h-2 rounded-full bg-blue-500 mt-2 flex-shrink-0" />}
    </motion.div>
  );
});

NotificationItem.displayName = "NotificationItem";

interface NotificationPanelProps {
  notifications: Notification[];
  onMarkAsRead: (id: string) => void;
  onDismiss: (id: string) => void;
  onDismissAllRead: () => void;
  onUndoDismiss: (ids: string[]) => void;
  onClose: () => void;
}

const NotificationPanel = ({
  notifications = [],
  onMarkAsRead,
  onDismiss,
  onDismissAllRead,
  onUndoDismiss,
  onClose,
}: NotificationPanelProps) => {
  const { toast } = useToast(); // Use the hook to get the toast function
  const readNotifications = notifications.filter((n) => n.is_read && !n.is_dismissed);
  const unreadNotifications = notifications.filter((n) => !n.is_read && !n.is_dismissed);

  const handleClearRead = () => {
    // Store the IDs before dismissing
    const readIds = readNotifications.map(n => n.id);
    
    // Call dismiss first
    onDismissAllRead();
    
    // Show toast with the stored IDs directly in the closure
    toast({
      title: "Notifications cleared",
      description: "All read notifications have been cleared",
      action: (
        <Button 
          variant="outline" 
          size="sm" 
          onClick={() => {
            // Use the IDs captured in this closure
            onUndoDismiss(readIds);
            toast({
              title: "Notifications restored",
              description: "Your notifications have been restored"
            });
          }}
        >
          Undo
        </Button>
      ),
      duration: 5000,
    });
  };

  return (
    <div className="w-[380px]">
      <div className="flex items-center justify-between px-4 py-3 border-b">
        <div className="space-y-1">
          <h4 className="text-sm font-medium">Notifications</h4>
          <p className="text-xs text-muted-foreground">{unreadNotifications.length} unread messages</p>
        </div>
        {readNotifications.length > 0 && (
          <Button 
            variant="ghost" 
            size="sm" 
            className="h-8 text-xs gap-2"
            onClick={handleClearRead}
          >
            <Trash2 className="h-3 w-3" />
            Clear Read
          </Button>
        )}
      </div>
      <Tabs defaultValue="all" className="w-full">
        <div className="px-4 py-2">
          <TabsList className="w-full grid grid-cols-2">
            <TabsTrigger value="all">All ({notifications.filter((n) => !n.is_dismissed).length})</TabsTrigger>
            <TabsTrigger value="unread">Unread ({unreadNotifications.length})</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="all" className="pb-2">
          <ScrollArea className="h-[400px]">
            <AnimatePresence mode="popLayout">
              {notifications.filter((n) => !n.is_dismissed).length === 0 ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="flex flex-col items-center justify-center h-[300px] text-muted-foreground"
                >
                  <Bell className="h-8 w-8 mb-4 text-muted-foreground/50" />
                  <p className="text-sm">No notifications</p>
                </motion.div>
              ) : (
                notifications
                  .filter((n) => !n.is_dismissed)
                  .map((notification) => (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                      onMarkAsRead={() => onMarkAsRead(notification.id)}
                      onDismiss={() => onDismiss(notification.id)}
                      onClose={onClose}
                    />
                  ))
              )}
            </AnimatePresence>
          </ScrollArea>
        </TabsContent>
        <TabsContent value="unread" className="pb-2">
          <ScrollArea className="h-[400px]">
            <AnimatePresence mode="popLayout">
              {unreadNotifications.length === 0 ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="flex flex-col items-center justify-center h-[300px] text-muted-foreground"
                >
                  <CheckCircle2 className="h-8 w-8 mb-4 text-muted-foreground/50" />
                  <p className="text-sm">All caught up!</p>
                </motion.div>
              ) : (
                unreadNotifications.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    onMarkAsRead={() => onMarkAsRead(notification.id)}
                    onDismiss={() => onDismiss(notification.id)}
                    onClose={onClose}
                  />
                ))
              )}
            </AnimatePresence>
          </ScrollArea>
        </TabsContent>
      </Tabs>
    </div>
  );
};

interface NotificationBellProps {
  count: number;
  notifications: Notification[];
  onMarkAsRead: (id: string) => void;
  onDismiss: (id: string) => void;
  onDismissAllRead: () => void;
  onUndoDismiss: (ids: string[]) => void;
}

const NotificationBell = ({
  count = 0,
  notifications = [],
  onMarkAsRead,
  onDismiss,
  onDismissAllRead,
  onUndoDismiss,
}: NotificationBellProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        {/* Change from Button to div with button styling */}
        <div
          role="button"
          tabIndex={0}
          className={cn(
            "inline-flex items-center justify-center rounded-full",
            "h-9 w-9 relative",
            "text-sm font-medium ring-offset-background transition-colors",
            "hover:bg-accent hover:text-accent-foreground",
            "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
            "disabled:pointer-events-none disabled:opacity-50",
          )}
        >
          <Bell className="h-4 w-4" />
          <AnimatePresence>
            {count > 0 && (
              <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} className="absolute -top-1 -right-1">
                <Badge className="h-5 min-w-5 flex items-center justify-center p-0 text-xs" variant="destructive">
                  {count > 99 ? "99+" : count}
                </Badge>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </PopoverTrigger>
      <PopoverContent align="end" alignOffset={-16} className="w-[380px] p-0 mr-4" forceMount>
        <NotificationPanel
          notifications={notifications}
          onMarkAsRead={onMarkAsRead}
          onDismiss={onDismiss}
          onDismissAllRead={onDismissAllRead}
          onUndoDismiss={onUndoDismiss}
          onClose={() => setIsOpen(false)}
        />
      </PopoverContent>
    </Popover>
  );
};

export default NotificationBell;
