import { supabase } from "@attrove/attrove-ui/app/supabase";
import { GET_USER_QUERY_KEY, User, getUser } from "@attrove/service-supabase";
import { Session } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import { axiosClient } from "../main";

type SessionContextValue = {
  session: Session;
  user: User;
};

const SessionContext = createContext<SessionContextValue | undefined>(undefined);

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};

type Props = { children: React.ReactNode };
export const SessionProvider = ({ children }: Props) => {
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error fetching session:", error);
        setIsLoading(false);
        return;
      }
      setSession(data.session);
      setIsLoading(false);
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setSession(session);
      axiosClient.defaults.headers.common["Authorization"] = `Bearer ${session?.access_token}`;
      setIsLoading(false);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const {
    data: user,
    isLoading: isUserLoading,
    isError,
    error,
  } = useQuery<User, Error>({
    queryKey: [GET_USER_QUERY_KEY],
    queryFn: async () => {
      if (!session?.user.id) throw new Error("No user ID available");
      try {
        return await getUser(supabase, session.user.id);
      } catch (error) {
        // After 4 retries, this will trigger the error case
        await supabase.auth.signOut();
        throw error;
      }
    },
    enabled: !!session?.user.id,
    retry: 4,
    retryDelay: 250  // Wait 1/4 second between retries
  });

  if (isLoading || isUserLoading) {
    return null;
  }

  if (!session) {
    return <p>You must be logged in to access this page.</p>;
  }

  if (isError) {
    return <p>Error: {error.message}</p>;
  }

  if (!user) {
    return <p>User data not available.</p>;
  }

  return <SessionContext.Provider value={{ session, user }}>{children}</SessionContext.Provider>;
};
