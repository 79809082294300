// import { SidebarNavItem } from '../index';
import { cn, Icons, Logo } from "@attrove/ui-shadcn";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@attrove/ui-shadcn";
import { CircleUser } from "lucide-react";
// import { SignOut } from '../config/auth-helpers/server';
import { Link } from "react-router-dom";

type DashboardNavProps = {
  items: any[];
  onLogout: () => void;
  userEmail: string;
  className?: string;
};

export function DashboardNav({ items, onLogout, userEmail }: DashboardNavProps) {
  const pathname = window.location.pathname;
  // const router = useRouter();
  // const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <aside className="hidden min-w-[220px] flex-col md:flex relative">
      <div className="p-5 flex mb-10">
        <Logo />
      </div>
      <div className="px-2 flex-grow">
        <div className="w-full px-1">
          {items.map((item, index) => (
            <div key={index} className={cn("pb-8")}>
              <h4 className="mb-1 rounded-md px-2 py-1 text-s  uppercase">{item.title}</h4>
              {item.items ? <DashboardSidebarNavItems items={item.items} pathname={pathname} /> : null}
            </div>
          ))}
      <div className="absolute bottom-3 left-0 w-full px-3">
      {/* <ThemeToggle className="rounded-full" /> */}
            <div className="ml-2">
            <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button 
              variant="secondary" 
              size="sm" 
              className="w-full justify-start text-left"
              childrenClassName="min-w-0"
            >
              <CircleUser className="mr-2 h-4 w-4 flex-shrink-0" />
              <span className="truncate">{userEmail}</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-56">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
              <Link to="/settings/profile">Settings</Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
            <Link to="/settings/billing">Billing</Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={onLogout}>Sign Out</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

type DashboardSidebarNavItemsProps = {
  items: any[];
  pathname: string | null;
};

export function DashboardSidebarNavItems({ items, pathname }: DashboardSidebarNavItemsProps) {
  return (
    <nav className="grid items-start gap-2">
      {items.map((item: any, index) => {
        const iconKey = item.icon || "arrowRight";
        const Icon = Icons[iconKey] || Icons.arrowRight;
        return (
          item.href && (
            <a
              key={index}
              href={item.disabled ? "/" : item.href}
              className={cn(
                "group flex items-center rounded-md px-3 py-2 text-sm font-medium hover:bg-accent hover:text-accent-foreground",
                pathname === item.href ? "bg-accent" : "transparent",
                item.disabled && "cursor-not-allowed opacity-80",
              )}
            >
              <Icon className="mr-2 h-4 w-4" />
              <span>{item.title}</span>
            </a>
          )
        );
      })}
    </nav>
  );
}
