import React from 'react';
import { cn } from "@attrove/ui-shadcn";
import { Link } from "react-router-dom";
import { parseTag } from "@attrove/attrove-ui/lib/utils";

interface TagCloudProps {
  tags: string[];
}

export const TagCloud: React.FC<TagCloudProps> = ({ tags }) => {
  // Group tags into importance levels based on their position in the array
  // Earlier tags are considered more important
  const groupedTags = tags.reduce((acc, tag, index) => {
    if (index < 3) {
      acc.high.push(tag);
    } else if (index < 8) {
      acc.medium.push(tag);
    } else {
      acc.low.push(tag);
    }
    return acc;
  }, { high: [], medium: [], low: [] });

  const TagGroup = ({ title, tags, variant = "default" }) => (
    <div className="mb-4">
      <h4 className="text-sm font-medium text-muted-foreground mb-2">{title}</h4>
      <div className="grid grid-cols-2 gap-2">
        {tags.map((tag) => (
          <Link 
            key={tag} 
            to={`/messages?tags=${tag}`}
            className="group block"
          >
            <div className={cn(
              "flex items-center p-2.5 rounded-lg border transition-all duration-200",
              "hover:bg-brand-primary/10 hover:border-brand-primary/30",
              (variant === "high" || variant === "medium") && "bg-brand-primary/5 border-brand-primary/20",
              variant === "default" && "bg-brand-primary/5 border-brand-primary/20 opacity-80"
            )}>
              <span className={cn(
                "text-sm font-medium transition-colors",
                "group-hover:text-brand-primary"
              )}>
                {parseTag(tag).split(' ').map(word => 
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                ).join(' ')}
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );

  return (
    <div className="space-y-2">
      {groupedTags.high.length > 0 && (
        <TagGroup 
          title="Top Topics" 
          tags={groupedTags.high} 
          variant="high" 
        />
      )}
      {groupedTags.medium.length > 0 && (
        <TagGroup 
          title="Common Topics" 
          tags={groupedTags.medium} 
          variant="medium" 
        />
      )}
      {groupedTags.low.length > 0 && (
        <TagGroup 
          title="Other Topics" 
          tags={groupedTags.low} 
        />
      )}
    </div>
  );
};