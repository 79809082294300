import React, { useRef, useState, useEffect } from "react";
import { cn, Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@attrove/ui-shadcn";
import { ColumnDef, Row, Table as TanStackTable } from "@tanstack/react-table";
import { EnhancedMessage } from "@attrove/service-supabase";

interface VirtualMessagesListProps {
  table: TanStackTable<EnhancedMessage>;
  columns: ColumnDef<EnhancedMessage, any>[];
  processedData: {
    rootMessages: EnhancedMessage[];
    threadMap: Map<number, EnhancedMessage[]>;
  };
}

export const VirtualMessagesList: React.FC<VirtualMessagesListProps> = ({ table, columns, processedData }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 20 });
  const rowHeight = 80; // Adjust based on your row height

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, clientHeight } = containerRef.current;
        const start = Math.floor(scrollTop / rowHeight);
        const end = Math.min(start + Math.ceil(clientHeight / rowHeight) + 1, processedData.rootMessages.length);
        setVisibleRange({ start, end });
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial call
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [processedData.rootMessages, rowHeight]);

  const renderRow = (message: EnhancedMessage, index: number) => {
    return (
      <TableRow
        key={`row-${message.message_id}`}
        className="group relative transition-all duration-200 hover:bg-accent/5 data-[state=selected]:bg-accent/10"
      >
        {columns.map((column) => (
          <TableCell
            key={`cell-${message.message_id}-${column.id}`}
            className={cn(
              "p-0", // Remove default padding
              column.meta?.className,
              column.id === "details" && "w-full table-cell", // Force full width for details column
            )}
          >
            {column.cell({ row: { index, original: message } as Row<EnhancedMessage>, table })}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const visibleRows = processedData.rootMessages.slice(visibleRange.start, visibleRange.end);

  return (
    <div ref={containerRef} className="h-[calc(100vh-400px)] overflow-y-auto rounded-lg border bg-card">
      <Table>
        <TableBody>
          {visibleRange.start > 0 && (
            <TableRow key="top-spacer">
              <TableCell colSpan={columns.length} style={{ height: `${visibleRange.start * rowHeight}px` }} className="border-b-0" />
            </TableRow>
          )}
          {visibleRows.map((message, index) => renderRow(message, visibleRange.start + index))}
          {processedData.rootMessages.length > visibleRange.end && (
            <TableRow key="bottom-spacer">
              <TableCell
                colSpan={columns.length}
                style={{ height: `${(processedData.rootMessages.length - visibleRange.end) * rowHeight}px` }}
                className="border-b-0"
              />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
