import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";

import { ThemeProvider } from "@attrove/ui-shadcn";
import App from "./app/app";
import { queryClient } from "./app/react-query";
import { UnprotectedSessionProvider } from "./context/UnprotectedSessionContext";

import axios from "axios";
import { createContext } from "react";

import { API_URL } from "@attrove/attrove-ui/constants";

export const axiosClient = axios.create({
  baseURL: API_URL,
});

export const AxiosClientContext = createContext(axiosClient);

const isDevelopment = import.meta.env.VITE_ENV === "development";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <ThemeProvider defaultTheme="system" storageKey="attrove-ui-theme">
      <QueryClientProvider client={queryClient}>
        <AxiosClientContext.Provider value={axiosClient}>
          <UnprotectedSessionProvider>
            <App />

            {isDevelopment && <ReactQueryDevtools initialIsOpen={true} />}
          </UnprotectedSessionProvider>
        </AxiosClientContext.Provider>
      </QueryClientProvider>
    </ThemeProvider>
  </StrictMode>,
);
