import { z } from "zod";
import { type AttroveSupabaseClient, DB, Integration, messagesInsertSchema, messagesRowSchema, Report } from "@attrove/service-supabase";
import { logError, logInfo, logWarn } from "@attrove/util-logs";
import { Logtail } from "@logtail/node";
import { EntityManager, EntityData } from "./entities";

// Support entities for messages
export type MessagesInsert = Omit<z.infer<typeof messagesInsertSchema>, "id"> & {
  sender_entity: EntityData;
  recipient_entities: Array<EntityData>;
  body_html: string;
  body_text: string;
  global_id_3p: string;
  id_3p: string;
  integration_id: number;
  sent_by: string;
  subject: string;
  received_at: string;
  thread_id_3p?: string;
  parent_message_id_3p?: string;
  thread_position?: number;
};
export type Message = z.infer<typeof messagesRowSchema>;

async function insertNewMessages(
  supabaseClient: AttroveSupabaseClient,
  entityManager: EntityManager,
  messagesData: MessagesInsert[],
  userId: string,
  logtail?: Logtail
): Promise<Message[]> {

  // Deduplicate messages based on id_3p and integration_id
  const uniqueMessages = Array.from(
    new Map(messagesData.map(msg => [`${msg.id_3p}-${msg.integration_id}`, msg])).values()
  );

  logInfo("[insertNewMessages] After deduplication", {
    original: messagesData.length,
    deduplicated: uniqueMessages.length
  }, logtail);

  const processedMessages = await Promise.all(uniqueMessages.map(async (message) => {
    try {
      const senderEntityId = await entityManager.getOrCreateEntity(
        userId,
        message.integration_id,
        message.sender_entity
      );

      const recipientEntityIds = await entityManager.batchGetOrCreateEntities(
        userId,
        message.integration_id,
        message.recipient_entities
      );

      let threadId: number | null = null;
      if (message.thread_id_3p) {
        try {
            const { data, error } = await supabaseClient
            .from('threads')
            .upsert(
              { 
              thread_id_3p: message.thread_id_3p, 
              integration_id: message.integration_id,
              title: message.subject.substring(0, 255) // Truncate to 255 characters to fit inside the varchar(255) column
              },
              { onConflict: 'thread_id_3p,integration_id' }
            )
            .select('id')
            .single();

          if (error) {
            logWarn("[insertNewMessages] Error upserting thread, proceeding without thread info", 
              { 
                error: error.message, 
                details: error.details,
                hint: error.hint,
                code: error.code,
                threadId3p: message.thread_id_3p, 
                integrationId: message.integration_id 
              }, 
              logtail
            );
          } else if (data) {
            threadId = data.id;
          } else {
            logWarn("[insertNewMessages] No data returned from thread upsert", 
              { threadId3p: message.thread_id_3p, integrationId: message.integration_id }, 
              logtail
            );
          }
        } catch (error) {
          logWarn("[insertNewMessages] Exception during thread upsert", 
            { 
              error: (error as Error).message, 
              stack: (error as Error).stack,
              threadId3p: message.thread_id_3p, 
              integrationId: message.integration_id 
            }, 
            logtail
          );
        }
      }

      let parentMessageId: number | undefined;
      if (message.parent_message_id_3p) {
        const { data: parentMessage } = await supabaseClient
          .from('messages')
          .select('id')
          .eq('id_3p', message.parent_message_id_3p)
          .eq('integration_id', message.integration_id)
          .maybeSingle();
        parentMessageId = parentMessage?.id;
      }

      const { sender_entity, recipient_entities, thread_id_3p, parent_message_id_3p, ...messageWithoutEntities } = message;

      return {
        ...messageWithoutEntities,
        primary_entity_id: senderEntityId,
        entity_ids: recipientEntityIds,
        thread_id: threadId,
        parent_message_id: parentMessageId,
      }
    } catch (error) {
      logError("[insertNewMessages] Error processing message", { 
        error: error instanceof Error ? error.message : String(error), 
        stack: error instanceof Error ? error.stack : undefined,
        messageId: message.id_3p 
      }, logtail);
      return null;
    }
  }));

  const validMessages = processedMessages.filter((message): message is NonNullable<typeof message> => message !== null);

  const { data: upsertedMessages, error } = await supabaseClient
    .from(DB.MESSAGES)
    .upsert(validMessages, {
      onConflict: 'id_3p,integration_id',
      ignoreDuplicates: false
    })
    .select();

  if (error) {
    throw new Error(`Error upserting messages: ${error.message}`);
  }

  return upsertedMessages || [];
}

// Function to update existing messages
async function updateExistingMessages(
  supabaseClient: AttroveSupabaseClient,
  messagesData: Partial<MessagesInsert>[],
  logtail?: Logtail
): Promise<Message[]> {
  const updatedMessages = messagesData.map(message => {
    // Pull out fields that are not part of the updateData; many of these don't get upserted
    const { sender_entity, recipient_entities, thread_id_3p, parent_message_id_3p, thread_position, ...updateData } = message;
    
    // Ensure id_3p and integration_id are always included
    if (!updateData.id_3p || !updateData.integration_id) {
      throw new Error(`Missing required fields id_3p or integration_id for message update`);
    }

    // Only include fields that are present in the updateData
    const filteredUpdateData: Partial<MessagesInsert> = Object.entries(updateData).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        (acc as any)[key] = value;
      }
      return acc;
    }, {} as Partial<MessagesInsert>);

    return filteredUpdateData;
  });

  const { data: upsertedMessages, error } = await supabaseClient
    .from(DB.MESSAGES)
    .upsert(updatedMessages as MessagesInsert[], {
      onConflict: "id_3p,integration_id",
      ignoreDuplicates: true,
    })
    .select();

  if (error) {
    throw new Error(`Error upserting (existing) messages: ${error.message}`);
  }

  return upsertedMessages || [];
}

export async function handleMessages(
  supabaseClient: AttroveSupabaseClient,
  messagesData: (MessagesInsert | Partial<MessagesInsert>)[],
  userId: string,
  forceInsert = false,
  logtail?: Logtail
): Promise<Message[]> {
  const entityManager = new EntityManager(supabaseClient, logtail);

  try {
    if (!messagesData || messagesData.length === 0) {
      logInfo("[handleMessages] No messages to process", {}, logtail);
      return [];
    }

    logInfo("[handleMessages] Starting to process messages", { messageCount: messagesData.length }, logtail);

    const newMessages: MessagesInsert[] = [];
    const existingMessages: Partial<MessagesInsert>[] = [];

    // Separate new messages from updates
    messagesData.forEach(message => {
      if ('brief' in message && !forceInsert) {
        existingMessages.push(message);
      } else if ('sender_entity' in message && 'recipient_entities' in message &&
          'body_html' in message && 'body_text' in message &&
          'global_id_3p' in message && 'id_3p' in message) {
        newMessages.push(message as MessagesInsert);
      } else {
        logWarn("[handleMessages] Skipping invalid message", { message }, logtail);
      }
    });

    if (existingMessages.length > 0) {
      logInfo("[handleMessages] Updating existing messages", { messageCount: existingMessages.length }, logtail);
    }

    if (newMessages.length > 0) {
      logInfo("[handleMessages] Inserting new messages", { messageCount: newMessages.length }, logtail);
    }

    const insertedMessages = newMessages.length > 0
      ? await insertNewMessages(supabaseClient, entityManager, newMessages, userId, logtail)
      : [];

    const updatedMessages = existingMessages.length > 0
      ? await updateExistingMessages(supabaseClient, existingMessages, logtail)
      : [];

    const processedMessages = [...insertedMessages, ...updatedMessages];

    logInfo(`[handleMessages] Successfully processed messages`, {
      insertedCount: insertedMessages.length,
      updatedCount: updatedMessages.length
    }, logtail);

    return processedMessages;
  } catch (error) {
    logError("[handleMessages] Error processing messages", {
      error: error instanceof Error ? error.message : String(error)
    }, logtail);
    throw error;
  } finally {
    entityManager.clearCache();
  }
}

export const getMessagesNew = async (supabaseClient: AttroveSupabaseClient): Promise<Message[]> => {
  const { data: Messages, error: getMessagesError } = await supabaseClient
    .from(DB.MESSAGES)
    .select("*, integrations(*)")
    .is("report_id", null)
    .limit(10);

  if (getMessagesError) {
    console.error(getMessagesError.message);
    throw getMessagesError;
  }
  return Messages;
};

export const MESSAGES_QUERY_KEY = "getMessagesByUser";

export type MessageIntegrationReport = Message & {
  report: Report;
  integration: Integration;
};

export interface EnhancedMessage {
  message_id: number;
  subject: string;
  snippet: string;
  brief: string;
  priority: string;
  received_at: string;
  integration_id: number;
  integration_type: string;
  integration_type_generic: string;
  sender_entity_id: string;
  sender_name: string;
  sender_entity_type: string;
  recipient_entity_ids: string[];
  recipient_names: string[];
  recipient_entity_types: string[];
  topics: string[];
  tags: string[];
  body_text?: string;
  thread_id?: number | null;
  thread_brief?: string | null;
  thread_title?: string | null;
  thread_message_count?: number | null;
  is_thread_root?: boolean;
  thread_position?: number | null;
  parent_message_id?: number | null;
  latest_thread_activity?: string;
  report_id: number | null;
}

export const getMessagesByUser = async (
  supabaseClient: AttroveSupabaseClient,
  filters: FilterOptions = {},
  limit: 1000
): Promise<EnhancedMessage[]> => {
  try {
    const { data: { user }, error: userError } = await supabaseClient.auth.getUser();
    
    if (userError) {
      throw new Error(`Error fetching user: ${userError.message}`);
    }

    if (!user) {
      throw new Error('No authenticated user found');
    }

    // Process tags to ensure they are properly split and trimmed
    const processedTags = filters.tags?.flatMap(tag => 
      tag.includes(',') ? tag.split(',').map(t => t.trim()) : [tag.trim()]
    );

    const { data, error } = await supabaseClient.rpc('get_messages_with_threads', { 
      p_user_id: user.id,
      p_limit: limit,
      p_message_ids: filters.message_ids ? filters.message_ids.map(id => parseInt(id)) : undefined,
      p_entity_id: filters.entity || undefined,
      p_report_id: filters.reports && filters.reports.length > 0 ? parseInt(filters.reports[0]) : undefined,
      p_integration_ids: filters.integrations ? filters.integrations.map(id => parseInt(id)) : undefined,
      p_tags: processedTags || undefined
    });

    if (error) {
      throw new Error(`Error fetching messages: ${error.message}`);
    }

    return data as EnhancedMessage[];
  } catch (error) {
    console.error("[getMessagesByUser] Error in getMessagesByUser", { 
      error: error instanceof Error ? error.message : String(error),
      filters: JSON.stringify(filters)
    });
    throw error;
  }
};

export const ALL_MESSAGES_QUERY_KEY = "getAllMessagesByUser";

export interface FilterOption {
  id: string;
  value: string;
}

export interface FilterOptions {
  reports?: string[];
  integrations?: string[];
  tags?: string[];
  message_ids?: string[];
  event?: string;
  entity?: string;
}

export interface EnhancedFilterOptions {
  reportOptions: FilterOption[];
  integrationOptions: FilterOption[];
  tagOptions: FilterOption[];
  messageOptions: FilterOption[];
  eventOptions: FilterOption[];
  entityOptions: FilterOption[];
}

interface FilterOptionRow {
  integration_id: number | null;
  integration_provider: string | null;
  report_id: number | null;
  report_created_at: string | null;
  tag: string | null;
  tag_name: string | null;
  tag_display: string | null;
  message_id: number | null;
  event_id: number | null;
  entity_id: string | null;
  entity_name: string | null;
}

export async function getAllMessagesByUser(supabase: AttroveSupabaseClient) {
  const result = await supabase.rpc(
    'get_filter_options',
    { p_user_id: (await supabase.auth.getUser()).data.user?.id }
  );

  if (!result.data) throw new Error('No data returned from get_filter_options');

  const data = result.data as FilterOptionRow[];

  // Process the results into options
  const integrationOptions: FilterOption[] = data
    .filter((row: FilterOptionRow) => row.integration_id)
    .map((row: FilterOptionRow) => ({
      id: String(row.integration_id),
      value: row.integration_provider || 'Unknown',
    }))
    .filter((option: FilterOption, index: number, self: FilterOption[]) => 
      index === self.findIndex((o: FilterOption) => o.id === option.id)
    );

  const reportOptions: FilterOption[] = data
    .filter((row: FilterOptionRow) => row.report_id)
    .map((row: FilterOptionRow) => ({
      id: String(row.report_id),
      value: new Date(row.report_created_at || '').toLocaleDateString(),
    }))
    .filter((option: FilterOption, index: number, self: FilterOption[]) => 
      index === self.findIndex((o: FilterOption) => o.id === option.id)
    );

  const tagOptions: FilterOption[] = data
    .filter((row: FilterOptionRow) => row.tag)
    .map((row: FilterOptionRow) => ({
      id: String(row.tag),
      value: row.tag_display || row.tag_name || 'Unknown',
    }))
    .filter((option: FilterOption, index: number, self: FilterOption[]) => 
      index === self.findIndex((o: FilterOption) => o.id === option.id)
    );

  return {
    integrationOptions,
    reportOptions,
    tagOptions,
  };
}

export const getMessagesByReport = async (supabaseClient: AttroveSupabaseClient, reportId: string): Promise<Message[]> => {
  const { data: Messages, error: getMessagesError } = await supabaseClient.from(DB.MESSAGES).select("*").eq("report_id", reportId);

  if (getMessagesError) {
    console.error(getMessagesError.message);
    throw getMessagesError;
  }
  return Messages;
};

export const getMessageById = async (supabaseClient: AttroveSupabaseClient, messageId: string): Promise<Message[]> => {
  const { data: Messages, error: getMessagesError } = await supabaseClient.from(DB.MESSAGES).select("*").eq("id", messageId);

  if (getMessagesError) {
    console.error(getMessagesError.message);
    throw getMessagesError;
  }
  return Messages;
};

export const getLastMessage = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  logtail?: Logtail,
): Promise<Message | null> => {
  const { data: message, error: getMessageError } = await supabaseClient
    .from(DB.MESSAGES)
    .select("*")
    .eq("integration_id", integrationId)
    .order("received_at", { ascending: false })
    .limit(1)
    .maybeSingle();

  if (getMessageError) {
    logError(
      "[getLastMessage] error getting last message",
      {
        error: getMessageError,
        integrationId,
      },
      logtail,
    );
    return null;
  }
  return message;
};

export const updateMessagesReportId = async (
  supabaseClient: AttroveSupabaseClient,
  messageIds: number[],
  reportId: number,
): Promise<void> => {
  const { error } = await supabaseClient.rpc("update_messages_report_id", { ids: messageIds, new_report_id: reportId });

  if (error) {
    console.error(error.message);
    throw error;
  }
  return;
};

export async function getMessagesForIntegration(
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  lastReportDate: string,
  limit = 100,
  logtail?: Logtail,
): Promise<Message[]> {
  logInfo(`[getMessagesForIntegration] Fetching messages for integration ${integrationId}`, {}, logtail);

  try {
    const {
      data: messages,
      error,
      count,
    } = await supabaseClient
      .from(DB.MESSAGES)
      .select("*", { count: "exact" })
      .eq("integration_id", integrationId)
      .gt("received_at", lastReportDate)
      .order("received_at", { ascending: false })
      .limit(limit);

    if (error) {
      throw new Error(`Error fetching messages: ${error.message}`);
    }

    if (!messages || messages.length === 0) {
      logInfo(`[getMessagesForIntegration] No new messages found for integration ${integrationId}`, {}, logtail);
      return [];
    }

    logInfo(
      `[getMessagesForIntegration] Successfully fetched ${messages.length} messages for integration ${integrationId}`,
      { messageCount: messages.length, totalCount: count },
      logtail,
    );

    return messages;
  } catch (error) {
    logError(
      `[getMessagesForIntegration] Error fetching messages for integration ${integrationId}`,
      { error: error instanceof Error ? error.message : "Unknown error" },
      logtail,
    );
    throw error;
  }
}

export const MESSAGES_COUNT_QUERY_KEY = "getMessageCountForIntegration";

export const getMessageCountForIntegration = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number
): Promise<number> => {
  const { count, error } = await supabaseClient
    .from(DB.MESSAGES)
    .select('id', { count: 'exact', head: true })
    .eq('integration_id', integrationId);

  if (error) {
    console.error("Error fetching message count for integration:", error);
    throw error;
  }

  return count ?? 0;
};

export const GET_MESSAGES_FOR_EVENTS_QUERY_KEY = "getMessagesForEvents";
type EventMessage = {
  event_id: number;
  message_ids: number[];
};

export type EventMessagesResponse = EventMessage[] | null;

export const getMessagesForEvents = async (supabaseClient: AttroveSupabaseClient, eventIds: number[]): Promise<EventMessagesResponse> => {
  const { data, error } = await supabaseClient.rpc("get_messages_for_events", { event_ids: eventIds });

  if (error) throw error;
  return data;
};

//
// Threads
//
export async function getMessagesInThread(
  supabaseClient: AttroveSupabaseClient,
  threadId: number,
  limit: number,
  offset: number
): Promise<Message[]> {
  const { data: messages, error } = await supabaseClient
    .from(DB.MESSAGES)
    .select('*')
    .eq('thread_id', threadId)
    .order('thread_position', { ascending: true })
    .range(offset, offset + limit - 1);

  if (error) {
    throw new Error(`Error fetching messages in thread: ${error.message}`);
  }

  return messages || [];
}

// Add a new function to get thread summary
export async function getThreadSummary(
  supabaseClient: AttroveSupabaseClient,
  threadId: number
): Promise<{
  threadId: number;
  title: string;
  messageCount: number;
  lastMessageDate: string;
  participants: string[];
}> {
  const { data: thread, error: threadError } = await supabaseClient
    .from('threads')
    .select('*')
    .eq('id', threadId)
    .single();

  if (threadError) {
    throw new Error(`Error fetching thread: ${threadError.message}`);
  }

  const { data: messages, error: messagesError } = await supabaseClient
    .from(DB.MESSAGES)
    .select('id, received_at, primary_entity_id')
    .eq('thread_id', threadId)
    .order('received_at', { ascending: false });

  if (messagesError) {
    throw new Error(`Error fetching messages for thread summary: ${messagesError.message}`);
  }

  const uniqueParticipants = new Set(messages?.map(m => m.primary_entity_id) || []);

  return {
    threadId: thread.id,
    title: thread.title || 'Untitled Thread',
    messageCount: messages?.length || 0,
    lastMessageDate: messages?.[0]?.received_at ?? thread.updated_at ?? '',
    participants: Array.from(uniqueParticipants).filter((participant): participant is string => participant !== null),
  };
}

export async function getMessagesByEntity(
  supabaseClient: AttroveSupabaseClient,
  entityId: string,
  startDate: Date,
  limit: number,
  logtail?: Logtail
): Promise<EnhancedMessage[]> {
  try {
    const { data, error } = await supabaseClient.rpc('get_messages_by_entity', { 
      p_entity_id: entityId,
      p_start_date: startDate.toISOString(),
      p_limit: limit
    });

    if (error) {
      throw error;
    }

    if (!Array.isArray(data)) {
      throw new Error('Unexpected data format returned from get_messages_by_entity');
    }

    return data as unknown as EnhancedMessage[];
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    const errorDetails = error instanceof Object ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : 'No additional details';
    
    logError("[getMessagesByEntity] Error fetching messages", { 
      error: errorMessage,
      details: errorDetails,
      entityId,
      startDate: startDate.toISOString()
    }, logtail);
    
    throw error;
  }
}

export interface ReportTopics {
  report_id: number;
  topics: string[];
}

/**
 * Get unique tags for a report by aggregating message topics
 */
export const getReportTopics = async (
  supabaseClient: AttroveSupabaseClient,
  reportId: number,
  logtail?: Logtail
): Promise<ReportTopics> => {
  try {
    const { data, error } = await supabaseClient.rpc('get_report_tags', {
      p_report_id: reportId
    });

    if (error) {
      logError('[getReportTopics] Database error:', {error}, logtail);
      throw error;
    }

    // If no tags found, return empty array
    if (!data || !Array.isArray(data)) {
      return {
        report_id: reportId,
        topics: []
      };
    }

    return {
      report_id: reportId,
      topics: data as string[]
    };
  } catch (error) {
    logError('[getReportTopics] Error fetching report topics:', {error}, logtail);
    throw new Error(`Failed to fetch tags for report ${reportId}: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};
