import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { toTimeAgo } from "@attrove/attrove-ui/lib/utils";
import {
  GET_CONVERSATIONS_FOR_INTEGRATION_QUERY_KEY,
  getConversationsForIntegration,
  updateConversationPreferences,
  MESSAGES_COUNT_QUERY_KEY,
  getMessageCountForIntegration,
  GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY,
  getIntegrationsWithCredById
} from "@attrove/service-supabase";
import { Checkbox, Icons } from "@attrove/ui-shadcn";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";
import { IntegrationActions } from "../integration-actions";

export const ChatDetail: React.FC<{ integrationId: number }> = ({ integrationId }) => {

    const { data: integration, isLoading } = useQuery({
        queryKey: [GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY, integrationId],
        queryFn: () => getIntegrationsWithCredById(supabase, Number(integrationId)),
      });

    const { data: conversations, isLoading: isConversationsLoading } = useQuery({
        queryKey: [GET_CONVERSATIONS_FOR_INTEGRATION_QUERY_KEY, integrationId],
        queryFn: () => getConversationsForIntegration(supabase, integrationId),
      });

  const { data: messageCount, isLoading: messagesLoading } = useQuery({
    queryKey: [MESSAGES_COUNT_QUERY_KEY, integrationId],
    queryFn: () => getMessageCountForIntegration(supabase, integrationId),
  });

  const toggleImportMessages = useMutation({
    mutationFn: async ({ conversationId, importMessages }: { conversationId: number; importMessages: boolean }) => {
      return await updateConversationPreferences(supabase, [{ id: conversationId, import_messages: importMessages }]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CONVERSATIONS_FOR_INTEGRATION_QUERY_KEY, integrationId] });
    },
  });

  if (isLoading || messagesLoading) return <p>Loading...</p>;
  if (!integration || !messageCount) return null;

  function removeConversations<T extends { conversations?: any }>(obj: T): Omit<T, "conversations"> {
    const { conversations, ...rest } = obj;
    return rest;
  }

  const integrationWithoutConvs = removeConversations(integration);

  const SlackIcon = Icons["slack"];

  return (
    <div className="pt-6">
      <div className="mx-auto max-w-7xl flex gap-2 px-4 sm:px-6 md:px-8">
        <SlackIcon className="w-8 h-8" />
        <h1 className="text-2xl">Slack</h1>
      </div>
      <div className="mx-auto max-w-7xl flex flex-col gap-4 px-4 pt-6 sm:px-6 md:px-8">
        <p className="text-muted-foreground">
          We are importing your Slack messages from selected channels, direct messages, and group messages.
        </p>
        <div className="flex gap-8">
          <div className="flex flex-col gap-4 rounded-lg border p-4 w-1/2 relative">
            <div className="absolute top-1 right-3">
              {integration.is_active ? (
                <span className="inline-block bg-green-500 h-2.5 w-2.5 rounded-full" />
              ) : (
                <span className="inline-block bg-red-500 h-2.5 w-2.5 rounded-full" />
              )}
            </div>
            <div className="flex flex-col gap-1">
              <h2 className="text-xl">Integration</h2>
              {integration.last_synced_at && (
                <div className="flex gap-1 text-sm text-muted-foreground">
                  <p>Last sync: </p>
                  {integration.pending_sync ? (
                    <div className="flex items-center gap-2">
                      <p>currently syncing</p>
                      <Loader2 className="w-4 h-4 animate-spin" />
                    </div>
                  ) : (
                    <p> {toTimeAgo(integration.last_synced_at)}</p>
                  )}
                </div>
              )}
            </div>
            <p>{messageCount} messages imported</p>
            <IntegrationActions integration={integrationWithoutConvs} />
          </div>
          <div className="flex flex-col gap-4 rounded-lg border p-4 w-1/2">
                <h2 className="text-xl">Select Conversations to Import</h2>
                {conversations?.map((conversation) => (
                <div key={conversation.id}>
                    <div className="flex items-center gap-2">
                    <Checkbox
                        id={`conversation-${conversation.id}`}
                        checked={conversation.import_messages}
                        onCheckedChange={(checked: boolean) => 
                        toggleImportMessages.mutate({ conversationId: conversation.id, importMessages: checked })
                        }
                        disabled={conversation.conversation_type === 'channel'}
                    />
                    <label htmlFor={`conversation-${conversation.id}`} className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        {conversation.title}
                    </label>
                    </div>
                    <p className="text-sm text-muted-foreground pl-6">
                    {conversation.conversation_type === 'channel' ? '#' : ''}
                    {conversation.conversation_type}
                    </p>
                </div>
                ))}
            </div>
        </div>
      </div>
    </div>
  );
};