export const DB = {
  USERS: "users",
  ENTITY: "entity",
  ENTITY_IDENTITY: "entity_identity",
  ENTITY_RELATION: "entity_relation",
  CUSTOMERS: "customers",
  PRICES: "prices",
  PRODUCTS: "products",
  SUBSCRIPTIONS: "subscriptions",
  INTEGRATIONS: "integrations",
  INTEGRATIONS_CREDENTIALS: "integrations_credentials",
  MESSAGES: "messages",
  REPORTS: "reports",
  CALENDARS: "calendars",
  EVENTS: "events",
  MEETINGS: "meetings",
  CONVERSATIONS: "conversations",
  NOTIFICATIONS: "notifications",
  NOTIFICATION_DELIVERIES: "notification_deliveries",
  USER_NOTIFICATION_PREFERENCES: "user_notification_preferences",
  NOTIFICATION_CHANNELS: "notification_channels",
  NOTIFICATION_TYPES: "notification_types",
  USER_GOALS: "user_goals",
} as const;
