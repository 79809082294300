import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { GET_USER_QUERY_KEY, updateUser } from "@attrove/service-supabase";
import { Button, Progress } from "@attrove/ui-shadcn";
import { useMutation } from "@tanstack/react-query";
import { createContext, useContext, useState, useRef } from "react";
import { Step1 } from "./step1";
import { Step2, Step2Ref } from "./step2";
import { Step3 } from "./step3";
import { Step4 } from "./step4";
import { Step5 } from "./step5";

interface DisabledContextType {
  setPreviousDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setNextDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const DisabledContext = createContext<DisabledContextType | undefined>(undefined);

export const useDisabled = () => {
  const context = useContext(DisabledContext);
  if (context === undefined) {
    throw new Error("useDisabled must be used within a DisabledProvider");
  }
  return context;
};

export function Onboarding() {
  const TOTAL_STEPS = 5;
  const PROGRESS_PER_STEP = 100 / TOTAL_STEPS;

  const { user } = useSession();

  const [currentStep, setCurrentStep] = useState(user.onboarding_step);
  const [previousDisabled, setPreviousDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const step2Ref = useRef<Step2Ref>(null);

  const updateOnboardingStep = useMutation({
    mutationFn: async (step: number) => {
      return await updateUser(supabase, user.id, { onboarding_step: step });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_USER_QUERY_KEY] });
    },
  });

  const handleNext = async () => {
    if (currentStep === 2) {
      if (step2Ref.current) {
        const formData = step2Ref.current.getFormData();
        
        // Update user profile
        await updateUser(supabase, user.id, {
          full_name: formData.fullName,
          preferred_name: formData.preferredName,
          role: formData.role
        });

        // Create goal if provided
        if (formData.currentGoal) {
          await supabase.rpc('create_user_goal', {
            _goal_text: formData.currentGoal,
          });
        }
      }
    }

    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    updateOnboardingStep.mutate(nextStep);
  };

  const handlePrevious = () => {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
    updateOnboardingStep.mutate(prevStep);
  };

  if (currentStep === TOTAL_STEPS + 1) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center">
      <div className="w-full h-[100dvh] md:w-full md:max-w-lg md:h-auto md:max-h-[90vh] md:rounded-lg flex flex-col bg-background">
        <div className="flex-shrink-0 p-3 border-b">
          <div className="flex flex-col gap-2">
            <h2 className="text-lg font-semibold">
              Step {currentStep} of {TOTAL_STEPS}
            </h2>
            <Progress value={currentStep * PROGRESS_PER_STEP} className="h-2 w-full" />
          </div>
        </div>

        <div className="flex-grow overflow-y-scroll p-3">
          <DisabledContext.Provider value={{ setPreviousDisabled, setNextDisabled }}>
            {currentStep === 1 && <Step1 />}
            {currentStep === 2 && <Step2 ref={step2Ref} />}
            {currentStep === 3 && <Step3 setCurrentStep={setCurrentStep} />}
            {currentStep === 4 && <Step4 />}
            {currentStep === 5 && <Step5 />}
          </DisabledContext.Provider>
        </div>

        {currentStep !== 3 && currentStep !== TOTAL_STEPS && (
          <div className="flex-shrink-0 p-3 border-t bg-background sticky bottom-0 left-0 right-0">
            <div className="flex justify-end gap-2">
              {currentStep > 1 && (
                <Button
                  variant="outline"
                  type="button"
                  disabled={previousDisabled || currentStep === 1}
                  onClick={handlePrevious}
                  className="flex-grow md:flex-grow-0"
                >
                  Back
                </Button>
              )}
              <Button 
                variant="default" 
                disabled={nextDisabled} 
                onClick={handleNext}
                className="flex-grow md:flex-grow-0"
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}