import { LRUCache } from 'lru-cache';

/**
 * A caching utility that provides in-memory storage with LRU (Least Recently Used) eviction.
 * Features:
 * - Configurable maximum size and TTL (Time To Live) for cached items
 * - Tracks in-flight requests to prevent duplicate API calls
 * - Request timeout protection for in-flight requests
 * - Cache hit/miss statistics
 * - LRU eviction strategy using lru-cache package
 * 
 * The cache handles both immediate values and promises, making it suitable for
 * caching both static data and async request results.
 */
interface CacheItem<T> {
  entry: T;
  timestamp: number;
}

export class LocalCache<T> {
  private cache: LRUCache<string, CacheItem<T>>;
  private inFlightRequests: Map<string, Promise<T>> = new Map();
  private hits = 0;
  private misses = 0;

  /**
   * Creates a new LocalCache instance
   * @param max_elems Maximum number of elements to store in cache (default: 5000)
   * @param ttl Time-to-live in milliseconds for cached items (default: 5 minutes)
   */
  constructor(max_elems = 50000, ttl = 5 * 60 * 1000) {
    this.cache = new LRUCache<string, CacheItem<T>>({
      max: max_elems,
      ttl: ttl,
      updateAgeOnGet: true,
    });
  }

  /**
   * Retrieves an item from the cache
   * @param key The cache key to look up
   * @returns The cached value, in-flight promise, or null if not found
   */
  get(key: string): T | Promise<T> | null {
    
    // Check memory cache first
    const item = this.cache.get(key);
    if (item) {
      this.hits++;
      return item.entry;
    }

    // Check for in-flight request
    const inFlightRequest = this.inFlightRequests.get(key);
    if (inFlightRequest) {
      this.hits++;
      return inFlightRequest;
    }

    this.misses++;
    return null;
  }

  /**
   * Stores an item in the cache
   * @param key The cache key
   * @param entry The value to store
   */
  set(key: string, entry: T): void {
    this.cache.set(key, { 
      entry, 
      timestamp: Date.now() 
    });
  }

  /**
   * Tracks an in-flight request and adds timeout protection
   * @param key The cache key
   * @param promise The promise to track
   * @param timeout Set to greater than 0 to create maximum time to wait in milliseconds (default: 0)
   * @returns A wrapped promise that includes timeout protection
   * @throws Error if the request exceeds the timeout
   */
  setInFlightRequest(key: string, promise: Promise<T>, timeout = 0): Promise<T> {
    
    // Check if timeout is less than 1, skip timeout wrapper if true
    const wrappedPromise = timeout < 1 ? promise : Promise.race([
      promise,
      new Promise<T>((_, reject) => 
        setTimeout(() => reject(new Error('In Flight Request timeout')), timeout)
      )
    ]);

    wrappedPromise.finally(() => {
      this.inFlightRequests.delete(key);
    });

    this.inFlightRequests.set(key, wrappedPromise);
    return wrappedPromise;
  }

  /**
   * Clears all items from the cache
   */
  clear(): void {
    this.cache.clear();
    this.inFlightRequests.clear();
  }

  /**
   * Returns current cache statistics
   * @returns Object containing cache size, hits, and misses
   */
  getStats(): { size: number, hits: number, misses: number } {
    return {
      size: this.cache.size,
      hits: this.hits,
      misses: this.misses,
    };
  }

  /**
   * Resets the hit/miss statistics counters to zero
   */
  clearStats(): void {
        this.hits = 0;
        this.misses = 0;
    } 
    
  }

  