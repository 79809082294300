import { useContext, useEffect, useState } from "react";
import { NewIntegrationCard } from "@attrove/attrove-ui/components/integrations/new-integration-card";
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { useNavigate, useSearchParams } from "react-router-dom";

export const GoogleMeetIntegration: React.FC<{
  onSuccess?: () => void;
}> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const axiosClient = useContext(AxiosClientContext);
  const navigate = useNavigate();

  const provider = searchParams.get("provider");
  const code = searchParams.get("code");

  useEffect(() => {
    async function handleGoogleMeetCallback() {
      try {
        const response = await axiosClient.get(`/integrations/new?provider=google_meet&code=${code}`);
      } catch (error) {
        console.log("error", error);
      }

      navigate("/integrations");
    }

    if (provider === "google_meet" && code) {
      setLoading(false);
      handleGoogleMeetCallback();
    }
  }, [provider, code]);

  const handleGoogleMeetIntegration = async () => {
    setLoading(true);

    const response = await axiosClient.get("/integrations/new?provider=google_meet");
    console.log("response", response.data);
    if (response.status === 200 && response.data.authUrl) {
      window.location.href = response.data.authUrl;
    }
  };

  return (
    <NewIntegrationCard
      provider={"google_meet"}
      name={"Google Meet"}
      loading={loading}
      onClick={handleGoogleMeetIntegration}
    />
  );
};