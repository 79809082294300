import { Input, Label } from "@attrove/ui-shadcn";

export interface OnboardingInputProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
  placeholder: string;
}

export const OnboardingInput: React.FC<OnboardingInputProps> = ({ 
  value, 
  onChange, 
  label, 
  placeholder 
}) => {
  return (
    <div className="flex flex-col gap-2">
      <Label>{label}</Label>
      <Input 
        placeholder={placeholder} 
        value={value} 
        onChange={(e) => onChange(e.target.value)} 
      />
    </div>
  );
}; 