import React, { useState, useMemo, useCallback } from "react";
import { ColumnFiltersState, useReactTable, getCoreRowModel, getFilteredRowModel } from "@tanstack/react-table";
import { Avatar, AvatarFallback, Button } from "@attrove/ui-shadcn";
import { ChevronDown, Clock } from "lucide-react";
import { MessageFilters } from "@attrove/attrove-ui/features/messages/filtering/filtering";
// import { OptionsType } from "@attrove/attrove-ui/app/pages/dashboard/messages";
import { VirtualMessagesList } from "@attrove/attrove-ui/features/messages/virtual-message-list";
import { useSearchParams } from "react-router-dom";
import { customGlobalFilter } from "@attrove/attrove-ui/features/messages/filtering/global-filter";
import { createColumns } from "@attrove/attrove-ui/features/messages/columns";
import { DetailSidePanel } from "../../components/layouts/detail-side-panel";
import { getInitials } from "@attrove/attrove-ui/lib/utils";
import EmailReplyParser from "email-reply-parser-browser";
import { motion, AnimatePresence } from "framer-motion";
import { EnhancedMessage } from "@attrove/service-supabase";

interface ParsedContent {
  visibleContent: string;
  hiddenContent: string;
  author?: string;
}

const QuotedContent: React.FC<{
  hiddenContent: string;
  author?: string;
  isExpanded: boolean;
  onToggle: () => void;
}> = ({ hiddenContent, author, isExpanded, onToggle }) => {
  if (!hiddenContent) return null;

  return (
    <div className="mt-4">
      {!isExpanded && (
        <Button variant="ghost" size="sm" onClick={onToggle} className="text-blue-600 hover:text-blue-800 flex items-center">
          Show more from {author || "previous message"}
          <ChevronDown className="ml-1 h-4 w-4" />
        </Button>
      )}

      <AnimatePresence>
        {isExpanded && (
          <>
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="border-l-2 border-gray-200 pl-4 mt-2"
            >
              <div className="text-sm text-gray-600 whitespace-pre-wrap">{hiddenContent}</div>
            </motion.div>
            <Button variant="ghost" size="sm" onClick={onToggle} className="mt-2 text-blue-600 hover:text-blue-800">
              Show less
              <ChevronDown className="ml-1 h-4 w-4 transform rotate-180" />
            </Button>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

const parseEmailContent = (content: string): ParsedContent => {
  try {
    // Create a new instance using the default import
    const parser = new EmailReplyParser();
    const email = parser.read(content);

    // Get visible text (non-quoted parts)
    const visibleContent = email.getVisibleText();

    // Get hidden text (quoted parts)
    const fragments = email.getFragments();
    const hiddenContent = fragments
      .filter((fragment) => fragment.isQuoted())
      .map((fragment) => fragment.getContent())
      .join("\n");

    return {
      visibleContent,
      hiddenContent,
      author: fragments[0]?.getContent()?.match(/On .* wrote:/)?.[0],
    };
  } catch (error) {
    console.error("Error parsing email content:", error);
    // Fallback to showing the entire content if parsing fails
    return {
      visibleContent: content,
      hiddenContent: "",
      author: undefined,
    };
  }
};

export function ThreadedMessagesComponent<TData extends EnhancedMessage, TValue>({
  data,
  options,
  entityData,
  queryParams,
  allFilterOptions,
}: ThreadedMessagesProps<TData, TValue>) {
  const [setSearchParams] = useSearchParams();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedThread, setSelectedThread] = useState<EnhancedMessage[] | null>(null);
  const [expandedMessages, setExpandedMessages] = useState<Set<string>>(new Set());

  const processedData = useMemo(() => {
    const threadMap = new Map<number, TData[]>();
    const displayMessages: TData[] = [];
    
    // First, group all messages by thread_id
    data.forEach((message) => {
      if (message.thread_id) {
        if (!threadMap.has(message.thread_id)) {
          threadMap.set(message.thread_id, []);
        }
        threadMap.get(message.thread_id)!.push(message);
      } else {
        // Messages without thread_id are treated as single-message threads
        displayMessages.push(message);
      }
    });

    // For each thread, find the most recent message
    threadMap.forEach((messages, threadId) => {
      // Sort messages by received_at in descending order
      const sortedMessages = messages.sort((a, b) => {
        const dateA = new Date(a.received_at).getTime();
        const dateB = new Date(b.received_at).getTime();
        return dateB - dateA;
      });

      // Get the most recent message
      const mostRecentMessage = sortedMessages[0];

      // Enhance the most recent message with thread information
      const enhancedMessage = {
        ...mostRecentMessage,
        thread_message_count: messages.length,
        // Preserve the original thread_title from the root message if it exists
        thread_title: messages.find(m => m.is_thread_root)?.thread_title || mostRecentMessage.thread_title,
        // Keep track of latest activity
        latest_thread_activity: mostRecentMessage.received_at
      };

      displayMessages.push(enhancedMessage as TData);
    });

    // Sort all display messages by latest activity/received date
    const sortedDisplayMessages = displayMessages.sort((a, b) => {
      const dateA = new Date(a.latest_thread_activity || a.received_at).getTime();
      const dateB = new Date(b.latest_thread_activity || b.received_at).getTime();
      return dateB - dateA;
    });

    return {
      rootMessages: sortedDisplayMessages,
      threadMap,
    };
  }, [data]);

  const handleEntityFilter = useCallback(
    (entityId: string) => {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        if (entityId && entityData[entityId]) {
          newParams.set("entity", entityId);
        } else {
          newParams.delete("entity");
        }
        return newParams;
      });
    },
    [entityData, setSearchParams],
  );

  const handleRowClick = useCallback(
    (message: EnhancedMessage) => {
      const threadMessages = message.thread_id ? processedData.threadMap.get(message.thread_id) || [message] : [message];
      setSelectedThread(threadMessages.sort((a, b) => new Date(b.received_at).getTime() - new Date(a.received_at).getTime()));
    },
    [processedData.threadMap],
  );

  const columns = useMemo(() => createColumns(handleEntityFilter, handleRowClick), [handleEntityFilter, handleRowClick]);

  const table = useReactTable({
    data: processedData.rootMessages,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    state: {
      columnFilters,
    },
    filterFns: {
      custom: customGlobalFilter,
    },
  });

  const toggleMessageExpansion = useCallback((messageId: string) => {
    setExpandedMessages((prev) => {
      const next = new Set(prev);
      if (next.has(messageId)) {
        next.delete(messageId);
      } else {
        next.add(messageId);
      }
      return next;
    });
  }, []);

  return (
    <div className="w-full relative">
      <MessageFilters
        options={options}
        table={table}
        entityData={entityData}
        queryParams={queryParams}
        handleEntityFilter={handleEntityFilter}
      />

      <div className="rounded-md border">
        <VirtualMessagesList table={table} columns={columns} processedData={processedData} />
      </div>

      <DetailSidePanel
        isOpen={!!selectedThread}
        onClose={() => setSelectedThread(null)}
        title={selectedThread?.[0]?.subject || "Message Details"}
      >
        {selectedThread && (
          <div className="space-y-6">
            {selectedThread.map((message) => {
              const { visibleContent, hiddenContent, author } = parseEmailContent(message.body_text);
              const isExpanded = expandedMessages.has(message.message_id);

              return (
                <div key={message.message_id} className="group pb-6 border-b last:border-b-0 animate-in fade-in-50">
                  <div className="flex items-center gap-4 mb-4">
                    <Avatar className="h-10 w-10 ring-2 ring-background">
                      <AvatarFallback className="bg-primary/10 text-primary">{getInitials(message.sender_name)}</AvatarFallback>
                    </Avatar>
                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <p className="font-medium text-foreground">{message.sender_name}</p>
                        <div className="flex items-center gap-2 text-muted-foreground">
                          <Clock className="h-3 w-3" />
                          <p className="text-sm">{new Date(message.received_at).toLocaleString()}</p>
                        </div>
                      </div>
                      <p className="text-sm text-muted-foreground">to {message.recipient_names || "recipients"}</p>
                    </div>
                  </div>
                  <div className="text-sm leading-relaxed pl-14">
                    <div className="whitespace-pre-wrap text-foreground">{visibleContent}</div>
                    {hiddenContent && (
                      <QuotedContent
                        hiddenContent={hiddenContent}
                        author={author}
                        isExpanded={isExpanded}
                        onToggle={() => toggleMessageExpansion(message.message_id)}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </DetailSidePanel>
    </div>
  );
}
