import React from "react";
import { useSearchParams } from "react-router-dom";
import { TopEntity, PeopleOptionsType } from "@attrove/attrove-ui/app/pages/dashboard/people";
import { FilterFn } from "@tanstack/react-table";

import {
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@attrove/ui-shadcn";
import { Table } from "@tanstack/react-table";
import { FilterSelect } from "./filter-select";
import { RenderTags } from "./render-tags";

interface PeopleFiltersProps {
  options: PeopleOptionsType;
  table: Table<TopEntity>;
  onTimePeriodChange: (value: string) => void;
  timePeriod: string;
}

export const tagFilterFn: FilterFn<TopEntity> = (row, columnId, filterValue: string[]) => {
  const tags = row.getValue(columnId) as string[];
  return filterValue.length === 0 || filterValue.some((tag) => tags.includes(tag));
};

export function PeopleFilters({ table, options, onTimePeriodChange, timePeriod }: PeopleFiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize filter from URL params
  React.useEffect(() => {
    const entityFilter = searchParams.get("entity_name");
    if (entityFilter) {
      table.getColumn("entity_name")?.setFilterValue(entityFilter);
    }
  }, [searchParams, table]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    table.getColumn("entity_name")?.setFilterValue(value);
    
    // Update URL params
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      if (value) {
        newParams.set("entity_name", value);
      } else {
        newParams.delete("entity_name");
      }
      return newParams;
    });
  };

  return (
    <div className="border-b pb-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col lg:flex-row lg:items-center gap-4 lg:justify-between">
          <div className="flex flex-col lg:flex-row gap-4 lg:items-center">
            <div className="relative w-full lg:w-80">
              <Input
                value={(table.getColumn("entity_name")?.getFilterValue() as string) ?? ""}
                onChange={handleInputChange}
                className="w-60 sm:w-80"
                placeholder="Filter people"
              />
            </div>
            <div className="flex gap-2 items-center">
              <FilterSelect options={options} title="Tags" urlParam="tags" table={table} />
              <Select onValueChange={onTimePeriodChange} value={timePeriod}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select time period" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="1">Last 24 hours</SelectItem>
                  <SelectItem value="7">Last 7 days</SelectItem>
                  <SelectItem value="30">Last 30 days</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
        <div className="flex-wrap w-full flex items-center gap-2">
          <RenderTags options={options} title="Tag" urlParam="tags" table={table} />
        </div>
      </div>
    </div>
  );
}
