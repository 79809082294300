import React from 'react';
import { X, ChevronLeft } from 'lucide-react';
import { cn, Button, ScrollArea } from "@attrove/ui-shadcn";

interface DetailSidePanelProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  className?: string;
}

const DesktopDetailSidePanel: React.FC<DetailSidePanelProps> = ({ isOpen, onClose, title, children, className }) => {
  return (
    <div
      className={cn(
        "fixed inset-y-0 right-0 bg-background border-l border-border shadow-lg transform transition-transform duration-300 ease-in-out z-50",
        "w-[400px]",
        isOpen ? "translate-x-0" : "translate-x-full",
        className
      )}
    >
      <div className="h-full flex flex-col">
        <div className="flex items-center justify-between px-6 py-4 border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 sticky top-0 z-10">
          <h2 className="text-lg font-semibold truncate pr-4">{title}</h2>
          <Button 
            variant="ghost" 
            size="icon" 
            onClick={onClose}
            className="shrink-0"
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
        <ScrollArea className="flex-grow">
          <div className="p-6">
            {children}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};

const MobileDetailSidePanel: React.FC<DetailSidePanelProps> = ({ isOpen, onClose, title, children, className }) => {
  return (
    <>
      <div
        className={cn(
          "fixed inset-0 bg-background/80 backdrop-blur-sm z-40 transition-opacity duration-300",
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        )}
        onClick={onClose}
      />
      <div
        className={cn(
          "fixed inset-0 bg-background shadow-lg transform transition-transform duration-300 ease-in-out z-50",
          isOpen ? "translate-x-0" : "translate-x-full",
          className
        )}
      >
        <div className="h-full flex flex-col">
          <div className="flex items-center px-4 py-4 border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 sticky top-0 z-10">
            <Button 
              variant="ghost" 
              size="icon" 
              onClick={onClose} 
              className="mr-2 shrink-0"
            >
              <ChevronLeft className="h-5 w-5" />
            </Button>
            <h2 className="text-lg font-semibold truncate">{title}</h2>
          </div>
          <ScrollArea className="flex-grow">
            <div className="p-4">
              {children}
            </div>
          </ScrollArea>
        </div>
      </div>
    </>
  );
};

export const DetailSidePanel: React.FC<DetailSidePanelProps> = (props) => {
  return (
    <>
      <DesktopDetailSidePanel {...props} className={cn(props.className, "hidden md:block")} />
      <MobileDetailSidePanel {...props} className={cn(props.className, "md:hidden")} />
    </>
  );
};

export default DetailSidePanel;