import { AttroveSupabaseClient } from "@attrove/service-supabase";

export function utilTags(): string {
  return "util-tags";
}

type TrendDirection = 'up' | 'down' | 'stable' | 'new';
type TrendImportance = 'high' | 'medium' | 'low';

export interface MetaTagStats {
  total_mentions: number;
  unique_participants: number;
}

export interface GetTrendingTagsParams {
  supabase: AttroveSupabaseClient;
  start_date: string;
  end_date?: string;
  max_limit?: number;
}

export interface RelatedPerson {
  id: string;
  name: string;
  message_count: number;
  last_occurrence: string;
  avatar_url?: string;
}

interface TagCountsRow {
  tag_id: string;
  formatted_name: string;
  count: number;
  occurrences: string[];
  decayed_importance: number;
  is_muted: boolean;
}

interface TagDetailsResponse {
  out_integration_id: number;
  out_integration_type: string;
  out_message_count: number;
  out_positive_count: number;
  out_neutral_count: number;
  out_negative_count: number;
  out_recent_messages: {
    id: string;
    content: string;
    sender: string;
    timestamp: string;
    integration: string;
  }[];
}

export interface TagDetail extends TagTrend {
  integration_stats: {
    name: string;
    count: number;
    color: string;
    icon: string;
  }[];
  sentiment: {
    positive: number;
    neutral: number;
    negative: number;
  };
  recent_messages: {
    id: string;
    content: string;
    sender: string;
    timestamp: string;
    integration: string;
  }[];
}

const INTEGRATION_METADATA = {
  'slack': { color: '#4A154B', icon: 'slack' },
  'gmail': { color: '#EA4335', icon: 'gmail' },
  'outlook': { color: '#0078D4', icon: 'outlook' },
  'default': { color: '#666666', icon: 'message' }
} as const;

export class TagTrend {
  id: string;
  name: string;
  count: number;
  trend_direction: TrendDirection;
  trend_percentage: number;
  category: string;
  importance: TrendImportance;
  last_activity: string;
  is_muted: boolean;
  top_tag_entities: RelatedPerson[];
  private occurrences: string[];
  private decayed_importance: number;
  private startDate: Date;
  private endDate: Date;

  constructor(row: TagCountsRow, startDate: string, endDate: string) {
    console.log("TagTrend constructor called with row:", row);

    if (!row.occurrences?.length) {
      throw new Error(`Tag Trend: Tag ${row.formatted_name} (${row.tag_id}) has no occurrence dates`);
    }

    this.id = row.tag_id;
    this.name = row.formatted_name;
    this.count = row.count;
    this.category = "normal";
    this.is_muted = row.is_muted;
    this.occurrences = row.occurrences;
    this.decayed_importance = row.decayed_importance;
    this.last_activity = row.occurrences[row.occurrences.length - 1];
    this.startDate = new Date(startDate);
    this.endDate = new Date(endDate);
    this.top_tag_entities = [];

    if (this.decayed_importance >= 0.6) {
      this.importance = 'high';
    } else if (this.decayed_importance >= 0.53) {
      this.importance = 'medium';
    } else {
      this.importance = 'low';
    }
    
    const { trend_direction, trend_percentage } = this.calculateTrendMetrics();
    this.trend_direction = trend_direction;
    this.trend_percentage = trend_percentage;
  }

  private calculateTrendMetrics(): { trend_direction: TrendDirection, trend_percentage: number } {
    let trend_percentage = 0;
    let trend_direction: TrendDirection = 'stable';

    if (this.occurrences.length < 2) {
      return { trend_direction: trend_direction, trend_percentage: trend_percentage };
    }

    // Convert occurrence strings to dates
    const dates = this.occurrences.map(d => new Date(d).getTime());
    
    // Calculate the point that marks 75% of the time window
    const threeQuarterPoint = this.startDate.getTime() + 
      (this.endDate.getTime() - this.startDate.getTime()) * 0.75;

    // Split occurrences into first 75% and last 25%
    const firstThreeQuarters = dates.filter(d => d < threeQuarterPoint);
    const lastQuarter = dates.filter(d => d >= threeQuarterPoint);

    // Calculate rates per day for each period
    const firstPeriodDays = (threeQuarterPoint - this.startDate.getTime()) / (1000 * 60 * 60 * 24);
    const secondPeriodDays = (this.endDate.getTime() - threeQuarterPoint) / (1000 * 60 * 60 * 24);

    const firstPeriodRate = firstThreeQuarters.length / firstPeriodDays;
    const lastPeriodRate = lastQuarter.length / secondPeriodDays;

    // Check if it's a new trend (no occurrences in first period)
    if (firstThreeQuarters.length === 0) {
      trend_direction = 'new';
      trend_percentage = 0;
      return { trend_direction, trend_percentage };
    }

    // Calculate percentage change
    trend_percentage = ((lastPeriodRate - firstPeriodRate) / firstPeriodRate) * 100;

    // Determine direction with a 10% threshold for significance
    if (trend_percentage > 10) {
      trend_direction = 'up';
    } else if (trend_percentage < -10) {
      trend_direction = 'down';
    }

    return { trend_direction: trend_direction, trend_percentage: trend_percentage };
  }

  async getDetails(supabase: AttroveSupabaseClient): Promise<TagDetail> {
    console.log("getDetails called with supabase:", supabase);

    const { data, error } = await supabase.rpc('get_tag_details', {
      p_tag_id: this.id,
      p_start_date: this.startDate.toISOString()
    });

    if (error) throw error;
    if (!data?.length) {
      throw new Error(`No details found for tag ${this.name} (${this.id})`);
    }

    // Get first row for sentiment and messages since they're the same for all rows
    const firstRow = data[0] as TagDetailsResponse;

    // Calculate total messages for sentiment percentages
    const totalSentimentMessages = firstRow.out_positive_count + firstRow.out_neutral_count + firstRow.out_negative_count;

    // Helper function to decode HTML entities
    const decodeHtmlEntities = (text: string) => {
      return text
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&apos;/g, "'");
    };

    return {
      ...this,
      integration_stats: data.map((d: TagDetailsResponse) => {
        const type = d.out_integration_type.toLowerCase();
        const meta = INTEGRATION_METADATA[type as keyof typeof INTEGRATION_METADATA] 
          ?? INTEGRATION_METADATA.default;
        
        return {
          name: d.out_integration_type,
          count: d.out_message_count,
          color: meta.color,
          icon: meta.icon
        };
      }),
      sentiment: {
        positive: Math.round((firstRow.out_positive_count / totalSentimentMessages) * 100),
        neutral: Math.round((firstRow.out_neutral_count / totalSentimentMessages) * 100),
        negative: Math.round((firstRow.out_negative_count / totalSentimentMessages) * 100)
      },
      recent_messages: firstRow.out_recent_messages.map(msg => ({
        ...msg,
        content: decodeHtmlEntities(msg.content),
        sender: decodeHtmlEntities(msg.sender)
      }))
    };
  }
}








///////// Getter Functions ///////////////////////////////////////////////////////////////////////////////////


export async function getTrendingTags(params: GetTrendingTagsParams): Promise<TagTrend[]> {
  const end_date = params.end_date || new Date().toISOString();

  // Get tag trends
  const { data: trendData, error: trendError } = await params.supabase.rpc('tag_counts', {
    start_date: params.start_date,
    end_date: end_date,
    max_limit: params.max_limit
  });

  if (trendError) throw trendError;
  if (!trendData?.length) return [];

  // Create initial trends
  const trends = trendData.map((row: TagCountsRow) => new TagTrend(row, params.start_date, end_date));

  // Get top entities for all tags in a single query
  const { data: entityData, error: entityError } = await params.supabase.rpc('get_top_entities_for_tags', {
    p_tag_ids: trends.map((t: TagTrend) => t.id),
    p_entities_per_tag: 3
  });

  if (entityError) throw entityError;

  // Group entities by tag_id
  const entitiesByTag = entityData?.reduce((acc: Record<string, RelatedPerson[]>, entity: any) => {
    if (!acc[entity.tag_id]) {
      acc[entity.tag_id] = [];
    }
    acc[entity.tag_id].push({
      id: entity.entity_id,
      name: entity.entity_name,
      message_count: entity.message_count,
      last_occurrence: entity.last_occurrence
    });
    return acc;
  }, {} as Record<string, RelatedPerson[]>) ?? {};

  // Assign entities to their respective trends
  trends.forEach((trend: TagTrend) => {
    trend.top_tag_entities = entitiesByTag[trend.id] ?? [];
  });

  return trends;
}

export async function getMetaTagStats(params: GetTrendingTagsParams): Promise<MetaTagStats> {
  const end_date = params.end_date || new Date().toISOString();

  const { data, error } = await params.supabase.rpc('get_meta_tag_stats', {
    p_start_date: params.start_date,
    p_end_date: end_date
  });

  if (error) throw error;
  
  // If no data returned, provide default values
  if (!data?.length) {
    return {
      total_mentions: 0,
      unique_participants: 0
    };
  }

  return {
    total_mentions: data[0].total_mentions,
    unique_participants: data[0].unique_participants
  };
}