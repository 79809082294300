import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { TimezoneCombobox } from "@attrove/attrove-ui/components/profile/timezone";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { GET_USER_QUERY_KEY, updateUser } from "@attrove/service-supabase";
import { Checkbox, Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@attrove/ui-shadcn";
import { convertFromUtc, convertToUtc, formatTime, parseTime } from "@attrove/util-formatting";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { z } from "zod";

const HourEnum = z.enum(["6", "7", "8", "9", "10", "11", "12", "1", "2", "3", "4", "5"]);
const MinuteEnum = z.enum(["00", "30"]);
const PeriodEnum = z.enum(["AM", "PM"]);

export type HourType = z.infer<typeof HourEnum>;
export type MinuteType = z.infer<typeof MinuteEnum>;
export type PeriodType = z.infer<typeof PeriodEnum>;

export const ReportTimeInput = () => {
  const { user } = useSession();
  let timezone = user.timezone;

  if (!timezone) {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  const convertedTime = convertFromUtc(user.report_time, timezone);
  const unconvertedTime = convertToUtc(convertedTime, timezone);
  const { hour: convertedHour, minute: convertedMinute, period: convertedPeriod } = parseTime(convertedTime);

  const [hour, setHour] = useState<HourType>(convertedHour as HourType);
  const [minute, setMinute] = useState<MinuteType>(convertedMinute as MinuteType);
  const [period, setPeriod] = useState<PeriodType>(convertedPeriod as PeriodType);

  const updateTime = useMutation({
    mutationFn: async (params: { newHour?: HourType; newMinute?: MinuteType; newPeriod?: PeriodType }) => {
      const { newHour, newMinute, newPeriod } = params;
      const reportTime = formatTime(newHour ?? hour, newMinute ?? minute, newPeriod ?? period);
      const utcTime = convertToUtc(reportTime, timezone);
      return await updateUser(supabase, user.id, {
        report_time: utcTime,
      });
    },
  });

  const updateConsolidateWeekends = useMutation({
    mutationFn: async (newConsolidatedWeekend: boolean) => {
      return await updateUser(supabase, user.id, {
        consolidated_weekend: newConsolidatedWeekend,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_USER_QUERY_KEY] });
    },
  });

  const handleSelectHour = (newHour: HourType) => {
    setHour(newHour);
    updateTime.mutate({ newHour });
  };

  const handleSelectMinute = (newMinute: MinuteType) => {
    setMinute(newMinute);
    updateTime.mutate({ newMinute });
  };

  const handleSelectPeriod = (newPeriod: PeriodType) => {
    setPeriod(newPeriod);
    updateTime.mutate({ newPeriod });
  };

  const handleConsolidatedWeekendChange = () => {
    const newValue = !user.consolidated_weekend;
    updateConsolidateWeekends.mutate(newValue);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-6">
        <div className="flex flex-col gap-2 w-full">
          <Label>Hour</Label>
          <Select onValueChange={handleSelectHour} value={hour}>
            <SelectTrigger className="flex-1 w-full">
              <SelectValue placeholder="Select hour" />
            </SelectTrigger>

            <SelectContent>
              {HourEnum.options.map((hour) => (
                <SelectItem key={hour} value={hour}>
                  {hour}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col gap-2 w-full">
          <Label>Minute</Label>
          <Select onValueChange={handleSelectMinute} value={minute}>
            <SelectTrigger className="flex-1">
              <SelectValue placeholder="Select minute" />
            </SelectTrigger>

            <SelectContent>
              {MinuteEnum.options.map((minute) => (
                <SelectItem key={minute} value={minute}>
                  {minute}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <Label>AM/PM</Label>
          <Select onValueChange={handleSelectPeriod} value={period}>
            <SelectTrigger className="flex-1">
              <SelectValue placeholder="Select AM/PM" />
            </SelectTrigger>

            <SelectContent>
              {PeriodEnum.options.map((period) => (
                <SelectItem key={period} value={period}>
                  {period}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Label className=" w-full">Timezone</Label>
        <TimezoneCombobox />
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2">
          <Checkbox checked={user.consolidated_weekend} onClick={handleConsolidatedWeekendChange} />
          <p className="text-sm">Consolidate weekends</p>
        </div>
        <p className="text-xs text-muted-foreground">Would you like messages from Saturday and Sunday combined into one report on Monday</p>
      </div>
    </div>
  );
};
