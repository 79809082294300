import { Logtail } from "@logtail/node";
import { displayClock } from "@attrove/util-formatting";

// Environment configuration
export enum AppEnvironment {
  PROD = "prod",
  DEV = "dev",
  LOCAL = "local"
}

// Get current environment with proper fallback
function getCurrentAppEnv(): AppEnvironment {
  const env = process.env['APP_ENV']?.toLowerCase();
  if (env === AppEnvironment.PROD || env === AppEnvironment.DEV || env === AppEnvironment.LOCAL) {
    return env as AppEnvironment;
  }
  // Default to local if not specified
  return AppEnvironment.LOCAL;
}

// Fast environment checks (avoid multiple string comparisons)
const APP_ENV = getCurrentAppEnv();
const isProd = APP_ENV === AppEnvironment.PROD;
const isLocal = APP_ENV === AppEnvironment.LOCAL;

let BETTER_STACK_CLIENT: Logtail | null = null;
export function setBetterStackClient(betterStackClient: Logtail) {
  BETTER_STACK_CLIENT = betterStackClient;
}

function getBetterStackClient(): Logtail {
  if (!BETTER_STACK_CLIENT) {
    throw new Error("BetterStack client not set");
  }
  return BETTER_STACK_CLIENT;
}

async function clearLogFile() {
  if (typeof window === 'undefined' && isLocal) {
    try {
      const { default: fs } = await import('fs');
      const { default: path } = await import('path');
      const LOG_DIR = 'logs';

      if (!fs.existsSync(LOG_DIR)) {
        fs.mkdirSync(LOG_DIR);
      }
      fs.writeFileSync(path.join(LOG_DIR, 'local.log'), '');
    } catch (error) {
      console.error('Failed to clear log file:', error);
    }
  }
}

// Clear the log file when the module is first loaded (only in local environment)
clearLogFile().catch(console.error);

async function writeToFile(message: string, meta?: any) {
  // Only write to file in local environment and Node.js
  if (typeof window === 'undefined' && isLocal) {
    try {
      const { default: fs } = await import('fs');
      const { default: path } = await import('path');
      const LOG_DIR = 'logs';

      if (!fs.existsSync(LOG_DIR)) {
        fs.mkdirSync(LOG_DIR);
      }
      const timestamp = new Date().toISOString();
      const logMessage = `${timestamp} - ${message} ${meta ? JSON.stringify(meta, null, 2) : ''}\n`;
      fs.appendFileSync(path.join(LOG_DIR, 'local.log'), logMessage);
    } catch (error) {
      console.error('Failed to write to log file:', error);
    }
  }
}

export type LogLevel = "INFO" | "LOG" | "WARN" | "ERROR" | "CHECKPOINT";

export type LogEvent = {
  level: LogLevel;
  message: string;
};

function addEmojiAndClock(item: unknown, level: LogLevel) {
  let emoji: string;
  switch (level) {
    case "INFO":
      emoji = "🟢";
      break;
    case "LOG":
      emoji = "🔵";
      break;
    case "WARN":
      emoji = "🟡";
      break;
    case "ERROR":
      emoji = "🔴";
      break;
    default:
      emoji = "⚪";
  }
  return `${emoji} ${displayClock()} ${item}`;
}

// Helper function to handle logging to BetterStack
function logToBetterStack(level: LogLevel, message: string, meta: any) {
  const client = getBetterStackClient();

  let logFunction: (message: string, meta: any) => Promise<any>;

  switch (level) {
    case "INFO":
    case "LOG":
      logFunction = client.info.bind(client);
      break;
    case "WARN":
      logFunction = client.warn.bind(client);
      break;
    case "ERROR":
      logFunction = client.error.bind(client);
      break;
    default:
      logFunction = client.info.bind(client);
  }

  return logFunction(message, meta).catch((error) => {
    console.error('BetterStack logging failed:', error);
    logToConsole(level, message, meta);
  });
}

// Helper function to handle console logging
function logToConsole(level: LogLevel, message: string, meta: any) {
  const metaString = JSON.stringify(meta, null, 2);
  switch (level) {
    case "INFO":
      console.info(message, metaString);
      break;
    case "LOG":
      console.log(message, metaString);
      break;
    case "WARN":
      console.warn(message, metaString);
      break;
    case "ERROR":
      console.error(message, metaString);
      break;
    default:
      console.log(message, metaString);
  }
}

// Generic logging function that handles all cases
function handleLog(level: LogLevel, item: string, meta: any) {
  const logMessage = addEmojiAndClock(item, level);

  // In prod, only log to BetterStack
  if (isProd) {
    logToBetterStack(level, logMessage, meta);
    return;
  }

  // In dev/local, always log to console
  logToConsole(level, logMessage, meta);

  // In local, also write to file
  if (isLocal) {
    writeToFile(level === "WARN" || level === "ERROR" ? `${level}: ${logMessage}` : logMessage, meta)
      .catch(console.error);
  }

  // In dev, also log to BetterStack
  if (!isLocal) {
    logToBetterStack(level, logMessage, meta);
  }
}

export async function flushLogs() {
  await getBetterStackClient().flush();
}


// keeping the betterStack arg only for backwards compatibility
export function logInfo(item: string, meta?: any, betterStackClient?: Logtail) {
  handleLog("INFO", item, meta || {});
}

export function log(item: string, meta?: any, betterStackClient?: Logtail) {
  handleLog("LOG", item, meta || {});
}

export function logWarn(item: string, meta?: any, betterStackClient?: Logtail) {
  handleLog("WARN", item, meta || {});
}

export function logError(item: string, meta?: any, betterStackClient?: Logtail) {
  handleLog("ERROR", item, meta || {});
}
