import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from "react";
import { getUserNotifications, markNotificationAsRead } from '@attrove/service-supabase';
import { AttroveSupabaseClient } from '@attrove/service-supabase';

export const useNotifications = (supabaseClient: AttroveSupabaseClient, userId: string) => {
  const queryClient = useQueryClient();
  const [recentlyDismissed, setRecentlyDismissed] = useState<string[]>([]);

  const { data: notifications = [], isLoading } = useQuery({
    queryKey: ['notifications', userId],
    queryFn: async () => {
      const notifications = await getUserNotifications(supabaseClient, userId, {
        includeDeliveries: true,
        limit: 50
      });
      return notifications;
    },
    refetchInterval: 30000,
  });

  const { mutate: markAsRead } = useMutation({
    mutationFn: async (notificationId: string) => {
      return markNotificationAsRead(supabaseClient, notificationId, userId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications', userId]);
    },
  });

  const { mutate: dismissNotification } = useMutation({
    mutationFn: async (notificationId: string) => {
      // Add API call to dismiss notification
      const { error } = await supabaseClient
        .from('notifications')
        .update({ is_dismissed: true })
        .eq('id', notificationId)
        .eq('user_id', userId);
      
      if (error) throw error;
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications', userId]);
    },
  });

  const { mutate: dismissAllRead } = useMutation({
    mutationFn: async () => {
      const { error } = await supabaseClient
        .from('notifications')
        .update({ is_dismissed: true })
        .eq('user_id', userId)
        .eq('is_read', true)
        .is('is_dismissed', false);
      
      if (error) throw error;
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications', userId]);
    },
  });

  const { mutate: undoDismiss } = useMutation({
    mutationFn: async (notificationIds: string[]) => {
      const { error } = await supabaseClient
        .from('notifications')
        .update({ is_dismissed: false })
        .in('id', notificationIds)
        .eq('user_id', userId);
      
      if (error) throw error;
      return true;
    },
    onSuccess: () => {
      setRecentlyDismissed([]); // Clear the undo history
      queryClient.invalidateQueries(['notifications', userId]);
    },
  });
  
  const unreadCount = notifications.filter(n => !n.is_read && !n.is_dismissed).length;

  return {
    notifications,
    unreadCount,
    isLoading,
    markAsRead,
    dismissNotification,
    dismissAllRead,
    undoDismiss,
    hasRecentlyDismissed: recentlyDismissed.length > 0,
  };
};