import * as React from "react";

import { Button, ButtonProps, buttonVariants, cn } from "@attrove/ui-shadcn";
import { Loader2 } from "lucide-react";

interface Props extends ButtonProps {
  loading?: boolean;
  children: React.ReactNode;
}

export const LoadingButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ className, variant, size, asChild = false, loading = false, children, ...props }, ref) => {
    return (
      <Button className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
        {loading ? <Loader2 className="animate-spin" /> : children}
      </Button>
    );
  },
);
LoadingButton.displayName = "LoadingButton";
